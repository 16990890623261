import {Cookies} from 'react-cookie';
import {useNavigate} from "react-router-dom";
import {swalCustom} from "../../../components/CustomStyle";
import {PostPeriode} from "../../../services/KeuanganService";

const PeriodeForm = () => {
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        const cookies = new Cookies();
        let { tahun } = document.forms[0];

        let formData = new FormData();
        formData.append('tahun', tahun.value);
        formData.append('created_by', cookies.get('username'));

        PostPeriode(formData).then(response => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil ditambah",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true){
                    navigate('/periode');
                }
            });
        }).catch(response => {
            swalCustom.fire({
                title: "Gagal",
                text: "Data gagal ditambah",
                type: "error",
            });
        });
    };

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Periode Form</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                Tahun
                                                <input className="form-control" type="text" name="tahun" required />
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <button className="btn btn-xs btn-success" type="submit">
                                        <i className="fas fa-save me-2"></i>Simpan
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PeriodeForm