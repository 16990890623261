import { tdStickLeftUpper } from "./CustomStyle";

export function RabLabel({title}) {
    return(
    <>
        <tr>
            <td colSpan={2} className="text-sm font-weight-normal text-uppercase" style={tdStickLeftUpper}>
                <b>{title}</b>
            </td>
            <td className="text-sm font-weight-normal" colSpan={13} style={{border: 0}}></td>
        </tr>
    </>
    )
}
