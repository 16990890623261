import Moment from 'moment';
import {Modal} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from "react";

import {FormatRupiah} from '@arismun/format-rupiah';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {header, numTable, swalCustom} from '../../components/CustomStyle';

import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {GetTrxUnitListChecked, TolakApproveTransaksiUnit, UpdateApproveTransaksiUnit} from "../../services/KeuanganService";

const TrxUnitListChecked = () => {
    const url = '/trx-unit-detail-checked/'
    
    const navigate = useNavigate();
    const {SearchBar} = Search;
    
    let [page, setPage] = useState(1);
    let [data, setData] = useState([]);
    let [detail, setDetail] = useState({});

    const [isShow, invokeModal] = useState(false)
    const initModal = (row) => {
        setDetail(row);
        return invokeModal(!isShow)
    }

    const getData = async () => {
        await GetTrxUnitListChecked().then((response) => {
            setData(response.data.rows);

        }).catch(() => console.log('error GetTrxUnitListChecked'));
    }

    useEffect(() => {
        getData();
    }, [])

    const approvesweetalert = (id) => {
        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin approve ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                UpdateApproveTransaksiUnit(id).then(response => {
                    swalCustom.fire('Berhasil diapprove!', '', 'success')
                    getData();
                }).catch(err => {
                    swalCustom.fire('Gagal diapprove!'.response.data.message, 'error')
                    getData();
                });
            }
        })
    }

    const handleTolak = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('note', event.target.note.value);

        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin menolak ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                TolakApproveTransaksiUnit(event.target.id.value, formData).then(response => {
                    invokeModal(!isShow)
                    swalCustom.fire('Transaksi ditolak!', '', 'success')
                    getData();

                }).catch(err => {
                    invokeModal(!isShow)
                    swalCustom.fire('Gagal ditolak!'.response.data.message, 'error')
                    getData();

                });
            }else{
                invokeModal(!isShow)
            }
        })
    }

    const namaTruncate = (cell, row, rowIndex, formatExtraData) => {
        const name = row.anggaran.nama_kegiatan;
        const ket = row.keterangan;
        const tc = 25;
        return (
            <>
            <b>{name.length > tc ? name.substring(0, tc) + ' ...' : name}</b>
            <br/>
            {ket.length > tc ? ket.substring(0, tc) + ' ...' : ket}
            </>
        );
    }

    const lembagaTruncate = (cell, row, rowIndex, formatExtraData) => {
        const name_lbg = row.nama_rekening_asal;
        const kode_lbg = row.kode_lembaga;
        const tc = 25;
        return (
            <>
            <b>{name_lbg.length > tc ? name_lbg.substring(0, tc) + ' ...' : name_lbg}</b>
            <br/>
            {kode_lbg}
            </>
        );
    }

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    },{
        text: 'Lembaga',
        dataField: 'nama_rekening_asal',
        formatter: lembagaTruncate,
        headerStyle: header
    },{
        text: 'Anggaran',
        dataField: 'anggaran.nama_kegiatan',
        title: (cell, row, rowIndex) => {
            return row.anggaran.nama_kegiatan
        },
        formatter: namaTruncate,
        formatExtraData: 1,
        headerStyle: header
    },{
        text: 'Total Transaksi',
        dataField: 'total_transaksi',
        formatter: (cell, row, rowIndex) => {
            return <b><FormatRupiah value={row.total_transaksi} /></b>
        },
        headerStyle: header
    },{
        text: 'Tanggal',
        dataField: 'created_at',
        formatter: (cell, row, rowIndex) => {
            return <>
                {Moment(row.tanggal).format('DD MMMM YYYY')}<br/>
                {row.created_by}
            </>
        },
        headerStyle: header
    },{
        text: 'Status',
        dataField: 'is_checker',
        formatter: (cell, row, rowIndex) => {
            return <>
                {/* {row.is_checker === false ? 
                    <span className="badge badge-danger text-danger"> Checker </span> : 
                    <span className="badge badge-success text-success"> Checker </span>}
                <br/> */}
                {row.is_approve === false ? 
                    <span className="badge btn-xs badge-danger text-danger"> Approval </span> : 
                    <span className="badge btn-xs badge-success text-success"> Approval </span>}
            </>
        },
        headerStyle: header
    },{
        text: 'Action',
        dataField: 'button',
        formatter: (cell, row, rowIndex) => {
            return <>
                {row.selesai == 1 && row.is_checker == 1 && row.is_approve == 0 ? <>
                    <button onClick={(e) => {e.stopPropagation(); approvesweetalert(row.id)}} className='btn btn-xs btn-info me-1'>
                        <i className="far fa-thumbs-up fa-lg text-white"></i> Approve
                    </button>

                    <button onClick={(e) => {e.stopPropagation(); initModal(row)}} className='btn btn-xs btn-danger'>
                        <i className="fas fa-times fa-lg text-white me-1"></i> Tolak
                    </button></>:null}
            </>
        },
        headerStyle: header
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            let path = url + row.id; 
            navigate(path);
        }
    }

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Transaksi Unit</h5>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <Modal show={isShow}>
                            <Modal.Header>
                                <Modal.Title>Tolak Transaksi Unit</Modal.Title>
                            </Modal.Header>
                            <form onSubmit={handleTolak}>
                            <Modal.Body>
                                Kode Lembaga: {detail.kode_lembaga} <br/>
                                Keterangan: {detail.keterangan} <br />
                                Total Transaksi: <FormatRupiah value={detail.total_transaksi} />
                                 <br/>
                                Catatan
                                <textarea className='form-control' type='text' name='note' required />
                                <input name='id' value={detail.id} hidden />
                            </Modal.Body>
                            <Modal.Footer>
                                <button type='button' className='btn btn-xs btn-primary' onClick={initModal}>Batal</button>
                                <button type='submit' className='btn btn-xs btn-danger'>Tolak</button>
                            </Modal.Footer>
                            </form>
                        </Modal>

                        <ToolkitProvider
                            keyField="id"
                            data={ data }
                            columns={ columns }
                            search>
                            {
                                props => (
                                    <div>
                                        <SearchBar { ...props.searchProps } />
                                        <br />
                                        <BootstrapTable
                                            keyField="id"
                                            { ...props.baseProps }
                                            pagination={paginationFactory({
                                                onPageChange: (page, sizePerPage) => {
                                                    setPage(page);
                                            }})}
                                            bordered={ false }
                                            rowEvents={ rowEvents }
                                            hover
                                            wrapperClasses="table-responsive"
                                            classes="table table-responsive"
                                            rowClasses="text-sm font-weight-normal"
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TrxUnitListChecked