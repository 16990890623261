import {Cookies} from "react-cookie";
import React, {useState, useEffect} from "react";
import {Link, useLocation} from 'react-router-dom';

import {GetHomebase, GetUserGroupByToken} from '../services/KeuanganService';

const Sidebar = ({setBege}) => {
    const location = useLocation();
    let cookies = new Cookies();
    let isUnitCookie = cookies.get('is_unit');
    let groupCookie = cookies.get('groups');

    let [show, setShow] = useState('');
    let [groupExist, setGroupExist] = useState('');
    let [isUnit, setIsUnit] = useState(0);

    let [activeMenu, setActiveMenu] = useState('');
    let namaHomebase = cookies.get('homebase_nama');

    const getData = () => {
        GetUserGroupByToken().then((response) => {
            let group_str = '';
            response.data.rows.groups.map((val, key) => group_str += val.name + ',');
            cookies.set('groups', group_str);
            setGroupExist(group_str);

        }).catch(() => console.log('error get_group_by_token'));
    }

    const getHomebase = () => {
        GetHomebase().then((response) => {
            const unit_now = cookies.get('is_unit');
            if (unit_now == null){
                cookies.set('is_unit', response.data[0].unit);
            }
            setIsUnit(response.data[0].unit);

        }).catch(() => console.log('error get_homebase_navbar'));
    }

    useEffect(() => {
        getData();
    }, [activeMenu])

    useEffect(() => {
        getData();
        getHomebase();
    }, [])

    useEffect(() => {
        getData();
        getHomebase();
    }, [location])

    const handleClick = () => {
        setBege((count) => !count);
    };

    useEffect(() => {
        function handleResize() {
            if (1200 < window.innerWidth){
                setShow("fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none");                
            }else{
                setShow("fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-xl-none");                
            }
        }

        if (1200 < window.innerWidth){
            setShow("fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none");                
        }else{
            setShow("fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-xl-none");                
        }
    
        window.addEventListener('resize', handleResize)
    })

    return (
        <>
            <div className="min-height-300 bg-primary position-absolute w-100"></div>
            <aside className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 "
                   id="sidenav-main">
                <div className="ps-1 pt-2 bg-white sticky-top mb-4">
                    <i className={show} onClick={handleClick} aria-hidden="true" id="iconSidenav"></i>
                    <Link 
                        to="/"
                        onClick={() => {setActiveMenu('home')}}
                        className={activeMenu == 'home' ? 'nav-link active' : 'nav-link'}>
                            <img src="https://minio.umkt.ac.id/berkas-media/berkas/lmbg1134/z6mu3aij42wzzs4e0kbxc69jzzmcwk15jnhgrvyeplymq6ovps.png" 
                                className="navbar-brand-img height-100" alt="main_logo"/>
                            <br/>
                            <span className="ms-1 font-weight-bolder" style={{fontSize: 20}}>KEUANGAN</span>
                    </Link>
                    <hr className="horizontal dark mt-0"/>
                </div>
                <div className="collapse navbar-collapse w-auto h-auto" id="sidenav-collapse-main">
                <ul className="navbar-nav">
                    {isUnit == 1 ? <>
                        <li className="nav-item mt-3">
                            <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">UNIT</h6>
                        </li>
                        <li className="nav-item ">
                            <Link to="/pie-chart" onClick={() => {setActiveMenu('grafik-unit')}}
                                  className={activeMenu == 'grafik-unit' ? 'nav-link active' : 'nav-link'}>
                                <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                    <i className="fa fa-chart-pie text-primary text-sm"></i>
                                </div>
                                <span className="nav-link-text ms-1">Grafik</span>
                            </Link>
                        </li>
                        <li className="nav-item ">
                            <Link to="/rekening" onClick={() => {setActiveMenu('rekening')}}
                                  className={activeMenu == 'rekening' ? 'nav-link active' : 'nav-link'}>
                                <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                    <i className="fa fa-credit-card text-primary text-sm"></i>
                                </div>
                                <span className="nav-link-text ms-1">Rekening</span>
                            </Link>
                        </li>
                        <li className="nav-item ">
                            <Link to="/anggaran" onClick={() => {setActiveMenu('anggaran-unit')}}
                                  className={activeMenu == 'anggaran-unit' ? 'nav-link active' : 'nav-link'}>
                                <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                    <i className="fa fa-calculator text-primary text-sm"></i>
                                </div>
                                <span className="nav-link-text ms-1">Anggaran</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/trx-unit" onClick={() => {setActiveMenu('trx-unit-unit')}}
                                  className={activeMenu == 'trx-unit-unit' ? 'nav-link active' : 'nav-link'}>
                                <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                    <i className="fas fa-receipt text-primary text-sm"></i>
                                </div>
                                <span className="sidenav-normal">Transaksi</span>
                            </Link>
                        </li>
                    </>:null}

                    {groupExist && groupExist.length !== 0 ? <>
                        {groupExist.includes('Pimpinan') ? <>
                            <li className="nav-item mt-3">
                                <hr className="horizontal dark mt-0"/>
                                <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">PIMPINAN</h6>
                            </li>
                            <li className="nav-item ">
                                <Link to="/keuangan-chart" onClick={() => {setActiveMenu('grafik-pimpinan')}}
                                    className={activeMenu == 'grafik-pimpinan' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fa fa-chart-pie text-primary text-sm"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Grafik Anggaran</span>
                                </Link>
                            </li>
                            {/* <li className="nav-item ">
                                <Link to="/anggaran-all" onClick={() => {setActiveMenu('anggaran-pimpinan')}}
                                    className={activeMenu == 'anggaran-pimpinan' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fa fa-calculator text-primary text-sm"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Daftar Anggaran</span>
                                </Link>
                            </li> */}
                            <li className="nav-item ">
                                <Link to="/rekening-all-pimpinan" onClick={() => {setActiveMenu('rekening-pimpinan')}}
                                    className={activeMenu == 'rekening-pimpinan' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fa fa-credit-card text-primary text-sm"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Daftar Rekening</span>
                                </Link>
                            </li>
                        </>:null}

                        {namaHomebase && namaHomebase.toLowerCase().includes('pengadaan') ?<>
                        <li className="nav-item mt-3">
                            <hr className="horizontal dark"/>
                            <h6 className="ps-4  ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Pengadaan</h6>
                        </li>
                        <li className="nav-item ">
                            <Link to="/trx-unit-pengadaan" onClick={() => {setActiveMenu('nota-pengadaan')}}
                                className={activeMenu == 'nota-pengadaan' ? 'nav-link active' : 'nav-link'}>
                                <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                    <i className="fa fa-chart-pie text-primary text-sm"></i>
                                </div>
                                <span className="nav-link-text ms-1">Nota Pengadaan</span>
                            </Link>
                        </li></>:null}


                        {groupExist.includes('Keuangan') ? <>
                            <li className="nav-item mt-3">
                                <hr className="horizontal dark"/>
                                <h6 className="ps-4  ms-2 text-uppercase text-xs font-weight-bolder opacity-6">KEUANGAN</h6>
                            </li>
                            <li className="nav-item ">
                                <Link to="/keuangan-chart" onClick={() => {setActiveMenu('grafik-keuangan')}}
                                    className={activeMenu == 'grafik-keuangan' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fa fa-chart-pie text-primary text-sm"></i>
                                    </div>
                                    <span className="nav-link-text ms-1">Grafik Anggaran</span>
                                </Link>
                            </li>
                            {/* <li className="nav-item ">
                                <Link to="/anggaran-all" onClick={() => {setActiveMenu('admin-anggaran-k')}}
                                    className={activeMenu == 'admin-anggaran-k' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fa fa-calculator text-primary text-sm"></i>
                                    </div>
                                    <span className="sidenav-normal">Daftar Anggaran</span>
                                </Link>
                            </li> */}
                            <li className="nav-item ">
                                <Link to="/rekening-all" onClick={() => {setActiveMenu('admin-rekening-k')}}
                                    className={activeMenu == 'admin-rekening-k' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fa fa-credit-card text-primary text-sm"></i>
                                    </div>
                                    <span className="sidenav-normal">Daftar Rekening</span>
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link to="/mata-anggaran" onClick={() => {setActiveMenu('mata-anggaran-k')}}
                                    className={activeMenu == 'mata-anggaran-k' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fas fa-dollar-sign text-primary text-sm"></i>
                                    </div>
                                    <span className="sidenav-normal">Mata Anggaran</span>
                                </Link>
                            </li>
                            {/* <li className="nav-item ">
                                <Link to="/rab" onClick={() => {setActiveMenu('rab')}}
                                    className={activeMenu == 'rab' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fas fa-columns text-primary text-sm"></i>
                                    </div>
                                    <span className="sidenav-normal">RAB</span>
                                </Link>
                            </li> */}
                            <li className="nav-item ">
                                <Link to="/trx-mata-anggaran" onClick={() => {setActiveMenu('trx-mata-anggaran')}}
                                    className={activeMenu == 'trx-mata-anggaran' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fas fa-money-bill-wave text-primary text-sm"></i>
                                    </div> 
                                    <span className="sidenav-normal">Transaksi Anggaran</span>
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link to="/trx-unit-verifikasi/list" onClick={() => {setActiveMenu('trx-unit-verifikasi')}}
                                    className={activeMenu == 'trx-unit-verifikasi' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fas fa-money-bill-wave text-primary text-sm"></i>
                                    </div> 
                                    <span className="sidenav-normal">Verifikasi Bukti</span>
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link to="/trx-permohonan-ubah" onClick={() => {setActiveMenu('trx-ubah')}}
                                    className={activeMenu == 'trx-ubah' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fas fa-edit text-primary text-sm"></i>
                                    </div> 
                                    <span className="sidenav-normal">Izin Edit</span>
                                </Link>
                            </li>

                            <li className="nav-item ">
                                <Link to="/aset-inventaris" onClick={() => {setActiveMenu('aset')}}
                                    className={activeMenu == 'aset' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fas fa-building text-primary text-sm"></i>
                                    </div> 
                                    <span className="sidenav-normal">Aset</span>
                                </Link>
                            </li>
                        </>:null}

                        {/* {groupExist.includes('Checker') ? <>
                            <li className="nav-item mt-3">
                                <hr className="horizontal dark"/>
                                <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Checker</h6>
                            </li>
                            <li className="nav-item">
                                <Link to="/trx-unit-selesai" onClick={() => {setActiveMenu('trx-unit-checker')}}
                                    className={activeMenu == 'trx-unit-checker' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fas fa-receipt text-success text-sm"></i>
                                    </div>
                                    <span className="sidenav-normal">Transaksi Unit</span>
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link to="/mata-anggaran-selesai" onClick={() => {setActiveMenu('trx-mata-anggaran-checker')}}
                                    className={activeMenu == 'trx-mata-anggaran-checker' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fas fa-money-bill-wave text-primary text-sm"></i>
                                    </div> 
                                    <span className="sidenav-normal">Transaksi Anggaran</span>
                                </Link>
                            </li>
                        </>:null} */}

                        {groupExist.includes('Approve') ? <>
                            <li className="nav-item mt-3">
                                <hr className="horizontal dark"/>
                                <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">Approval</h6>
                            </li>
                            <li className="nav-item">
                                <Link to="/trx-unit-checked" onClick={() => {setActiveMenu('trx-unit-approve')}}
                                    className={activeMenu == 'trx-unit-approve' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fas fa-receipt text-primary text-sm"></i>
                                    </div>
                                    <span className="sidenav-normal">Transaksi Unit</span>
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link to="/mata-anggaran-checked" onClick={() => {setActiveMenu('trx-mata-anggaran-approve')}}
                                    className={activeMenu == 'trx-mata-anggaran-approve' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fas fa-money-bill-wave text-primary text-sm"></i>
                                    </div> 
                                    <span className="sidenav-normal">Transaksi Anggaran</span>
                                </Link>
                            </li>
                        </>:null}

                        {groupExist.includes('Operator') ? <>
                            <li className="nav-item mt-3">
                                <hr className="horizontal dark"/>
                                <h6 className="ps-4  ms-2 text-uppercase text-xs font-weight-bolder opacity-6">OPERATOR</h6>
                            </li>
                            <li className="nav-item ">
                                <Link to="/periode" onClick={() => {setActiveMenu('periode')}}
                                    className={activeMenu == 'periode' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fa fa-calendar text-secondary text-sm"></i>
                                    </div>
                                    <span className="sidenav-normal">Periode</span>
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link to="/user-group" onClick={() => {setActiveMenu('user-group')}}
                                    className={activeMenu == 'user-group' ? 'nav-link active' : 'nav-link'}>
                                    <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                        <i className="fa fa-user text-secondary text-sm"></i>
                                    </div>
                                    <span className="sidenav-normal">User Group</span>
                                </Link>
                            </li>
                        </>:null}
                    </>:null}
                </ul>
                </div>
            </aside>
        </>
    );
}

export default Sidebar;