import { rangez } from "./CustomStyle"

function CustomPagination({data_pagin, setPage}){
    let prev = data_pagin['prev']
    let next = data_pagin['next']
    let page = data_pagin['page']
    let data_now = data_pagin['data_now']
    let data_total = data_pagin['total_data']
    let page_total = data_pagin['total_page']

    let start_index = page > 1 ? (page - 1) * 10 + 1  : page
    let end_index = page != page_total ? page * data_now : data_total
    
    return (
        <>
        Showing {start_index} to {end_index} of {data_total} entries
        <ul className="pagination justify-content-end">
            {prev == true ? <>
                <li className="page-item">
                    <button className="page-link" onClick={(e) => prev == true ? setPage(1) : null}>
                        <span className="material-icons">keyboard_double_arrow_left</span>
                    </button>
                </li>
                <li className="page-item">
                    <button className="page-link" onClick={(e) => prev == true ? setPage(page-1) : null}>
                        <span className="material-icons">chevron_left</span>
                    </button>
                </li>
            </>
            :<>
                <li className="page-item disabled opacity-2">
                    <button className="page-link">
                        <span className="material-icons">keyboard_double_arrow_left</span>
                    </button>
                </li>
                <li className="page-item disabled opacity-2">
                    <button className="page-link">
                        <span className="material-icons">chevron_left</span>
                    </button>
                </li>
            </>}

            {rangez(1, page_total+1).map(datax => 
                {return datax == page ?
                    <li className="page-item active">
                        <button className="page-link">
                            {datax}
                        </button>
                    </li>
                : page < datax + 3 && page > datax - 3 ?
                    <li className="page-item">
                        <button className="page-link" onClick={(e) => setPage(datax)}>
                            {datax}
                        </button>
                    </li>
                :null}
            )}

            {next == true ? <>
                <li className="page-item">
                    <button className="page-link" onClick={(e) => next == true ? setPage(page+1) : null}>
                        <span className="material-icons">chevron_right</span>
                    </button>
                </li>
                <li className="page-item">
                    <button className="page-link" onClick={(e) => next == true ? setPage(page_total) : null}>
                        <span className="material-icons">keyboard_double_arrow_right</span>
                    </button>
                </li>
            </>
            :<>
                <li className="page-item disabled opacity-2">
                    <button className="page-link">
                        <span className="material-icons">chevron_right</span>
                    </button>
                </li>
                <li className="page-item disabled opacity-2">
                    <button className="page-link">
                        <span className="material-icons">keyboard_double_arrow_right</span>
                    </button>
                </li>
            </>}
        </ul>
        </>
    )
}

export default CustomPagination