import { FormatRupiah } from "@arismun/format-rupiah";
import { tdStickLeft } from "./CustomStyle";

export function RabTotal({row}) {
    return(
    <>
        <tr>
            <td className="text-sm font-weight-normal" style={tdStickLeft}><b>Total</b></td>
            <td className="text-sm font-weight-normal"></td>
            <td className="text-sm font-weight-normal">
                {row[8] > 0 ? <FormatRupiah value={row[8]} /> : ''}
            </td>
            <td className="text-sm font-weight-normal">
                {row[9] > 0 ? <FormatRupiah value={row[9]} /> : ''}
            </td>
            <td className="text-sm font-weight-normal">
                {row[10] > 0 ? <FormatRupiah value={row[10]} /> : ''}
            </td>
            <td className="text-sm font-weight-normal">
                {row[11] > 0 ? <FormatRupiah value={row[11]} /> : ''}
            </td>
            <td className="text-sm font-weight-normal">
                {row[12] > 0 ? <FormatRupiah value={row[12]} /> : ''}
            </td>
            <td className="text-sm font-weight-normal">
                {row[13] > 0 ? <FormatRupiah value={row[13]} /> : ''}
            </td>
            <td className="text-sm font-weight-normal">
                {row[14] > 0 ? <FormatRupiah value={row[14]} /> : ''}
            </td>
            <td className="text-sm font-weight-normal">
                {row[15] > 0 ? <FormatRupiah value={row[15]} /> : ''}
            </td>
            <td className="text-sm font-weight-normal">
                {row[16] > 0 ? <FormatRupiah value={row[16]} /> : ''}
            </td>
            <td className="text-sm font-weight-normal">
                {row[17] > 0 ? <FormatRupiah value={row[17]} /> : ''}
            </td>
            <td className="text-sm font-weight-normal">
                {row[18] > 0 ? <FormatRupiah value={row[18]} /> : ''}
            </td>
            <td className="text-sm font-weight-normal">
                {row[19] > 0 ? <FormatRupiah value={row[19]} /> : ''}
            </td>
            <td className="text-sm font-weight-normal">
                {row[20] > 0 ? <FormatRupiah value={row[20]} /> : ''}
            </td>
        </tr>
    </>
    )
}
