import axios from "axios";
import {api_refresh} from "./ApiService";
import TokenService from "./TokenService";

const clientSarpras = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_API_SARPRAS,
    // baseURL: process.env.REACT_APP_BASE_URL_API_LOCAL,
    headers: {
      "Content-Type": "application/json",
      "token": process.env.REACT_APP_TOKEN_TEMP,
    },
});

clientSarpras.interceptors.request.use(
    (config) => {
        let token = TokenService.getAccessToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
            // config.headers["Content-Type"] = 'multipart/form-data'
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

clientSarpras.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
        const oriConfig = err.config;
        
        if (oriConfig.url !== "/login" && err.response) {
            if (err.response.status === 401 && !oriConfig._retry) {

                oriConfig._retry = true;
                try {
                    const formData = new FormData();
                    formData.append('refresh', TokenService.getRefreshToken());

                    const rs = await axios.post(api_refresh, formData);
                    const accessToken = rs.data.access;
                    TokenService.updateAccessToken(accessToken);
                    
                    return clientSarpras(oriConfig);
                
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }
        return Promise.reject(err);
    }
);

export default clientSarpras;