import React, {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom';

import {GetInventarisList, GetRuangan, GetSearchInventaris} from '../../../services/SarprasService';
import {numTable} from '../../../components/CustomStyle';
import {FormatRupiah} from '@arismun/format-rupiah';
import ModalDepresiasi from '../../../components/ModalDepresiasi';
import CustomPagination from '../../../components/CustomPagination';


const InventarisList = () => {
    const url_detail = '/aset-detail/'
    const navigate = useNavigate();

    let [data, setData] = useState([]);
    let [searchVal, setSearchVal] = useState({});

    let [cari, setCari] = useState('');
    let [ruangan, setRuangan] = useState(0);
    let [ruanganAll, setRuanganAll] = useState([]);

    let [page, setPage] = useState(1);
    let [dataPagin, setDataPagin] = useState({});

    let [checkedAll, setCheckedAll] = useState(false);
    let [checked, setChecked] = useState({});

    const getData = async () => {
        await GetInventarisList(page).then((response) => {
            setData(response.data.rows);
            setDataPagin({
                'prev': response.data.is_prev,
                'next': response.data.is_next,
                'total_data': response.data.data_total,
                'total_page': response.data.page_total,
                'data_now': response.data.data_now,
                'page': response.data.page_now
            });
            setChecked(response.data.rows.reduce((o, key) => ({ ...o, [key._id]: false}), {}))

        }).catch(() => console.log('error get inventaris list'));
    }

    const getSearch = () => {
        GetSearchInventaris(page, searchVal).then((response) => {
            setData(response.data.rows);
            setDataPagin({
                'prev': response.data.is_prev,
                'next': response.data.is_next,
                'total_data': response.data.data_total,
                'total_page': response.data.page_total,
                'data_now': response.data.data_now,
                'page': response.data.page_now
            });
            setChecked(response.data.rows.reduce((o, key) => ({ ...o, [key._id]: false}), {}))

        }).catch(err => {
            console.log('error search inventaris');
            setData([]);    
        })
    }

    const getRuangan = () => {
        GetRuangan().then((response) => {
            setRuanganAll(response.data.rows);

        }).catch(() => console.log('error get ruangan list'))
    }

    useEffect(() => {
        getRuangan();

        let svJson = JSON.stringify(searchVal);
        if (svJson != '{}'){
            const delaySearchVal = setTimeout(() => {
                getSearch();
            }, 1000)

            return () => clearTimeout(delaySearchVal)
        }else{
            getData();
        }

    }, [page, searchVal])

    useEffect(() => {
        if (ruangan != 0){
            setSearchVal({...searchVal, 'ruang': ruangan})
        }else{
            let newSearch = {...searchVal};
            delete newSearch['ruang'];
            setSearchVal(newSearch);
        }
    }, [ruangan])

    useEffect(() => {
        if (cari != ''){
            setSearchVal({...searchVal, 'q': cari})
        }else{
            let newSearch = {...searchVal};
            delete newSearch['q'];
            setSearchVal(newSearch);
        }
    }, [cari])

    useEffect(() => {
        setPage(1);
    }, [searchVal])

    function detail(id){
        let path = url_detail + id;
        navigate(path);
    }

    const toggleCheck = (inputName) => {
        setChecked((prevState) => {
            const newState = { ...prevState };
            newState[inputName] = !prevState[inputName];
            return newState;
        });
    };

    const selectAll = (value) => {
        setCheckedAll(value);
        setChecked((prevState) => {
            const newState = { ...prevState };
            for (const inputName in newState) {
                newState[inputName] = value;
            }
            return newState;
        });
    };

    useEffect(() => {
        let allChecked = true;
        for (const inputName in checked) {
            if (checked[inputName] === false) {
                allChecked = false;
            }
        }

        if (allChecked) {
            setCheckedAll(true);
        } else {
            setCheckedAll(false);
        }
    }, [checked]);

    let [tdClick, setTdClick] = useState(0);
    useEffect(() => {
        if (tdClick == 1){
            const paths = '/aset-inventaris';
            navigate(paths);

        }else if (tdClick == 2){
            const paths = '/aset-depresiasi';
            navigate(paths);
        }

    }, [tdClick]);

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="table-responsive m-1">
                        <table className="table" id="nav-table">
                            <tbody>
                                <tr>
                                    <td id="nav-active" onClick={() => {setTdClick(1)}}>
                                        <b className="text-xs">Inventaris</b>
                                    </td>
                                    <td id="nav-no-active" onClick={() => {setTdClick(2)}}>
                                        <b className="text-xs">Depresiasi</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Inventaris</h5>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        Ruangan
                        {ruanganAll.length !== 0 ?
                            <select className="form-select" onChange={x => setRuangan(x.target.value)}>
                                <option value="">Semua</option>
                                {ruanganAll.map((ruang) =>
                                    <option key={ruang.id} value={ruang.id}>{ruang.nama}</option>
                                )}
                            </select>:<select className="form-select"><option>-</option></select>}
                        <br/>

                        <div className="pagination justify-content-end me-2 mt-2 mb-4">
                            <div className="ms-3 mt-1">
                                <ModalDepresiasi titles={'Edit Depresiasi'} datas={checked} getDatas={getData} />
                                <br/>
                                <input id="all_check" type="checkbox" onChange={(event) => selectAll(event.target.checked)} checked={checkedAll}/>
                                <label htmlFor="all_check">Check all</label>
                            </div>
                            <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                                <input type="text" name="q" onChange={x => setCari(x.target.value)} className="form-control" placeholder="Search here..."/>
                            </div>
                        </div>

                        <table className="table table-flush">
                            <thead className="thead-light">
                                <tr>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">No</th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nama</th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Kode</th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Ruangan</th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Bulan / Tahun</th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Harga</th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Depresiasi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((row, rowIndex) =>  
                                <tr key={row._id} onClick={() => detail(row._id)}>
                                    <td className="text-sm font-weight-normal">
                                        {numTable(page, rowIndex)} &nbsp; 
                                        <input type="checkbox" name={row._id} value={row._id} onChange={() => toggleCheck(row._id)} checked={checked[row._id]} onClick={e => e.stopPropagation()} />
                                    </td>
                                    <td className="text-sm font-weight-normal" style={{whiteSpace: 'normal'}}>
                                        <b>{row.inventaris && row.inventaris.inventaris_nama}</b><br/>
                                        <i>{row.spek ? row.spek : '-'}</i>
                                    </td>
                                    <td className="text-sm font-weight-normal"><b><i>{row.rid}</i></b></td>
                                    <td className="text-sm font-weight-normal">{row.ruangan && row.ruangan.ruang_nama}</td>
                                    <td className="text-sm font-weight-normal">{row.bln}/{row.thn}</td>
                                    <td className="text-sm font-weight-normal"><b><FormatRupiah value={row.harga}/></b></td>
                                    <td className="text-sm font-weight-normal"><b><FormatRupiah value={row.depresiasi}/></b></td>
                                </tr>
                                )}
                            </tbody>
                        </table>

                        <CustomPagination data_pagin={dataPagin} setPage={setPage}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InventarisList