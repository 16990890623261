import {useEffect, useState} from "react";
import {useParams, Link} from "react-router-dom";
import {GetPeriodeDetail} from "../../../services/KeuanganService";

const PeriodeDetail = () => {
    const {id} = useParams();
    
    let [data, setData] = useState({});

    useEffect(() => {
        GetPeriodeDetail(id).then((response) => {
            setData(response.data);

        }).catch(() => console.log('error get periode detail'));
    }, [id])

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Periode Detail</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            Tahun <br />
                                            {data.tahun}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <Link to="/periode" className="btn btn-sm btn-secondary">
                                    <i className="fas fa-chevron-circle-left me-2"></i>Kembali
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PeriodeDetail