import { Link, useNavigate } from 'react-router-dom';
import React, {useEffect, useState} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Moment from 'moment';

import {DeleteMataAnggaran, GetMataAnggaranList, GetKodeAnggaranList, GetRabList, GetMataAnggaranListV2, GetRequestTrxAgrEdit} from "../../../services/KeuanganService";
import { header, numTable, swalCustom } from '../../../components/CustomStyle';
import { FormatRupiah } from '@arismun/format-rupiah';

const TrxMataAnggaranList = () => {
    const url = '/trx-mata-anggaran/detail/'
    const url_edit = '/trx-mata-anggaran/edit/'
    const url_upload_bukti = '/trx-mata-anggaran-bukti/'
    const { SearchBar } = Search;
    let [data, setData] = useState([]);
    let [mataAgr, setMataAgr] = useState([]);
    let [rab, setRab] = useState([]);
    let [menus, setMenus] = useState('mag');
    let [filter, setFilter] = useState('');
    let [tipe, setTipe] = useState(1);

    const navigate = useNavigate();
    let [page, setPage] = useState(1);

    const getData = () => {
        GetMataAnggaranList(tipe).then((response) => {
            setData(response.data.rows);

        }).catch(() => console.log('error get_trx_mata_agr'));
    }

    const getDataFiltered = () => {
        GetMataAnggaranListV2(filter, tipe).then((response) => {
            setData(response.data.rows);

        }).catch(() => console.log('error get_trx_mata_agr_filtered'));
    }

    const getMataAgr = () => {
        GetKodeAnggaranList().then((response) => {
            setMataAgr(response.data.rows);

        }).catch(() => console.log('error get_kode_anggaran_all'));
    }

    const getRab = () => {
        GetRabList().then((response) => {
            setRab(response.data.rows);

        }).catch(() => console.log('error get_rab_all'));
    }

    useEffect(() => {
        getData();
    }, [tipe])

    useEffect(() => {
        if (filter == 'all'){
            getData();
        }else{
            getDataFiltered();
        }
    }, [filter])

    useEffect(() => {
        if (menus == 'mag'){
            getMataAgr();
        }else if(menus == 'rab'){
            getRab();
        }
    }, [menus])

    const opensweetalert = (id) => {
        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin menghapus ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                DeleteMataAnggaran(id).then(response => {
                    swalCustom.fire('Berhasil dihapus!', '', 'success')
                    if (filter == 'all'){
                        getData();
                    }else{
                        getDataFiltered();
                    }
                })
            }
        })
    }

    const ajukaneditswal = (id) => {
        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin mengajukan edit transaksi ini ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                GetRequestTrxAgrEdit(id).then(response => {
                    swalCustom.fire('Berhasil diajukan!', '', 'success')
                    if (filter == 'all'){
                        getData();
                    }else{
                        getDataFiltered();
                    }
                })
            }
        })
    }

    const ketTruncate = (cell, row, rowIndex, formatExtraData) => {
        const tc = 25;
        const tm = 30;
        return (
            <>
            {row.is_rab == true ? <><span className="badge badge-primary text-primary">RAB</span><br/></> : ''}
            <b title={row.mata_anggaran}>
                {row.mata_anggaran.length > tm ? row.mata_anggaran.substring(0, tm) + '...' : row.mata_anggaran}
            </b>
            <br/>
            <span title={row.keterangan}>
                {row.keterangan.length > tc ? row.keterangan.substring(0, tc) + ' ...' : row.keterangan}
            </span>
            </>
        );
    }

    const asalTujuan = (cell, row, rowIndex, formatExtraData) => {
        const tc = 25;
        return (
            <>
            <div className='row'>
                <div className='col-lg-12 mb-2'>
                    Rekening Asal<br/>
                    <span className="badge badge-success badge-sm" style={{fontSize: 12}}>
                        {row.rekening_asal != null && row.rekening_asal != '' ? row.rekening_asal : '-'}
                    </span>
                </div>
                <div className='col-lg-12'>
                    Rekening Tujuan<br/>
                    <span className="badge badge-info badge-sm" style={{fontSize: 12}}>
                        {row.rekening_tujuan}
                    </span>
                </div>
            </div>
             <br/>
            </>
        );
    }

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    },{
        text: 'Keterangan',
        dataField: 'keterangan',
        formatter: ketTruncate,
        headerStyle: header
    },{
        text: 'Rekening',
        dataField: 'rekening_asal',
        formatter: asalTujuan,
        headerStyle: header
    },{
        text: 'Nominal',
        dataField: 'nominal',
        formatter: (cell, row, rowIndex) => {
            return <b><FormatRupiah value={row.nominal} /></b>;
        },
        headerStyle: header
    },{
        text: 'Tanggal',
        dataField: 'tanggal',
        formatter: (cell, row, rowIndex) => {
            return <>
                {Moment(row.tanggal).format('DD MMMM YYYY HH:mm')}<br/>
                {row.created_by}
            </>
        },
        headerStyle: header
    },{
        text: 'Status',
        dataField: 'is_checker',
        formatter: (cell, row, rowIndex) => {
            return <>
                {/* {row.is_checker === false ? 
                    <span className="badge badge-danger text-danger"> Checker </span> : 
                    <span className="badge badge-success text-success"> Checker </span>}
                <br/> */}
                {row.is_approve === false ? 
                    <span className="badge btn-xs badge-danger text-danger"> Approval </span> : 
                    <span className="badge btn-xs badge-success text-success"> Approval </span>}
            </>
        },
        headerStyle: header
    },{
        text: 'Action',
        dataField: 'button',
        formatter: (cell, row, rowIndex) => {
            return <>
                {row.selesai == 0 ? <>
                    <Link to={url_edit+row.id} onClick={(e) => e.stopPropagation()} className='btn btn-xs btn-warning me-1'>
                        <i className="fas fa-edit text-white"></i>
                    </Link>
                    <button onClick={(e) => {e.stopPropagation(); opensweetalert(row.id)}} className='btn btn-xs btn-danger'>
                        <i className="fas fa-trash text-white"></i>
                    </button></>:null}
                {row.selesai == 1 && row.is_checker == 1 && row.is_approve == 1 && row.jenis_trx == 'Keluar' && row.bukti == null ?
                    <Link to={url_upload_bukti+row.id} onClick={(e) => e.stopPropagation()} className='btn btn-xs btn-info'>
                        <i className="fas fa-upload text-white me-1"></i> Upload Bukti
                    </Link>:null}
                {row.is_edit == 1 ? 
                    <Link to={url_edit+row.id} onClick={(e) => e.stopPropagation()} className='btn btn-xs btn-warning me-1'>
                        <i className="fas fa-edit text-white"></i>
                    </Link>:null}
            </>
        },
        headerStyle: header
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            let path = url + row.id; 
            navigate(path);
        }
    }

    const handleMenus = (event) => {
        setMenus(event.target.value);
    }

    const handleFilter = (event) => {
        setFilter(event.target.value);
    }

    let [tdClick, setTdClick] = useState(0);
    useEffect(() => {
        if (tdClick == 1){
            setTipe(2);
            setMenus('rab')
        }else if (tdClick == 2){
            setTipe(1);
            setMenus('mag')
        }

    }, [tdClick]);

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="table-responsive m-1">
                        <table className="table" id="nav-table">
                            <tbody>
                                <tr>
                                    <td id={menus == 'mag' ? "nav-active" : "nav-no-active"} onClick={() => {setTdClick(2)}}>
                                        <b className="text-xs">Mata Agr</b>
                                    </td>
                                    <td id={menus == 'rab' ? "nav-active" : "nav-no-active"} onClick={() => {setTdClick(1)}}>
                                        <b className="text-xs">RAB</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Transaksi Mata Anggaran</h5>
                            </div>

                            <div className="ms-auto my-auto mt-lg-0 mt-4">
                                <div className="ms-auto my-auto">
                                    <Link to="/trx-mata-anggaran/form" className="btn btn-xs btn-success">
                                        <i className="fas fa-plus-square fa-lg me-1"></i> Tambah
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        {/* <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    Filter By
                                    <select className="form-select" onChange={handleMenus}>
                                        <option value="mag">Mata Anggaran</option>
                                        <option value="rab">RAB</option>
                                    </select>
                                </div>
                            </div>
                        </div> */}
                        {menus == 'mag' ?
                        <div className="row mb-4">
                            <div className="col-lg-12">
                                Pilih Mata Anggaran
                                {mataAgr.length !== 0 ?
                                    <select className="form-select" onChange={handleFilter}>
                                        <option value="all">Semua</option>
                                        {mataAgr.map((mag) =>
                                            <option key={mag.id} value={mag.kode}>{mag.kode}</option>
                                        )}
                                    </select>:<select className="form-select"></select>}
                            </div>
                        </div>:null}
                        {menus == 'rab' ?
                        <div className="row mb-4">
                            <div className="col-lg-12">
                                Pilih RAB
                                {rab.length !== 0 ?
                                    <select className="form-select" onChange={handleFilter}>
                                        <option value="all">Semua</option>
                                        {rab.map((r) =>
                                            <option key={r.id} value={r.nama}>{r.nama}</option>
                                        )}
                                    </select>:<select className="form-select"></select>}
                            </div>
                        </div>:null}
                        <ToolkitProvider
                            keyField="id"
                            data={ data }
                            columns={ columns }
                            search>
                            {
                                props => (
                                    <div>
                                        <SearchBar { ...props.searchProps } />
                                        <br />
                                        <BootstrapTable
                                            keyField="id"
                                            { ...props.baseProps }
                                            pagination={paginationFactory({
                                                onPageChange: (page, sizePerPage) => {
                                                    setPage(page);
                                            }})}
                                            bordered={ false }
                                            rowEvents={ rowEvents }
                                            hover
                                            wrapperClasses="table-responsive"
                                            classes="table table-responsive"
                                            rowClasses="text-sm font-weight-normal"
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TrxMataAnggaranList