import axios from 'axios';
import {Link} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {api_list_web} from '../services/ApiService';

const Content = () => {
    const [data, setData] = useState([]);

    const getData = () => {
        axios.get(api_list_web).then((response) => {
            setData(response.data);

        }).catch(() => console.log('error get_web_all'));
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <section className="py-0">
            <div className="row">
                <div className="col-md-8 me-auto text-left">
                    <h5 className="text-white">My UMKT</h5>
                    <p className="text-white">Layanan aplikasi utama dari unit IT UMKT </p>
                    <a href="https://my.umkt.ac.id/semua-website/" className="btn btn-sm btn-danger">semua list website</a>
                </div>
            </div>
            <div className="row mt-lg-4 mt-2">
                {data.map((val, key) => (
                    <div key={key} className="col-lg-4 col-md-6 mb-4">
                        <div className="card h-100">
                            <div className="card-body p-3">
                                <div className="d-flex">
                                    <div className="avatar avatar-xl bg-gradient-dark border-radius-md p-2">                      
                                        <img src={val.my_icon} alt="slack_logo"/>
                                    </div>
                                    <div className="ms-3 my-auto" title={val.nama}>
                                        <h6>{val.nama}</h6>
                                    </div>
                                </div>
                                <p className="text-sm mt-3" title={val.deskripsi}>
                                    {val.deskripsi.length > 200 ? val.deskripsi.substring(0, 200) + ' ...' : val.deskripsi}
                                </p>
                            </div>
                            <div className="d-lg-flex">
                                <div className="ms-auto my-auto mt-lg-0 mt-2 me-3">
                                    <Link to={val.url} onClick={(e) => e.stopPropagation()} className="btn btn-danger btn-sm">
                                    <i className="ni ni-send"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
        </>
    )
}

export default Content