import clientSarpras from "./ApiClientSarpras";
import clientSarprasDjango from "./ApiClientSarprasDjango";
import { api_aset_inventaris, api_aset_depresiasi, api_detail_inventaris, api_depresiasi_multi_inventaris, 
         api_search_inventaris, api_search_depresiasi, api_ruangan_list } from "./ApiService";


export function GetInventarisList(page){
    return clientSarpras.get(api_aset_inventaris+page);
}

export function GetDepresiasiList(page){
    return clientSarpras.get(api_aset_depresiasi+page);
}

export function GetDetailInventaris(id){
    return clientSarpras.get(api_detail_inventaris+'/'+id);
}

export function UpdateDepresiasiMultiInventaris(data){
    return clientSarpras.post(api_depresiasi_multi_inventaris, data);
}

export function GetSearchInventaris(page, param){
    return clientSarpras.get(api_search_inventaris+page, {params: param});
}

export function GetSearchDepresiasi(page, param){
    return clientSarpras.get(api_search_depresiasi+page, {params: param});
}

export function GetRuangan(){
    return clientSarprasDjango.get(api_ruangan_list);
}

