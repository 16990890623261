import {GetPeriodeList, GetPic, GetRabDetail, UpdateRab} from "../../../services/KeuanganService";
import {useEffect, useState} from "react";
import {useNavigate, useParams, Link} from "react-router-dom";
import { swalCustom } from "../../../components/CustomStyle";
import CurrencyInput from "react-currency-input-field";
import Select from 'react-select'

const RabEdit = () => {
    let [data, setData] = useState({});
    const {id} = useParams();
    const navigate = useNavigate();
    let [periodeList, setPeriodeList] = useState([]);
    let [periodeDetail, setPeriodeDetail] = useState(0);

    let [picList, setPicList] = useState([]);
    let [picDef, setPicDef] = useState([]);
    let optPic = [];
    let optData = [];

    const getPic = () => {
        GetPic().then((response) => {
            let resp = response.data.rows;
            for (let i = 0; i < resp.length; i++){
                let opt = {
                    value: resp[i].user[0].username,
                    label: resp[i].nama_bergelar,
                }
                optPic.push(opt);

                if (data.pic == resp[i].user[0].username){
                    let optMatch = {
                        value: resp[i].user[0].username,
                        label: resp[i].nama_bergelar,
                    }
                    optData.push(optMatch);
                }
            }

            setPicList(optPic);
            setPicDef(optData);

        }).catch(() => console.log('error get_pic'));
    }

    const getPeriode = () => {
        GetPeriodeList().then((response) => {
            setPeriodeList(response.data.rows);

        }).catch(() => console.log('error get_periode_all'));
    }

    useEffect(() => {
        GetRabDetail(id).then((response) => {
            setData(response.data);
            setPeriodeDetail(response.data.tahun);

        }).catch(() => console.log('error get_rab_detail'));
    }, [id])

    useEffect(() => {
        getPic();
        getPeriode();
    }, [data])

    const handleSubmit = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('nama', event.target.nama.value);
        // formData.append('nominal', event.target.nominal.value);
        // formData.append('nominal', event.target.nominal.value.replace(/[^0-9]/g, ''));
        formData.append('nominal', event.target.nominal.value.replace(/[^\d.-]/g, ''));
        formData.append('tahun', event.target.tahun.value);
        formData.append('pic', event.target.pic.value);

        UpdateRab(id, formData).then(response => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil diubah",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true){
                    navigate('/rab');
                }
            });
        }).catch(response => {
            swalCustom.fire({
                title: "Gagal",
                text: "Data gagal diubah",
                type: "error",
            });
        });
    };

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">RAB Edit</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                Nama
                                                <input type="text" name="nama" value={data.nama} className="form-control"
                                                       onChange={x => setData({...data, nama: x.target.value})} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Nominal
                                                {/* <input type="text" name="nominal" value={data.nominal} className="form-control"
                                                       onChange={x => setData({...data, nominal: x.target.value})} /> */}

                                                {data.nominal != null ?
                                                <CurrencyInput 
                                                    name="nominal" 
                                                    className="form-control" 
                                                    prefix="Rp " 
                                                    defaultValue={data.nominal}
                                                    onChange={x => setData({...data, nonimal: x.target.value})}
                                                    decimalsLimit={2} />:null}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                {/* Tahun
                                                <input type="text" name="tahun" value={data.tahun} className="form-control"
                                                       onChange={x => setData({...data, tahun: x.target.value})} /> */}

                                                Tahun
                                                {periodeList.length !== 0 ?
                                                    <select className="form-select" name="tahun">
                                                        {periodeList.map((periode) =>
                                                            <option 
                                                                key={periode.id} 
                                                                value={periode.tahun}
                                                                selected={periodeDetail == periode.tahun ? 'selected' : ''}
                                                                >{periode.tahun}</option>
                                                        )}
                                                    </select>:null}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                PIC
                                                {picDef.length > 0 ?
                                                <Select name="pic" options={picList} defaultValue={picDef[0]}/>:null}
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <Link to="/rab" className="btn btn-xs btn-secondary me-1">
                                        <i className="fas fa-chevron-circle-left me-2"></i>Kembali
                                    </Link>
                                    <button className="btn btn-xs btn-success" type="submit">
                                        <i className="fas fa-save me-2"></i>Simpan
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RabEdit