import {Cookies} from 'react-cookie';
import {useEffect, useState} from "react";
import {swalCustom} from "../../../components/CustomStyle";

import {useNavigate, useParams, Link} from "react-router-dom";
import {GetAnggaranDetail, GetPeriodeList, UpdateAnggaran} from "../../../services/KeuanganService";

const AnggaranPimpinanEdit = () => {
    const navigate = useNavigate();
    let {id} = useParams();

    let cookies = new Cookies();
    let username = cookies.get('username');
    let homebase = cookies.get('homebase_kode');

    let [detail, setDetail] = useState({});
    let [periode, setPeriode] = useState([]);

    const getPeriode = () => {
        GetPeriodeList().then((response) => {
            setPeriode(response.data.rows);

        }).catch(() => console.log('error get_kode_anggaran_all'));
    }

    const getDetail = () => {
        GetAnggaranDetail(homebase, id).then((response) => {
            setDetail(response.data);

        }).catch(() => console.log('error get_periode_all'));
    }

    useEffect(() => {
        getPeriode();
        getDetail();
    }, [id])

    const handleSubmit = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('periode_id', event.target.periode.value);
        formData.append('jenis_anggaran', event.target.jenis.value);
        formData.append('nama_kegiatan', event.target.kegiatan.value);
        formData.append('total_anggaran', event.target.total_anggaran.value);
        formData.append('kode_lembaga', homebase);
        formData.append('created_by', username);

        UpdateAnggaran(homebase, id, formData).then(() => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil diubah",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true){
                    navigate('/anggaran');
                }
            });
        }).catch(() => {
            swalCustom.fire({
                title: "Gagal",
                text: "Data gagal ditambah",
                type: "error",
            });
        });
    };

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Anggaran Edit</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                Periode
                                                <select className="form-control" name="periode" value={detail.periode}
                                                        onChange={x => setDetail({...detail, periode: x.target.value})}>
                                                    {periode.map((v, k) => {
                                                        return <option value={v.id}>{v.tahun}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Jenis Anggaran
                                                <select className="form-control" name="jenis" value={detail.jenis_anggaran}
                                                        onChange={x => setDetail({...detail, jenis_anggaran: x.target.value})}>
                                                    <option value="Apple">Apple</option>
                                                    <option value="Non Apple">Non Apple</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Nama Kegiatan
                                                <input className="form-control" type="text" name="kegiatan" value={detail.nama_kegiatan}
                                                       onChange={x => setDetail({...detail, nama_kegiatan: x.target.value})} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Total Anggaran
                                                <input className="form-control" type="number" name="total_anggaran" value={detail.total_anggaran}
                                                       onChange={x => setDetail({...detail, total_anggaran: x.target.value})} />
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <Link to="/anggaran-pimpinan" className="btn btn-xs btn-secondary me-1">
                                        <i className="fas fa-chevron-circle-left me-2"></i>Kembali
                                    </Link>
                                    <button className="btn btn-xs btn-success" type="submit">
                                        <i className="fas fa-save me-2"></i>Simpan
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnggaranPimpinanEdit