import { useState } from "react";
import { tdStickLeft } from "./CustomStyle";
import { FormatRupiah } from "@arismun/format-rupiah";

export function RabParent({row, defaultIsOpen = false, children}) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  return (
    <>
      <tr>
        <td onClick={() => setIsOpen(!isOpen)} className="text-sm font-weight-normal" style={tdStickLeft}>{row.kode} {row.nama}</td>
        <td className="text-sm font-weight-normal">
            {row.nominal > 0 ? <FormatRupiah value={row.nominal} /> : ''}
        </td>
        <td className="text-sm font-weight-normal">
            {row.detail['9'] > 0 ? <FormatRupiah value={row.detail['9']} /> : ''}
        </td>
        <td className="text-sm font-weight-normal">
            {row.detail['10'] > 0 ? <FormatRupiah value={row.detail['10']} /> : ''}
        </td>
        <td className="text-sm font-weight-normal">
            {row.detail['11'] > 0 ? <FormatRupiah value={row.detail['11']} /> : ''}
        </td>
        <td className="text-sm font-weight-normal">
            {row.detail['12'] > 0 ? <FormatRupiah value={row.detail['12']} /> : ''}
        </td>
        <td className="text-sm font-weight-normal">
            {row.detail['1'] > 0 ? <FormatRupiah value={row.detail['1']} /> : ''}
        </td>
        <td className="text-sm font-weight-normal">
            {row.detail['2'] > 0 ? <FormatRupiah value={row.detail['2']} /> : ''}
        </td>
        <td className="text-sm font-weight-normal">
            {row.detail['3'] > 0 ? <FormatRupiah value={row.detail['3']} /> : ''}
        </td>
        <td className="text-sm font-weight-normal">
            {row.detail['4'] > 0 ? <FormatRupiah value={row.detail['4']} /> : ''}
        </td>
        <td className="text-sm font-weight-normal">
            {row.detail['5'] > 0 ? <FormatRupiah value={row.detail['5']} /> : ''}
        </td>
        <td className="text-sm font-weight-normal">
            {row.detail['6'] > 0 ? <FormatRupiah value={row.detail['6']} /> : ''}
        </td>
        <td className="text-sm font-weight-normal">
            {row.detail['7'] > 0 ? <FormatRupiah value={row.detail['7']} /> : ''}
        </td>
        <td className="text-sm font-weight-normal">
            {row.detail['8'] > 0 ? <FormatRupiah value={row.detail['8']} /> : ''}
        </td>
        <td className="text-sm font-weight-normal">
            {row.total > 0 ? <FormatRupiah value={row.total} /> : ''}
        </td>
      </tr>
      {isOpen && children}
    </>
  );
}