import {useNavigate} from "react-router-dom";
import {GetAllRekening, GetKodeAnggaranList, GetRabList, GetUnitKerja, PostMataAnggaran} from "../../../services/KeuanganService";
import {useEffect, useState} from "react";
import { Cookies } from 'react-cookie';
import { swalCustom } from '../../../components/CustomStyle';
import CurrencyInput from "react-currency-input-field";

const TrxMataAnggaranForm = () => {
    const navigate = useNavigate();
    let [kode, setKode] = useState([]);
    let [rab, setRab] = useState([]);
    let [ckRab, setCkRab] = useState(false);
    let [unitKerja, setUnitKerja] = useState([]);
    let [isSelesai, setIsSelesai] = useState(false);
    let [jenis, setJenis] = useState('Keluar');
    let [menus, setMenus] = useState('mag');
    let [mataAgr, setMataAgr] = useState('');
    let [kd, setKd] = useState('');
    let [ku, setKu] = useState('');
    let [rek, setRek] = useState([]);

    const cookies = new Cookies();
    const homebase = cookies.get('homebase_kode');
    const username = cookies.get('username');

    let [rekAsal, setRekAsal] = useState('');
    let [rekTujuan, setRekTujuan] = useState('');
    let [predictAsal, setPredictAsal] = useState([]);
    let [predictTujuan, setPredictTujuan] = useState([]);

    const INPUT_TIMEOUT = 250;

    const getPredictions = (value) => {        
        const cari_kode = rek.filter(item => String(item.kode_lembaga).toLowerCase().indexOf(value.toLowerCase()) !== -1);
        const cari_nama = rek.filter(item => String(item.nama).toLowerCase().indexOf(value.toLowerCase()) !== -1);
        const cari_rek = rek.filter(item => String(item.nomor_rekening).toLowerCase().indexOf(value.toLowerCase()) !== -1);
        let hasil = []

        if (cari_kode.length > 0){
            cari_kode.map(x => {
                if (hasil.includes(x) == false){
                    hasil.push(x)
                }
            });
        }

        if (cari_nama.length > 0){
            cari_nama.map(x => {
                if (hasil.includes(x) == false){
                    hasil.push(x)
                }
            });
        }

        if (cari_rek.length > 0){
            cari_rek.map(x => {
                if (hasil.includes(x) == false){
                    hasil.push(x)
                }
            });
        }

        return hasil;
    }

    const handleRekAsal = (event) => {
      const value = event.target.value;
      setRekAsal(value);

      if (value.length > 0) {
        setTimeout(() => {
          const predictions = getPredictions(value);
          setPredictAsal(predictions);
        }, INPUT_TIMEOUT);
      } else {
        setPredictAsal([]);
      }
    }

    const handleRekTujuan = (event) => {
        const value = event.target.value;
        setRekTujuan(value);
  
        if (value.length > 0) {
          setTimeout(() => {
            const predictions = getPredictions(value);
            setPredictTujuan(predictions);
          }, INPUT_TIMEOUT);
        } else {
          setPredictTujuan([]);
        }
    }

    const handleOptAsal = (event) => {
        setRekAsal(event.target.value);
        setPredictAsal([]);
    }

    const handleOptTujuan = (event) => {
        setRekTujuan(event.target.value);
        setPredictTujuan([]);
    }

    const getRekening = () => {
        GetAllRekening().then(response => {
            setRek(response.data.rows);
        })
    }

    const getKode = () => {
        GetKodeAnggaranList().then((response) => {
            setKode(response.data.rows);
            setKd(response.data.rows[0].kode);

        }).catch(() => console.log('error get_kode_anggaran_all'));
    }

    const getUnitKerja = () => {
        GetUnitKerja().then((response) => {
            setUnitKerja(response.data.rows);
            setKu(response.data.rows[0].get_kode);

        }).catch(() => console.log('error get_unit_kerja_all'));
    }

    const getRab = () => {
        GetRabList().then((response) => {
            setRab(response.data.rows);

        }).catch(() => console.log('error get_rab_all'));
    }

    useEffect(() => {
        getKode();
        getRab();
        getUnitKerja();
        getRekening();
    }, [])

    const handleKode = (event) => {
        setKd(event.target.value);
    }

    const handleUnit = (event) => {
        setKu(event.target.value);
    }

    const handleGen = () => {
        setMataAgr(kd + '.' + ku);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let formData = new FormData();

        formData.append('mata_anggaran', mataAgr);

        // if(menus == 'mag'){
        //     formData.append('mata_anggaran', mataAgr);
        //     formData.append('is_rab', false);

        // }else if(menus == 'rab'){
        //     formData.append('mata_anggaran', event.target.rab.value);
        //     formData.append('is_rab', true);
        // }

        if (ckRab == true){
            formData.append('rab', event.target.rab.value);
            formData.append('is_rab', true);
        }else{
            formData.append('is_rab', false);
        }

        formData.append('keterangan', event.target.keterangan.value);
        formData.append('jenis_trx', jenis);
        
        let date_str = event.target.tanggal.value;
        let date_millis = new Date(date_str).getTime();

        // formData.append('tanggal', event.target.tanggal.value);
        formData.append('tanggal', date_millis);
        
        if (jenis == 'Keluar'){
            formData.append('rekening_asal', event.target.rekening_asal.value);
        }
        formData.append('rekening_tujuan', event.target.rekening_tujuan.value);
        formData.append('nominal', event.target.nominal.value.replace(/[^0-9.]/g, ''));

        if (jenis == 'Masuk'){
            formData.append('bukti', event.target.bukti.files[0]);
        }else{
            formData.append('bukti_pengajuan', event.target.bukti_pengajuan.files[0]);
        }

        formData.append('kode_lembaga', homebase);
        formData.append('created_by', username);
        formData.append('is_checker', true);
        formData.append('is_approve', false);
        formData.append('selesai', isSelesai);

        PostMataAnggaran(formData).then(response => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil ditambah",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true){
                    navigate('/trx-mata-anggaran');
                }
            });
        }).catch(response => {
            swalCustom.fire({
                title: "Gagal",
                text: "Data gagal ditambah",
                type: "error",
            });
        });
    };

    const handleUbahJenis = (event) => {
        setJenis(event.target.value);
    }

    const handleMenus = (event) => {
        setMenus(event.target.value);
    }

    const handleCk = (event) => {
        setCkRab(!ckRab);
    }

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Transaksi Mata Anggaran Form</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        {/* <div className="col-12">
                                            <div className="form-group" onChange={handleMenus}>
                                                Pilih
                                                <select className="form-select">
                                                    <option value="mag">Mata Anggaran</option>
                                                    <option value="rab">RAB</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        
                                        {menus == 'mag' ? <>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        Kode Anggaran
                                                        <select className="form-control" name="kode_anggaran" onChange={handleKode}>
                                                            {kode.map((v, k) => {
                                                                return <option value={v.kode}>{v.kode} - {v.keterangan}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        Kode Unit Kerja
                                                        <select className="form-control" name="unit_kerja" onChange={handleUnit}>
                                                            {unitKerja.map((v, k) => {
                                                                return <option value={v.get_kode}>{v.get_kode} - {v.keterangan}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <span style={{fontSize: 12, color: 'red'}}>Klik untuk generate mata anggaran</span><br/>
                                                        <button onClick={handleGen} type="button" className="btn btn-xs btn-outline-primary">Generate</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Mata Anggaran
                                                <input className="form-control" type="text" name="mata_anggaran" value={mataAgr} readOnly/>
                                            </div>
                                        </div></>:null}
                                        
                                        <div className="col-12">
                                            <div className="form-group">
                                                Pilih RAB
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={ckRab} onClick={handleCk}/>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        {menus == 'rab' || ckRab == true ?
                                        <div className="col-12">
                                            <div className="form-group">
                                                RAB
                                                <select className="form-select" name="rab">
                                                    {rab.map((v, k) => {
                                                        return <option value={v.nama + ' ('+v.tahun+')'}>{v.nama} ({v.tahun}) - Penggunaan {v.persentase_penggunaan}%</option>
                                                        // return <option value={v.id}>{v.nama} ({v.tahun}) - Penggunaan {v.persentase_penggunaan}%</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>:null}

                                        <div className="col-12">
                                            <div className="form-group">
                                                Keterangan
                                                <input className="form-control" type="text" name="keterangan" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Jenis
                                                <select className="form-control" name="jenis_trx" onChange={handleUbahJenis}>
                                                    <option value="Keluar">Keluar</option>
                                                    <option value="Masuk">Masuk</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Tanggal
                                                <input className="form-control" type="datetime-local" name="tanggal" />
                                            </div>
                                        </div>
                                        {jenis == 'Keluar' ?
                                        <div className="col-12">
                                            <div className="form-group">
                                                Rekening Asal
                                                <input className="form-control" type="number" name="rekening_asal" value={rekAsal} onChange={handleRekAsal}/>
                                                    {predictAsal.length > 0 ?
                                                        <div className="form-control"> 
                                                        {predictAsal.map((item, index) => (
                                                            <option key={item.id} value={item.nomor_rekening} onClick={handleOptAsal}>
                                                                {item.nomor_rekening} - {item.nama} ({item.kode_lembaga})
                                                            </option>
                                                        ))} 
                                                        </div>:null}
                                            </div>
                                        </div>:null}
                                        <div className="col-12">
                                            <div className="form-group">
                                                Rekening Tujuan
                                                <input className="form-control" type="number" name="rekening_tujuan" value={rekTujuan} onChange={handleRekTujuan} />
                                                    {predictTujuan.length > 0 ?
                                                        <div className="form-control"> 
                                                            {predictTujuan.map((item, index) => (
                                                                <option key={item.id} value={item.nomor_rekening} onClick={handleOptTujuan}>
                                                                    {item.nomor_rekening} - {item.nama} ({item.kode_lembaga})
                                                                </option>
                                                            ))} 
                                                        </div>:null}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Nominal
                                                {/* <input className="form-control" type="text" name="nominal" /> */}
                                                <CurrencyInput name="nominal" className="form-control" prefix="Rp " decimalsLimit={2} />
                                            </div>
                                        </div>
                                        {jenis == 'Masuk' ? 
                                        <div className="col-12">
                                            <div className="form-group">
                                                Bukti
                                                <input className="form-control" type="file" name="bukti" />
                                            </div>
                                        </div>:
                                        <div className="col-12">
                                            <div className="form-group">
                                                Bukti Pengajuan
                                                <input className="form-control" type="file" name="bukti_pengajuan" />
                                            </div>
                                        </div>}
                                    </div>
                                    <br />
                                    <button className="btn btn-xs btn-secondary me-2" type="submit" onClick={() => setIsSelesai(false)}>
                                        <i className="fas fa-pencil-ruler me-2"></i>Draft
                                    </button>
                                    <button className="btn btn-xs btn-success" type="submit" onClick={() => setIsSelesai(true)}>
                                        <i className="fas fa-save me-2"></i>Selesai
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrxMataAnggaranForm