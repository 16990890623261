import {useNavigate, useParams} from "react-router-dom";
import {GetMataAnggaranDetail, UpdateMataAnggaran} from "../../../services/KeuanganService";
import React, {useEffect, useState} from "react";
import Moment from "moment/moment";
import {Link} from 'react-router-dom';
import { detailStyle, header, swalCustom } from '../../../components/CustomStyle';
import { Cookies } from 'react-cookie';
import { FormatRupiah } from "@arismun/format-rupiah";

const TrxMataAnggaranUploadBukti = () => {
    const {id} = useParams();
    let navigate = useNavigate();
    let [detail, setDetail] = useState({});

    const cookies = new Cookies();
    const homebase = cookies.get('homebase_kode');

    const getDetail = (id) => {
        GetMataAnggaranDetail(id).then((response) => {
            setDetail(response.data);

        }).catch(() => console.log('error get_mata_anggaran_detail'));
    }

    useEffect(() => {
        getDetail(id);
    }, [id])

    const handleSubmit = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('mata_anggaran', detail.mata_anggaran);
        formData.append('keterangan', detail.keterangan);
        formData.append('jenis_trx', detail.jenis_trx);
        formData.append('tanggal', detail.tanggal);    
        formData.append('rekening_asal', detail.rekening_asal);
        formData.append('rekening_tujuan', detail.rekening_tujuan);
        formData.append('nominal', detail.nominal);

        if (detail.jenis_trx == 'Keluar'){
            if (event.target.bukti.files[0] !== undefined){
                formData.append('bukti', event.target.bukti.files[0]);
            }
        }

        formData.append('kode_lembaga', detail.kode_lembaga);
        formData.append('is_checker', true);
        formData.append('is_approve', true);
        formData.append('selesai', true);

        UpdateMataAnggaran(id, formData).then(response => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil diubah",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true){
                    navigate('/trx-mata-anggaran');
                }
            });
        }).catch(response => {
            swalCustom.fire({
                title: "Gagal",
                text: "Data gagal ditambah",
                type: "error",
            });
        });
    };

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Transaksi Anggaran Detail</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                {detail.length !== 0 ?
                                    <>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Mata Anggaran
                                            </div>
                                            <div className="col-md-10">
                                                <b>{detail && detail.mata_anggaran}</b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Keterangan
                                            </div>
                                            <div className="col-md-5">
                                                {detail.keterangan}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Rekening Asal
                                            </div>
                                            <div className="col-md-5">
                                                {detail.rekening_asal}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Rekening Tujuan
                                            </div>
                                            <div className="col-md-5">
                                                {detail.rekening_tujuan}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Nominal
                                            </div>
                                            <div className="col-md-5">
                                                <b><FormatRupiah value={detail.nominal} /></b>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Tanggal
                                            </div>
                                            <div className="col-md-5">
                                                {Moment(detail.tanggal).format('DD MMMM YYYY')}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2" style={detailStyle}>
                                                Status
                                            </div>
                                            <div className="col-md-5">
                                                {detail.is_checker == true ? 
                                                    <span className="badge badge-success text-success"> Checker </span> : 
                                                    <span className="badge badge-danger text-danger"> Checker </span>} <br/>
                                                {detail.is_approve == true ? 
                                                    <span className="badge badge-success text-success"> Approval </span> : 
                                                    <span className="badge badge-danger text-danger"> Approval </span>}
                                                </div>
                                            </div>
                                        <form onSubmit={handleSubmit}>
                                            <div className="row mt-3">
                                                <div className="col-md-2">
                                                    Bukti
                                                </div>
                                                <div className="col-md-5">
                                                    <input className="form-control" type="file" name="bukti" required />
                                                </div>
                                            </div>
                                            <br/>
                                            <Link to="/trx-unit" className="btn btn-sm btn-secondary me-2">
                                                <i className="fas fa-chevron-circle-left me-2"></i>Kembali
                                            </Link>
                                            <button className="btn btn-sm btn-success" type="submit">
                                                <i className="fas fa-save me-2"></i>Selesai
                                            </button>
                                        </form>
                                    </>:null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrxMataAnggaranUploadBukti