import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Moment from 'moment';
import { Cookies } from 'react-cookie';

import {GetListTrxAgrEdit, GetListTrxUnitEdit, GetRequestTrxAgrEdit, GetRequestTrxUnitEdit} from "../../../services/KeuanganService";
import { header, numTable, swalCustom } from '../../../components/CustomStyle';
import { FormatRupiah } from '@arismun/format-rupiah';

const TrxUnitAnggaranReditList = () => {
    const navigate = useNavigate();
    const url_trx_unit_detail = '/trx-unit/detail/'
    const url_trx_agr_detail = '/trx-mata-anggaran/detail/'

    const { SearchBar } = Search;
    let [page, setPage] = useState(1);
    
    let [trxUnitList, setTrxUnitList] = useState([]);
    let [trxAgrList, setTrxAgrList] = useState([]);
    let [menus, setMenus] = useState('agr');

    const getTrxUnitList = () => {
        GetListTrxUnitEdit().then((response) => {
            setTrxUnitList(response.data.rows);

        }).catch(() => console.log('error get_trx_unit_edit_all'));
    }

    const getTrxAgrList = () => {
        GetListTrxAgrEdit().then((response) => {
            setTrxAgrList(response.data.rows);

        }).catch(() => console.log('error get_trx_agr_edit_all'));
    }

    useEffect(() => {
        if (menus == 'unit'){
            getTrxUnitList();
        }else if (menus == 'agr'){
            getTrxAgrList();
        }
    }, [menus])

    const openSwal = (id) => {
        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin mengijinkan edit transaksi ini ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                if (menus == 'unit'){
                    GetRequestTrxUnitEdit(id).then(response => {
                        swalCustom.fire('Berhasil ijinkan edit!', '', 'success')
                    })
                }else if (menus == 'agr'){
                    GetRequestTrxAgrEdit(id).then(response => {
                        swalCustom.fire('Berhasil ijinkan edit!', '', 'success')
                    })
                }
            }
        })
    }

    const namaTruncate = (cell, row, rowIndex, formatExtraData) => {
        const name = row.anggaran.nama_kegiatan;
        const ket = row.keterangan;
        const tc = 25;
        return (
            <>
            <b>{name.length > tc ? name.substring(0, tc) + ' ...' : name}</b>
            <br/>
            {ket.length > tc ? ket.substring(0, tc) + ' ...' : ket}
            </>
        );
    }

    const columns_unit = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    }, {
        text: 'Anggaran',
        dataField: 'anggaran.nama_kegiatan',
        title: (cell, row, rowIndex) => {
            return row.anggaran.nama_kegiatan
        },
        formatter: namaTruncate,
        headerStyle: header
    },{
        text: 'Total Transaksi',
        dataField: 'total_transaksi',
        formatter: (cell, row, rowIndex) => {
            return <b><FormatRupiah value={row.total_transaksi} /></b>;
        },
        headerStyle: header
    },{
        text: 'Jenis',
        dataField: 'status_trx',
        title: (cell, row, rowIndex) => {
            if (row.status_trx == 0){
                return 'Keluar'
            }else{
                return 'Kedalam'
            }
        },
        formatter: (cell, row, rowIndex) => {
            return <>
                {row.status_trx == 1 ? 
                <i className="fas fa-arrow-circle-left text-info"></i> : 
                <i className="fas fa-arrow-circle-right text-success"></i>}
            </>
        },
        headerStyle: header
    },{
        text: 'Tanggal',
        dataField: 'created_at',
        formatter: (cell, row, rowIndex) => {
            return <>
                {Moment(row.tanggal).format('DD MMMM YYYY')}<br/>
                {row.created_by}
            </>
        },
        headerStyle: header,
        headerFormatter: (cell, row, rowIndex) => {
            return (
                <>
                    Tanggal<br/>
                    Dibuat Oleh
                </>
            )
        }
    },{
        text: 'Status',
        dataField: 'is_checker',
        formatter: (cell, row, rowIndex) => {
            return <>
                {row.status_trx == 0 ? <>
                    {/* {row.is_checker === false ? 
                        <span className="badge badge-danger text-danger"> Checker </span> : 
                        <span className="badge badge-success text-success"> Checker </span>}
                    <br/> */}
                    {row.is_approve === false ? 
                        <span className="badge btn-xs badge-danger text-danger"> Approval </span> : 
                        <span className="badge btn-xs badge-success text-success"> Approval </span>}
                </>:null}
            </>
        },
        headerStyle: header
    },{
        text: 'Action',
        dataField: 'button',
        formatter: (cell, row, rowIndex) => {
            return <>
                {row.is_edit == 0 && row.selesai == 1 ? 
                    <button className='btn btn-xs btn-warning' onClick={(e) => {e.stopPropagation(); openSwal(row.id)}}>
                        <i className="fas fa-hand text-white me-1"></i> Izinkan Edit
                    </button>:null}
            </>
        },
        headerStyle: header
    }];


    const ketTruncate = (cell, row, rowIndex, formatExtraData) => {
        const tc = 25;
        const tm = 30;
        return (
            <>
            {row.is_rab == true ? <><span className="badge badge-primary text-primary">RAB</span><br/></> : ''}
            <b title={row.mata_anggaran}>
                {row.mata_anggaran.length > tm ? row.mata_anggaran.substring(0, tm) + '...' : row.mata_anggaran}
            </b>
            <br/>
            <span title={row.keterangan}>
                {row.keterangan.length > tc ? row.keterangan.substring(0, tc) + ' ...' : row.keterangan}
            </span>
            </>
        );
    }

    const asalTujuan = (cell, row, rowIndex, formatExtraData) => {
        const tc = 25;
        return (
            <>
            <div className='row'>
                <div className='col-lg-12 mb-2'>
                    Rekening Asal<br/>
                    <span className="badge badge-success badge-sm" style={{fontSize: 12}}>
                        {row.rekening_asal != null && row.rekening_asal != '' ? row.rekening_asal : '-'}
                    </span>
                </div>
                <div className='col-lg-12'>
                    Rekening Tujuan<br/>
                    <span className="badge badge-info badge-sm" style={{fontSize: 12}}>
                        {row.rekening_tujuan}
                    </span>
                </div>
            </div>
             <br/>
            </>
        );
    }

    const columns_agr = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    },{
        text: 'Keterangan',
        dataField: 'keterangan',
        formatter: ketTruncate,
        headerStyle: header
    },{
        text: 'Rekening',
        dataField: 'rekening_asal',
        formatter: asalTujuan,
        headerStyle: header
    },{
        text: 'Nominal',
        dataField: 'nominal',
        formatter: (cell, row, rowIndex) => {
            return <b><FormatRupiah value={row.nominal} /></b>;
        },
        headerStyle: header
    },{
        text: 'Tanggal',
        dataField: 'tanggal',
        formatter: (cell, row, rowIndex) => {
            return <>
                {Moment(row.tanggal).format('DD MMMM YYYY')}<br/>
                {row.created_by}
            </>
        },
        headerStyle: header
    },{
        text: 'Status',
        dataField: 'is_checker',
        formatter: (cell, row, rowIndex) => {
            return <>
                {/* {row.is_checker === false ? 
                    <span className="badge badge-danger text-danger"> Checker </span> : 
                    <span className="badge badge-success text-success"> Checker </span>}
                <br/> */}
                {row.is_approve === false ? 
                    <span className="badge btn-xs badge-danger text-danger"> Approval </span> : 
                    <span className="badge btn-xs badge-success text-success"> Approval </span>}
            </>
        },
        headerStyle: header
    },{
        text: 'Action',
        dataField: 'button',
        formatter: (cell, row, rowIndex) => {
            return <>
                {row.is_edit == 0 && row.selesai == 1 ? 
                    <button className='btn btn-xs btn-warning' onClick={(e) => {e.stopPropagation(); openSwal(row.id)}}>
                        <i className="fas fa-hand text-white me-1"></i> Izinkan Edit
                    </button>:null}
            </>
        },
        headerStyle: header
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            let path = '';
            if (menus == 'unit'){
                path = url_trx_unit_detail + row.id;
            }else if (menus == 'agr'){
                path = url_trx_agr_detail + row.id;
            }
            navigate(path);
        }
    }

    const handleMenus = (event) => {
        setMenus(event.target.value);
    }

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Daftar Seluruh Transaksi Approved</h5>
                            </div>

                            <div className="ms-auto my-auto mt-lg-0 mt-4">
                                <div className="ms-auto my-auto"></div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    Pilih Transaksi
                                    <select className="form-select" onChange={handleMenus}>
                                        <option value="agr">Anggaran</option>
                                        <option value="unit">Unit</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <ToolkitProvider
                            keyField="id"
                            data={ menus == 'agr' ? trxAgrList : trxUnitList }
                            columns={ menus == 'agr' ? columns_agr : columns_unit }
                            search>
                            {
                                props => (
                                    <div>
                                        <SearchBar { ...props.searchProps } />
                                        <br />
                                        <BootstrapTable
                                            keyField="id"
                                            { ...props.baseProps }
                                            pagination={paginationFactory({
                                                onPageChange: (page, sizePerPage) => {
                                                    setPage(page);
                                            }})}
                                            bordered={ false }
                                            rowEvents={ rowEvents }
                                            hover
                                            wrapperClasses="table-responsive"
                                            classes="table table-responsive"
                                            rowClasses="text-sm font-weight-normal"
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TrxUnitAnggaranReditList
