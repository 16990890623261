import Moment from "moment/moment";
import {Cookies} from 'react-cookie';
import {Link} from 'react-router-dom';

import React, {useEffect, useState} from "react";
import {FormatRupiah} from "@arismun/format-rupiah";
import ModalDialog from "../../../components/Modal";

import BootstrapTable from "react-bootstrap-table-next";
import {useParams, useNavigate} from "react-router-dom";
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from "react-bootstrap-table2-paginator";

import {detailStyle, header, numTable, swalCustom} from '../../../components/CustomStyle';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {DeleteDetailTransaksiUnit, GetTransaksiUnitDetail, UpdateDetailTransaksiUnit} from "../../../services/KeuanganService";

const TrxUnitNotaDetail = () => {
    const url_rincian_form = '/trx-unit/rincian-form/'

    const navigate = useNavigate();
    const {id} = useParams();

    let {SearchBar} = Search;
    let cookies = new Cookies();
    let homebase = cookies.get('homebase_kode');

    let [page, setPage] = useState(1);
    let [detail, setDetail] = useState({});
    let [detailTrx, setDetailTrx] = useState([]);

    const goBack = () => {
		navigate(-1);
	}

    const getDetail = (id) => {
        GetTransaksiUnitDetail(homebase, id).then((response) => {
            setDetail(response.data);
            setDetailTrx(response.data.detail_transaksi);

        }).catch(() => console.log('error get_trx_unit_detail'));
    }

    const updateDetail = (column, newVal, row) => {
        let formData = new FormData();

        formData.append('transaksi_unit_id', id);
        formData.append('rekening_tujuan', column == 'rekening_tujuan' ? newVal : row.rekening_tujuan);
        formData.append('keterangan', column == 'keterangan' ? newVal : row.keterangan);
        formData.append('nominal', column == 'nominal' ? newVal : row.nominal);

        UpdateDetailTransaksiUnit(homebase, row.id, formData).then((response) => {
            getDetail(id);

        }).catch(() => console.log('error update_trx_unit_rincian'));
    }

    function beforeSaveCell(oldValue, newValue, row, column, done) {
        setTimeout(() => {
            if (detail.selesai == 0){
                swalCustom.fire({
                    type: 'question',
                    title: 'Anda yakin ingin mengubah data ?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak',
                }).then((result) => {
                    if (result.value === true) {
                        updateDetail(column.dataField, newValue, row);
                    }
                })

            }else{
                swalCustom.fire('Data tidak bisa diubah!', '', 'danger')
            }

        }, 0);
        return { async: true };
    }

    useEffect(() => {
        getDetail(id);
    }, [id])

    const opensweetalert = (id_detail) => {
        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin menghapus ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                DeleteDetailTransaksiUnit(homebase, id_detail).then(response => {
                    swalCustom.fire('Berhasil dihapus!', '', 'success')
                    getDetail(id);
                })
            }
        })
    }

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header,
        editable: false
    }, {
        text: 'Rekening Tujuan',
        dataField: 'rekening_tujuan',
        headerStyle: header,
        editable: (cell, row, rowIndex) => {
            if (detail.selesai == 1){
                return false
            }else{
                return true
            }
        }
    },{
        text: 'Keterangan',
        dataField: 'keterangan',
        headerStyle: header,
        editable: (cell, row, rowIndex) => {
            if (detail.selesai == 1){
                return false
            }else{
                return true
            }
        }
    },{
        text: 'Nominal',
        dataField: 'nominal',
        headerStyle: header,
        formatter: (cell, row, rowIndex) => {
            return <FormatRupiah value={row.nominal} />
        },
        editable: (cell, row, rowIndex) => {
            if (detail.selesai == 1){
                return false
            }else{
                return true
            }
        }
    },{
        text: 'Action',
        dataField: 'button',
        formatter: (cell, row, rowIndex) => {
            return <>
                {detail.selesai == 0 ? <>
                    <button onClick={(e) => {e.stopPropagation(); opensweetalert(row.id)}} className='btn btn-xs btn-danger'>
                        <i className="fas fa-trash text-white"></i>
                    </button></>:null}
            </>
        },
        headerStyle: header,
        editable: false
    }];

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Transaksi Unit Detail</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            {detail.no_checker == 1 && detail.selesai == 0 ? 
                                <span className="badge badge-danger text-danger m-3">
                                    <b>{detail.note_checker}</b>
                                </span>:null
                            }
                            {detail.no_approve == 1 && detail.selesai == 0 ? 
                                <span className="badge badge-danger text-danger m-3">
                                    <b>{detail.note_approve}</b>
                                </span>:null
                            }
                            <div className="card-body">
                                {detail.length !== 0 ?
                                    <>
                                        <div className="row">
                                            <div className="col-9">
                                                <div className="row mb-1">
                                                    <div className="col-md-2" style={detailStyle}>
                                                        Kode Lembaga
                                                    </div>
                                                    <div className="col-md-10">
                                                        {detail.kode_lembaga}
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-2" style={detailStyle}>
                                                        Anggaran
                                                    </div>
                                                    <div className="col-md-10">
                                                        {detail.anggaran && detail.anggaran.nama_kegiatan}
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-2" style={detailStyle}>
                                                        Keterangan
                                                    </div>
                                                    <div className="col-md-10">
                                                        {detail.keterangan}
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-2" style={detailStyle}>
                                                        Total Anggaran
                                                    </div>
                                                    <div className="col-md-10">
                                                        <b>{detail.anggaran && <FormatRupiah value={detail.anggaran.total_anggaran} />}</b>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-2" style={detailStyle}>
                                                        Tanggal
                                                    </div>
                                                    <div className="col-md-10">
                                                        {Moment(detail.tanggal).format('DD MMMM YYYY hh:mm')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="row">
                                                    {detail.status_trx == 0 ? <>
                                                        {detail.is_checker == false ? 
                                                            <span className="badge badge-danger text-danger mb-2"> Checker </span> : 
                                                            <span className="badge badge-success text-success mb-2"> Checker </span>}

                                                        {detail.is_approve == false ? 
                                                            <span className="badge btn-xs badge-danger text-danger"> Approval </span> : 
                                                            <span className="badge btn-xs badge-success text-success"> Approval </span>}
                                                    </>:null}
                                                    <span className="mt-2"></span>
                                                    <ModalDialog 
                                                        titles={'Bukti Nota'}
                                                        datas={detail.bukti}
                                                        verifs={detail.verif_bukti} />

                                                    <ModalDialog 
                                                        titles={'Bukti Transfer'} 
                                                        datas={detail.bukti_tf}
                                                        verifs={detail.verif_bukti_tf} />
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="row mb-1">
                                            {detail.status_trx == 0 ? <>
                                                <div className="col-md-4">
                                                    <span style={detailStyle}>Rekening Asal</span> <br/>
                                                    {detail.nama_rekening_asal} <br/>
                                                    <b>{detail.rekening_asal}</b><br/>
                                                    <b><FormatRupiah value={detail.saldo_rekening_asal} /></b>
                                                </div></>:null}

                                            {detail.is_payroll == 0 ? <>
                                                {detail.status_trx == 0 ?
                                                    <div className="col-md-2">
                                                        <br/>
                                                        <i className="fa fa-arrow-circle-right fa-xl text-success"></i>
                                                    </div>:null}

                                            <div className="col-md-4">
                                                <span style={detailStyle}>Rekening Tujuan</span> <br/>
                                                {detail.nama_rekening_tujuan} <br/>
                                                <b>{detail.rekening_tujuan}</b><br/>
                                                <b><FormatRupiah value={detail.saldo_rekening_tujuan} /></b>
                                            </div> </>:null}

                                            <div className="col-md-2">
                                                <span style={detailStyle}>Total Transaksi</span> <br/>
                                                <b><FormatRupiah value={detail.total_transaksi} /></b>
                                            </div>
                                        </div>
                                        <br/>

                                        {detail.selesai == 1 && detail.is_checker == 0 && detail.is_approve == 0 ?
                                            <Link to={url_rincian_form+detail.id}className="btn btn-xs btn-success">
                                                <i className="fas fa-plus-square fa-lg me-1"></i> Tambah Detail
                                            </Link>:null}
                                        
                                        {detail.is_payroll == 1 ? <>
                                            <div className="col-md-2 mb-3" style={detailStyle}>
                                                Detail Rekening Tujuan 
                                            </div>
                                            <ToolkitProvider
                                                keyField="id"
                                                data={ detailTrx }
                                                columns={ columns }
                                                search>
                                                {
                                                    props => (
                                                        <div>
                                                            <SearchBar { ...props.searchProps } />
                                                            <br />
                                                            <BootstrapTable
                                                                keyField="id"
                                                                { ...props.baseProps }
                                                                pagination={paginationFactory({
                                                                    onPageChange: (page, sizePerPage) => {
                                                                        setPage(page);
                                                                }})}
                                                                bordered={ false }
                                                                wrapperClasses="table-responsive"
                                                                classes="table table-responsive"
                                                                rowClasses="text-sm font-weight-normal"
                                                                cellEdit={ cellEditFactory({
                                                                    mode: 'click',
                                                                    beforeSaveCell
                                                                }) }
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </ToolkitProvider></>:null}
                                        <br/>
                                        <button onClick={goBack} className="btn btn-sm btn-secondary">
                                            <i className="fas fa-chevron-circle-left me-2"></i>Kembali
                                        </button>
                                    </>:null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrxUnitNotaDetail