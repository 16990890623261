import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';

function ModalDialog({titles, datas, verifs}) {
    const [isModal, setIsModal] = useState(false)
    let data = ''+datas;

    const initModal = (e) => {
        e.preventDefault();
        return setIsModal(!isModal)
    }

    return (
    <>
        <button className="btn btn-xs btn-outline-danger" onClick={(e) => initModal(e)}>
            {titles}
            {verifs != null ?
            verifs == 2 ?
                <i className="fa fa-check-circle text-success ms-2"></i>:
                <i className="fa fa-check-circle text-secondary ms-2"></i>:null}
        </button>

        <Modal show={isModal} onClick={() => setIsModal(!isModal)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {titles}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {data.toLowerCase().includes('.png') == true ? 
                    <img src={datas} width='100%' />:null
                }
                {data.toLowerCase().includes('.jpeg') == true ? 
                    <img src={datas} width='100%' />:null
                }
                {data.toLowerCase().includes('.jpg') == true ? 
                    <img src={datas} width='100%' />:null
                }
                {data.toLowerCase().includes('.pdf') == true ? 
                    <div style={{ borderRadius: 10, overflow: 'hidden', display: 'flex', height: 'fit-content' }}>
                        <iframe height="650" width="100%" src={datas} ></iframe>
                    </div>:null
                }
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-xs btn-danger mt-2" onClick={() => setIsModal(!isModal)}>Tutup</button>
            </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalDialog