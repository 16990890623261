import React, {useEffect, useState} from "react";
import { FormatRupiah } from '@arismun/format-rupiah';
import BootstrapTable from 'react-bootstrap-table-next';

import {GetAllRekening} from '../../../services/KeuanganService';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {header, numTable} from '../../../components/CustomStyle';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

const RekeningPimpinanList = () => {
    let {SearchBar} = Search;

    let [page, setPage] = useState(1);
    let [data, setData] = useState([]);

    const getData = () => {
        GetAllRekening().then((response) => {
            setData(response.data.rows);

        }).catch(() => console.log('error get_rekening_unit'));
    }

    useEffect(() => {
        getData();
    }, [])

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    }, {
        text: 'Kode Lembaga',
        dataField: 'kode_lembaga',
        headerStyle: header
    }, {
        text: 'Nama Lembaga',
        dataField: 'nama',
        headerStyle: header
    }, {
        text: 'Kepala',
        dataField: 'kepala',
        headerStyle: header
    }, {
        text: 'Nomor Rekening',
        dataField: 'nomor_rekening',
        headerStyle: header
    }, {
        text: 'Saldo',
        dataField: 'saldo',
        headerStyle: header,
        formatter: (cell, row, rowIndex) => {
            return <FormatRupiah value={row.saldo} />
        }
    }];

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Rekening Pimpinan</h5>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <ToolkitProvider
                            keyField="id"
                            data={ data }
                            columns={ columns }
                            search>
                            {
                                props => (
                                    <div>
                                        <SearchBar { ...props.searchProps } />
                                        <br />
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            { ...props.baseProps }
                                            pagination={paginationFactory({
                                                onPageChange: (page, sizePerPage) => {
                                                    setPage(page);
                                            }})}
                                            bordered={ false }
                                            hover
                                            wrapperClasses="table-responsive"
                                            classes="table table-responsive"
                                            headerWrapperClasses="thead-light"
                                            rowClasses="text-sm font-weight-normal"
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RekeningPimpinanList