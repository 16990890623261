import {Cookies} from 'react-cookie';
import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom';

import {FormatRupiah} from '@arismun/format-rupiah';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {header, numTable, swalCustom, headerStickLeft, tdStickLeft, detailStyle} from '../../../components/CustomStyle';
import {DeleteAnggaran, GetAnggaranListV2, GetPeralihanAnggaranDetailAdmin, PutPeralihanAnggaranDetailAdmin} from "../../../services/KeuanganService";

const AnggaranPeralihanDetail = () => {
    const {id} = useParams();

    const navigate = useNavigate();
    let {SearchBar} = Search;
    
    let cookies = new Cookies();
    let [detail, setDetail] = useState([]);
    let [statusPeralihan, setStatusPeralihan] = useState('');

    const getData = async () => {
        await GetPeralihanAnggaranDetailAdmin(id).then((response) => {
            setDetail(response.data.rows);
            console.log(response.data.rows);
            
        }).catch(() => console.log('error GetPeralihanAnggaranListAdmin'))
    }

    useEffect(() => {
        getData();
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('status_peralihan', statusPeralihan);

        PutPeralihanAnggaranDetailAdmin(id, formData).then(response => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil "+statusPeralihan,
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                getData();
            });
        }).catch(err => swalCustom.fire(err.response.data.message, '', 'fail'));
    };

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Anggaran Peralihan Detail</h5>
                            </div>
                            <div className="ms-auto my-auto mt-lg-0 mt-4">
                                <div className="ms-auto my-auto">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12"><b>Asal Anggaran</b></div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2" style={detailStyle}>Nama Kegiatan</div>
                            <div className="col-lg-10">{detail.asal_nama_kegiatan}</div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2" style={detailStyle}>Jenis Kegiatan</div>
                            <div className="col-lg-10">{detail.asal_jenis_anggaran}</div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2" style={detailStyle}>Periode</div>
                            <div className="col-lg-10">{detail.periode}</div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-lg-12"><b>Tujuan Anggaran</b></div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2" style={detailStyle}>Nama Kegiatan</div>
                            <div className="col-lg-10">{detail.tujuan_nama_kegiatan}</div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2" style={detailStyle}>Jenis Kegiatan</div>
                            <div className="col-lg-10">{detail.tujuan_jenis_anggaran}</div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2" style={detailStyle}>Periode</div>
                            <div className="col-lg-10">{detail.periode}</div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-lg-2" style={detailStyle}>Status Peralihan</div>
                            <div className="col-lg-10">
                                {detail.status_peralihan == 'diajukan' ? <><span className="badge badge-sm badge-info">{detail.status_peralihan}</span><br/></> : ''}
                                {detail.status_peralihan == 'disetujui' ? <><span className="badge badge-sm badge-success">{detail.status_peralihan}</span><br/></> : ''}
                                {detail.status_peralihan == 'dibatalkan' ? <><span className="badge badge-sm badge-danger">{detail.status_peralihan}</span><br/></> : ''}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2" style={detailStyle}>Lembaga</div>
                            <div className="col-lg-10">{detail.kode_lembaga}</div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2" style={detailStyle}>Periode</div>
                            <div className="col-lg-10">{detail.periode}</div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2" style={detailStyle}>Nominal</div>
                            <div className="col-lg-10"><FormatRupiah value={detail.total} /></div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 mt-5">
                                {detail.status_peralihan == 'diajukan'?
                                <form onSubmit={handleSubmit}>
                                    <button type="submit" className="btn btn-sm btn-danger" onClick={() => setStatusPeralihan('dibatalkan')}>Batalkan</button>
                                    <button type="submit" className="ms-2 btn btn-sm btn-success" onClick={() => setStatusPeralihan('disetujui')}>Setujui</button>
                                </form>:null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnggaranPeralihanDetail