import Moment from 'moment';
import {Cookies} from 'react-cookie';
import React, {useEffect, useState} from "react";

import {Link, useNavigate} from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import {FormatRupiah} from '@arismun/format-rupiah';
import {header, numTable, swalCustom} from '../../../components/CustomStyle';

import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {DeleteTransaksiUnit, GetPeriodeList, GetRequestTrxUnitEdit, GetTransaksiUnitListV2} from "../../../services/KeuanganService";

const TrxUnitList = () => {
    const url = '/trx-unit/detail/'
    const url_edit = '/trx-unit/edit/'
    const url_upload_bukti = '/trx-unit-bukti/'

    let { SearchBar } = Search;
    let navigate = useNavigate();

    let cookies = new Cookies();
    let homebase = cookies.get('homebase_kode');
    let homebase_nama = cookies.get('homebase_nama');

    let [page, setPage] = useState(1);
    let [data, setData] = useState([]);
    let [periode, setPeriode] = useState(0);
    let [periodeList, setPeriodeList] = useState([]);

    const getData = async () => {
        await GetTransaksiUnitListV2(homebase, periode).then((response) => {
            setData(response.data.rows);

        }).catch(() => console.log('error GetTransaksiUnitListV2'));
    }

    const getPeriode = async () => {
        await GetPeriodeList().then((response) => {
            setPeriodeList(response.data.rows);
            setPeriode(response.data.rows[0]['id']);

        }).catch(() => console.log('error GetPeriodeList'));
    }

    useEffect(() => {
        getPeriode();
    }, [])

    useEffect(() => {
        getData();
    }, [homebase, periode])

    const opensweetalert = (id) => {
        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin menghapus ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                DeleteTransaksiUnit(homebase, id).then(response => {
                    swalCustom.fire('Berhasil dihapus!', '', 'success')
                    getData();
                })
            }
        })
    }

    const ajukaneditswal = (id) => {
        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin mengajukan edit transaksi ini ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                GetRequestTrxUnitEdit(id).then(response => {
                    swalCustom.fire('Berhasil diajukan!', '', 'success')
                    getData();
                })
            }
        })
    }

    const namaTruncate = (cell, row, rowIndex, formatExtraData) => {
        const name = row.anggaran.nama_kegiatan;
        const ket = row.keterangan;
        const tc = 25;
        return (
            <>
            <b>{name.length > tc ? name.substring(0, tc) + ' ...' : name}</b>
            <br/>
            {ket.length > tc ? ket.substring(0, tc) + ' ...' : ket}
            </>
        );
    }

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    }, {
        text: 'Anggaran',
        dataField: 'anggaran.nama_kegiatan',
        title: (cell, row, rowIndex) => {
            return row.anggaran.nama_kegiatan
        },
        formatter: namaTruncate,
        headerStyle: header
    },{
        text: 'Total Transaksi',
        dataField: 'total_transaksi',
        formatter: (cell, row, rowIndex) => {
            return <b><FormatRupiah value={row.total_transaksi} /></b>;
        },
        headerStyle: header
    },{
        text: 'Jenis',
        dataField: 'status_trx',
        title: (cell, row, rowIndex) => {
            if (row.status_trx == 0){
                return 'Keluar'
            }else{
                return 'Kedalam'
            }
        },
        formatter: (cell, row, rowIndex) => {
            return <>
                {row.status_trx == 1 ? 
                <i className="fas fa-arrow-circle-left text-info"></i> : 
                <i className="fas fa-arrow-circle-right text-success"></i>}
            </>
        },
        headerStyle: header
    },{
        text: 'Tanggal',
        dataField: 'created_at',
        formatter: (cell, row, rowIndex) => {
            return <>
                {Moment(row.tanggal).format('DD MMMM YYYY hh:mm')}<br/>
                {row.created_by}
            </>
        },
        headerStyle: header,
        headerFormatter: (cell, row, rowIndex) => {
            return (
                <>
                    Tanggal<br/>
                    Dibuat Oleh
                </>
            )
        }
    },{
        text: 'Status',
        dataField: 'is_checker',
        formatter: (cell, row, rowIndex) => {
            return <>
                {row.status_trx == 0 ? <>
                    {/* {row.is_checker === false ? 
                        <span className="badge badge-danger text-danger mb-1"> Checker </span> : 
                        <span className="badge badge-success text-success mb-1"> Checker </span>}
                    <br/> */}
                    {row.is_approve === false ? 
                        <span className="badge btn-xs badge-danger text-danger"> Approval </span> : 
                        <span className="badge btn-xs badge-success text-success"> Approval </span>}
                </>:null}
            </>
        },
        headerStyle: header
    },{
        text: 'Action',
        dataField: 'button',
        formatter: (cell, row, rowIndex) => {
            let namas_pajak = row.anggaran.nama_kegiatan.toLowerCase() !== 'pajak';
            let namas_admin = row.anggaran.nama_kegiatan.toLowerCase() !== 'biaya administrasi';

            return <>
                {row.selesai == 0 ? <>
                    <Link to={url_edit+row.id} onClick={(e) => e.stopPropagation()} className='btn btn-xs btn-warning me-1'>
                        <i className="fas fa-edit text-white"></i>
                    </Link>
                    <button onClick={(e) => {e.stopPropagation(); opensweetalert(row.id)}} className='btn btn-xs btn-danger'>
                        <i className="fas fa-trash text-white"></i>
                    </button></>:null}

                {namas_pajak && namas_admin && row.selesai == 1 && row.is_checker == 1 && row.is_approve == 1 && row.status_trx == 0 && row.bukti == null || 
                    namas_pajak && namas_admin && row.selesai == 1 && row.is_checker == 1 && row.is_approve == 1 && row.status_trx == 0 && row.bukti_tf == null ?
                    <Link to={url_upload_bukti+row.id} onClick={(e) => e.stopPropagation()} className='btn btn-xs btn-info'>
                        <i className="fas fa-upload text-white me-1"></i> Upload Bukti
                    </Link>:null}
                
                {row.is_approve == 1 && row.verif_bukti == 1 || row.is_approve == 1 && row.verif_bukti_tf == 1 ? 
                    <Link to={url_upload_bukti+row.id} onClick={(e) => e.stopPropagation()} className='btn btn-xs btn-info'>
                        <i className="fas fa-upload text-white me-1"></i> Revisi Bukti
                    </Link>:null}
                
                {row.is_edit == 1 ? 
                    <Link to={url_edit+row.id} onClick={(e) => e.stopPropagation()} className='btn btn-xs btn-warning me-1'>
                        <i className="fas fa-edit text-white"></i>
                    </Link>:null}
            </>
        },
        headerStyle: header
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            let path = url + row.id; 
            navigate(path);
        }
    }

    function change(event){
        setPeriode(event.target.value);
        setData([]);
    }

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Transaksi Unit</h5>
                                <span className="text-bold">{homebase} - {homebase_nama}</span>
                            </div>

                            <div className="ms-auto my-auto mt-lg-0 mt-4">
                                <div className="ms-auto my-auto">
                                    <Link to="/trx-unit/form" className="btn btn-xs btn-success">
                                        <i className="fas fa-plus-square fa-lg me-1"></i> Tambah
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        Periode
                        {periodeList.length !== 0 ?
                            <select className="form-select" onChange={change}>
                                {periodeList.map((periode) =>
                                    <option key={periode.id} value={periode.id}>{periode.tahun}</option>
                                )}
                            </select>:null}
                        <br/>
                        {data !== undefined ?
                        <ToolkitProvider
                            keyField="id"
                            data={ data }
                            columns={ columns }
                            search>
                            {
                                props => (
                                    <div>
                                        <SearchBar { ...props.searchProps } />
                                        <br />
                                        <BootstrapTable
                                            keyField="id"
                                            { ...props.baseProps }
                                            pagination={paginationFactory({
                                                onPageChange: (page, sizePerPage) => {
                                                    setPage(page);
                                            }})}
                                            bordered={ false }
                                            rowEvents={ rowEvents }
                                            hover
                                            wrapperClasses="table-responsive"
                                            classes="table table-responsive"
                                            rowClasses="text-sm font-weight-normal"
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>:null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TrxUnitList