import './App.css';

import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes, Outlet} from 'react-router-dom';

import Content from "./layouts/Content";
import Login from "./layouts/login/Login";
import Sidebar from "./layouts/Sidebar";
import Navbar from "./layouts/Navbar";

import KodeAnggaranList from "./layouts/keuangan/kode_anggaran/KodeAnggaranList";
import KodeAnggaranForm from "./layouts/keuangan/kode_anggaran/KodeAnggaranForm";
import KodeAnggaranDetail from "./layouts/keuangan/kode_anggaran/KodeAnggaranDetail";
import PeriodeList from "./layouts/admin/periode/PeriodeList";
import PeriodeForm from "./layouts/admin/periode/PeriodeForm";
import PeriodeDetail from "./layouts/admin/periode/PeriodeDetail";
import TrxMataAnggaranList from "./layouts/keuangan/trx_mata_anggaran/TrxMataAnggaranList";
import TrxMataAnggaranForm from "./layouts/keuangan/trx_mata_anggaran/TrxMataAnggaranForm";
import TrxMataAnggaranDetail from "./layouts/keuangan/trx_mata_anggaran/TrxMataAnggaranDetail";
import AnggaranList from "./layouts/unit/anggaran/AnggaranList";
import AnggaranForm from "./layouts/unit/anggaran/AnggaranForm";
import AnggaranDetail from "./layouts/unit/anggaran/AnggaranDetail";
import TrxUnitList from "./layouts/unit/trx_unit/TrxUnitList";
import TrxUnitForm from "./layouts/unit/trx_unit/TrxUnitForm";
import TrxUnitDetail from "./layouts/unit/trx_unit/TrxUnitDetail";
import TrxUnitEdit from "./layouts/unit/trx_unit/TrxUnitEdit";
import PeriodeEdit from './layouts/admin/periode/PeriodeEdit';
import KodeAnggaranEdit from './layouts/keuangan/kode_anggaran/KodeAnggaranEdit';
import AnggaranEdit from './layouts/unit/anggaran/AnggaranEdit';
import TrxMataAnggaranEdit from './layouts/keuangan/trx_mata_anggaran/TrxMataAnggaranEdit';
import FormRincian from './layouts/unit/trx_unit/FormRincian';
import UserGroupList from './layouts/admin/user_group/UserGroupList';
import UserGroupAdd from './layouts/admin/user_group/UserGroupAdd';
import UserGroupDelete from './layouts/admin/user_group/UserGroupDelete';
import RekeningList from './layouts/unit/rekening/RekeningList';
import RekeningForm from './layouts/unit/rekening/RekeningForm';
import DelegasiForm from './layouts/unit/rekening/DelegasiForm';
import RekeningKeuanganList from './layouts/keuangan/rekening/RekeningKeuanganList';
import TrxUnitListSelesai from './layouts/checker/TrxUnitListSelesai';
import TrxUnitListChecked from './layouts/approve/TrxUnitListChecked';
import TrxMataAnggaranSelesai from './layouts/checker/TrxMataAnggaranSelesai';
import TrxMataAnggaranChecked from './layouts/approve/TrxMataAnggaranChecked';
import AnggaranKeuanganList from './layouts/keuangan/anggaran/AnggaranKeuanganList';
import AnggaranKeuanganDetail from './layouts/keuangan/anggaran/AnggaranKeuanganDetail';
import TrxUnitCheckerDetail from './layouts/checker/TrxUnitCheckerDetail';
import TrxUnitApproveDetail from './layouts/approve/TrxUnitApproveDetail';
import TrxMataAnggaranCheckerDetail from './layouts/checker/TrxMataAnggaranCheckerDetail';
import TrxMataAnggaranApproveDetail from './layouts/approve/TrxMataAnggaranApproveDetail';
import TrxUnitUploadBukti from './layouts/unit/trx_unit/TrxUnitUploadBukti';
import AnggaranChart from './layouts/chart/AnggaranChart';
import AnggaranPimpinanList from "./layouts/pimpinan/anggaran/AnggaranPimpinanList";
import AnggaranPimpinanDetail from "./layouts/pimpinan/anggaran/AnggaranPimpinanDetail";
import RekeningPimpinanList from "./layouts/pimpinan/rekening/RekeningPimpinanList";
import AnggaranPimpinanForm from "./layouts/pimpinan/anggaran/AnggaranPimpinanForm";
import AnggaranPimpinanEdit from "./layouts/pimpinan/anggaran/AnggaranPimpinanEdit";
import LogRekeningUnit from "./layouts/unit/rekening/LogRekeningUnit";
import AnggaranChartKeuangan from './layouts/chart/AnggaranChartKeuangan';
import RekeningKeuanganForm from './layouts/keuangan/rekening/RekeningKeuanganForm';
import TrxMataAnggaranUploadBukti from './layouts/keuangan/trx_mata_anggaran/TrxMataAnggaranUploadBukti';
import TrxUnitVerifikasiList from './layouts/keuangan/verifikasi/TrxUnitVerifikasiList';
import TrxUnitVerifikasiDetail from './layouts/keuangan/verifikasi/TrxUnitVerifikasiDetail';
import RabList from './layouts/keuangan/rab/RabList';
import RabForm from './layouts/keuangan/rab/RabForm';
import RabEdit from './layouts/keuangan/rab/RabEdit';
import RabDetail from './layouts/keuangan/rab/RabDetail';
import { ProtectedRoutes } from './components/Protected';
import TrxUnitAnggaranReditList from './layouts/keuangan/redit/TrxUnitAnggaranReditList';
import InventarisList from './layouts/keuangan/aset/Inventaris';
import DepresiasiList from './layouts/keuangan/aset/Depresiasi';
import InventarisDetail from './layouts/keuangan/aset/InventarisDetail';
import TrxUnitNotaList from './layouts/keuangan/nota_pengadaan/TrxUnitNotaList';
import TrxUnitNotaDetail from './layouts/keuangan/nota_pengadaan/TrxUnitNotaDetail';
import TrxUnitNotaUploadBukti from './layouts/keuangan/nota_pengadaan/TrxUnitNotaUploadBukti';
import AnggaranPeralihanList from './layouts/keuangan/anggaran_peralihan/AnggaranPeralihanList';
import AnggaranPeralihanDetail from './layouts/keuangan/anggaran_peralihan/AnggaranPeralihanDetail';

function App() {
  const useScript = (src) => {
    useEffect(() => {
        const tag = document.createElement('script');
        tag.async = true;
        tag.src = src;
        document.body.appendChild(tag);

        return () => {
            document.body.removeChild(tag);
        }
    }, [src])
  }

  useScript('/assets/js/core/popper.min.js');
  useScript('/assets/js/core/bootstrap.min.js');
  useScript('/assets/js/plugins/perfect-scrollbar.min.js');
  useScript('/assets/js/plugins/smooth-scrollbar.min.js');
  // useScript('/assets/js/argon-dashboard.min.js');
  useScript('/assets/js/htmx.min.js');

  const [bege, setBege] = useState(false);
  const [classBd, setClassBd] = useState(0);
  const bg_show = 'g-sidenav-show bg-gray-100';
  const bg_pinned = 'g-sidenav-pinned bg-gray-100';

  useEffect(() => {
    if (bege == false){
      setClassBd(0)
    }else{
      setClassBd(1)
    }
  }, [bege])


  const LayoutDashboard = () => (
      <>
        <div className={classBd == 0 ? bg_show : bg_pinned}>
          <Sidebar setBege={setBege}/>
          <main className="main-content position-relative border-radius-lg ">
            <Navbar setBege={setBege}/>
            <div className="container-fluid py-4">
              <Outlet />
            </div>
          </main>
        </div>
      </>
  );


  return (
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route element={<LayoutDashboard />}>
            <Route element={<ProtectedRoutes/>}>
                <Route exact path="/" element={ <Content/> } />

                # Unit
                <Route path="/rekening" element={ <RekeningList/> } />
                <Route path="/rekening/edit" element={ <RekeningForm /> } />
                <Route path="/rekening/delegator" element={ <DelegasiForm /> } />

                <Route path="/anggaran" element={ <AnggaranList/> } />
                <Route path="/anggaran/form" element={ <AnggaranForm/> } />
                <Route path="/anggaran/detail/:id/:periode" element={ <AnggaranDetail/> } />
                <Route path="/anggaran/edit/:id" element={ <AnggaranEdit/> } />

                <Route path="/trx-unit" element={ <TrxUnitList/> } />
                <Route path="/trx-unit/form" element={ <TrxUnitForm/> } />
                <Route path="/trx-unit/detail/:id" element={ <TrxUnitDetail/> } />
                <Route path="/trx-unit/edit/:id" element={ <TrxUnitEdit/> } />
                <Route path="/trx-unit/rincian-form/:id" element={ <FormRincian /> } />
                <Route path="/trx-unit-bukti/:id" element={ <TrxUnitUploadBukti /> } />

                <Route path="/log-rekening-unit" element={ <LogRekeningUnit/> } />

                # Nota Pengadaan
                <Route path="/trx-unit-pengadaan" element={ <TrxUnitNotaList/> } />
                <Route path="/trx-unit-pengadaan/detail/:id" element={ <TrxUnitNotaDetail/> } />
                <Route path="/trx-unit-pengadaan/bukti/:id" element={ <TrxUnitNotaUploadBukti/> } />
                

                # Pimpinan
                <Route path="/rekening-all-pimpinan" element={ <RekeningPimpinanList/> } />

                <Route path="/anggaran-pimpinan" element={ <AnggaranPimpinanList/> } />
                <Route path="/anggaran-pimpinan/form" element={ <AnggaranPimpinanForm/> } />
                <Route path="/anggaran-pimpinan/detail/:id" element={ <AnggaranPimpinanDetail/> } />
                <Route path="/anggaran-pimpinan/edit/:id" element={ <AnggaranPimpinanEdit/> } />

                # Keuangan
                <Route path="/trx-mata-anggaran" element={ <TrxMataAnggaranList/> } />
                <Route path="/trx-mata-anggaran/form" element={ <TrxMataAnggaranForm/> } />
                <Route path="/trx-mata-anggaran/detail/:id" element={ <TrxMataAnggaranDetail/> } />
                <Route path="/trx-mata-anggaran/edit/:id" element={ <TrxMataAnggaranEdit/> } />
                <Route path="/trx-mata-anggaran-bukti/:id" element={ <TrxMataAnggaranUploadBukti/> } />

                <Route path="/trx-unit-verifikasi/list" element={ <TrxUnitVerifikasiList/> } />
                <Route path="/trx-unit-verifikasi-detail/:id" element={ <TrxUnitVerifikasiDetail/> } />

                <Route path="/rab" element={ <RabList/> } />
                <Route path="/rab/form" element={ <RabForm/> } />
                <Route path="/rab/detail/:id" element={ <RabDetail/> } />
                <Route path="/rab/edit/:id" element={ <RabEdit/> } />

                <Route path="/trx-permohonan-ubah" element={ <TrxUnitAnggaranReditList/> } />

                <Route path="/aset-inventaris" element={<InventarisList/>}/>
                <Route path="/aset-depresiasi" element={<DepresiasiList/>}/>
                <Route path="/aset-detail/:id" element={<InventarisDetail/>}/>

                <Route path="/anggaran-peralihan" element={<AnggaranPeralihanList/>}/>
                <Route path="/anggaran-peralihan/:id" element={<AnggaranPeralihanDetail/>}/>

                # Checker Approve
                <Route path="/trx-unit-selesai" element={ <TrxUnitListSelesai/> } />
                <Route path="/trx-unit-detail-selesai/:id" element={ <TrxUnitCheckerDetail/> } />
                <Route path="/trx-unit-checked" element={ <TrxUnitListChecked/> } />
                <Route path="/trx-unit-detail-checked/:id" element={ <TrxUnitApproveDetail/> } />

                <Route path="/mata-anggaran-selesai" element={ <TrxMataAnggaranSelesai/> } />
                <Route path="/mata-anggaran-detail-selesai/:id" element={ <TrxMataAnggaranCheckerDetail/> } />
                <Route path="/mata-anggaran-checked" element={ <TrxMataAnggaranChecked/> } />
                <Route path="/mata-anggaran-detail-checked/:id" element={ <TrxMataAnggaranApproveDetail/> } />


                # Operator
                <Route path="/user-group" element={ <UserGroupList /> } />
                <Route path="/user-group/add" element={ <UserGroupAdd /> } />
                <Route path="/user-group/delete" element={ <UserGroupDelete /> } />

                <Route path="/mata-anggaran" element={ <KodeAnggaranList/> } />
                <Route path="/mata-anggaran/form" element={ <KodeAnggaranForm/> } />
                <Route path="/mata-anggaran/detail/:id" element={ <KodeAnggaranDetail/> } />
                <Route path="/mata-anggaran/edit/:id" element={ <KodeAnggaranEdit/> } />

                <Route path="/periode" element={ <PeriodeList/> } />
                <Route path="/periode/form" element={ <PeriodeForm/> } />
                <Route path="/periode/detail/:id" element={ <PeriodeDetail/> } />
                <Route path="/periode/edit/:id" element={ <PeriodeEdit/> } />

                <Route path="/rekening-all" element={ <RekeningKeuanganList/> } />
                <Route path="/rekening/form" element={ <RekeningKeuanganForm/> } />

                <Route path="/anggaran-all" element={ <AnggaranKeuanganList /> } />
                <Route path="/anggaran-detail/:kode_lembaga/:id" element={ <AnggaranKeuanganDetail /> } />

                # Chart
                <Route path="/pie-chart" element={ <AnggaranChart /> } />
                <Route path="/keuangan-chart" element={ <AnggaranChartKeuangan /> } />

            </Route>
          </Route>
        </Routes>
      </Router>
  )
}

export default App;