import {Cookies} from 'react-cookie';
import {useEffect, useState} from "react";

import {useParams, Link} from "react-router-dom";
import {FormatRupiah} from "@arismun/format-rupiah";
import {GetAnggaranDetail} from "../../../services/KeuanganService";

const AnggaranPimpinanDetail = () => {
    let {id} = useParams();

    let cookies = new Cookies();
    let homebase = cookies.get('homebase_kode');

    let [detail, setDetail] = useState({});

    const getDetail = () => {
        GetAnggaranDetail(homebase, id).then((response) => {
            setDetail(response.data);

        }).catch(() => console.log('error get_periode_all'));
    }

    useEffect(() => {
        getDetail();
    }, [id])

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Anggaran Detail</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            Periode <br />
                                            {detail.periode != null ? detail.periode.tahun : ''}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            Jenis Anggaran <br />
                                            {detail.jenis_anggaran}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            Nama Kegiatan <br />
                                            {detail.nama_kegiatan}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            Total Anggaran <br />
                                            <FormatRupiah value={detail.total_anggaran} />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <Link to="/anggaran-pimpinan" className="btn btn-sm btn-secondary">
                                    <i className="fas fa-chevron-circle-left me-2"></i>Kembali
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnggaranPimpinanDetail