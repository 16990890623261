import { Cookies } from "react-cookie";

const cookies = new Cookies();

class TokenService {
    getAccessToken(){
        const token = cookies.get('token');
        return token;
    }

    getBearerAccessToken(){
        const cookies = new Cookies();
        return `Bearer ${cookies.get('token')}`;
    }

    getRefreshToken(){
        const refresh = cookies.get('refresh');
        return refresh;
    }

    updateAccessToken(token){
        cookies.set('token', token);
    }
}

export default new TokenService();