import {Outlet, Navigate} from 'react-router-dom';
import Moment from "moment";
import TokenService from '../services/TokenService';

export const ProtectedRoutes = () => {
    let now = new Date();
    let idLocale = require('moment/locale/id');
    Moment.locale('id', idLocale);

    let token = TokenService.getAccessToken();
    if (token){
        let payloadToken = JSON.parse(atob(token.split(".")[1]));
        if (now.getTime().toString().slice(0, -3) > payloadToken.exp){
            console.log('token expired');

            let refresh = TokenService.getRefreshToken();
            let payloadRefresh = JSON.parse(atob(refresh.split(".")[1]));

            if (parseInt(now.getTime().toString().slice(0, -3)) < payloadRefresh.exp){
                console.log('refresh belum expired');
                return <Outlet />;
            }else{
                console.log('refresh expired');
                return <Navigate replace to="/login" />;
            }
        }else{
            console.log('token belum expired')
            return <Outlet />;
        }
    }else{
        return <Navigate replace to="/login" />;
    }
};