import {Link, useParams} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import { FormatRupiah } from '@arismun/format-rupiah';
import { GetDetailAnggaranByLembaga } from '../../../services/KeuanganService';
import { detailStyle, header } from '../../../components/CustomStyle';

import Moment from "moment/moment";

const AnggaranKeuanganDetail = () => {
    const {kode_lembaga} = useParams();
    const {id} = useParams();

    let [detail, setDetail] = useState({});
    let [pengeluaran, setPengeluaran] = useState([]);

    const getData = async () => {
        await GetDetailAnggaranByLembaga(kode_lembaga, id).then((response) => {
            setDetail(response.data);
            setPengeluaran(response.data.detail_pengeluaran);

        }).catch(() => console.log('error get_anggaran_by_lbg'));
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Detail Anggaran Lembaga</h5>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="row mb-1">
                                    <div className="col-md-2" style={detailStyle}>
                                        Kode Lembaga
                                    </div>
                                    <div className="col-md-10">
                                        {detail.kode_lembaga}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-md-2" style={detailStyle}>
                                        Jenis Anggaran
                                    </div>
                                    <div className="col-md-2">
                                        {detail.jenis_anggaran}<br/>
                                        {detail.bukti_pengajuan != null ?
                                            <Link target='_blank' className="btn btn-xs btn-danger" to={detail.bukti_pengajuan}>
                                                Bukti Pengajuan
                                            </Link>:'-'}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-md-2" style={detailStyle}>
                                        Nama Kegiatan
                                    </div>
                                    <div className="col-md-10">
                                        {detail.nama_kegiatan}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-md-2" style={detailStyle}>
                                        Total Anggaran
                                    </div>
                                    <div className="col-md-10">
                                        <b><FormatRupiah value={detail.total_anggaran} /></b>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-md-2" style={detailStyle}>
                                        Terpakai
                                    </div>
                                    <div className="col-md-10">
                                        <span className="text-danger"><FormatRupiah value={detail.total_pengeluaran} /></span>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-md-2" style={detailStyle}>
                                        Tersisa
                                    </div>
                                    <div className="col-md-10">
                                        <span className="text-success"><b><FormatRupiah value={detail.sisa_dana} /></b></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-6 mb-3">
                            <div className="col-lg-12 mb-3">
                                <h5>Penggunaan Anggaran</h5>
                            </div>
                            <div className="col-lg12">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th style={header}>No.</th>
                                                <th style={header}>Tujuan</th>
                                                <th style={header}>Keterangan</th>
                                                <th style={header}>Nominal</th>
                                                <th style={header}>Tanggal</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pengeluaran.map((peng, index)=> {
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-sm font-weight-normal">{index+1}</td>
                                                        <td className="text-sm font-weight-normal" style={{whiteSpace: "normal"}}>
                                                            {peng.status_trx == 0 ? <>
                                                                <span className="badge badge-sm badge-success">Keluar</span>
                                                            </> : <>
                                                                <span className="badge badge-sm badge-primary">Masuk</span>
                                                            </>}

                                                            {peng.is_payroll == 1 ? <>
                                                                <span className="badge badge-sm badge-info ms-1">Payroll</span>
                                                            </> : ''}
                                                
                                                            <br/>
                                                            <b>{peng.rekening_tujuan ? peng.rekening_tujuan : '-'}</b><br/>
                                                            <i>{peng.nama_rekening_tujuan ? peng.nama_rekening_tujuan : '-'}</i>
                                                        </td>
                                                        <td className="text-sm font-weight-normal" style={{whiteSpace: "normal"}}>
                                                            {peng.keterangan ? peng.keterangan : '-'}
                                                        </td>
                                                        <td className="text-sm font-weight-normal">
                                                            <b><FormatRupiah value={peng.nominal} /></b>
                                                        </td>
                                                        <td className="text-sm font-weight-normal">
                                                            {Moment(peng.tanggal).format('DD MMMM YYYY hh:mm')}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Link to="/anggaran-all" className="btn btn-sm btn-secondary">
                            <i className="fas fa-chevron-circle-left me-2"></i>Kembali
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnggaranKeuanganDetail