import {GetPeriodeList, GetPic, PostRab} from "../../../services/KeuanganService";
import {useNavigate} from "react-router-dom";
import { Cookies } from "react-cookie";
import { swalCustom } from "../../../components/CustomStyle";
import CurrencyInput from "react-currency-input-field";
import { useEffect, useState } from "react";
import Select from 'react-select'

const RabForm = () => {
    const navigate = useNavigate();
    let optPic = [];
    let [periodeList, setPeriodeList] = useState([]);
    let [picList, setPicList] = useState([]);

    const getPic = () => {
        GetPic().then((response) => {
            let resp = response.data.rows;
            for (let i = 0; i < resp.length; i++){
                let opt = {
                    value: resp[i].user[0].username,
                    label: resp[i].nama_bergelar,
                }
                optPic.push(opt);
            }
            setPicList(optPic);

        }).catch(() => console.log('error get_pic'));
    }

    const getPeriode = () => {
        GetPeriodeList().then((response) => {
            setPeriodeList(response.data.rows);

        }).catch(() => console.log('error get_periode_all'));
    }

    useEffect(() => {
        getPeriode();
        getPic();
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const cookies = new Cookies();

        let formData = new FormData();
        formData.append('nama', event.target.nama.value);
        // formData.append('nominal', event.target.nominal.value.replace(/[^0-9]/g, ''));
        formData.append('nominal', event.target.nominal.value.replace(/[^\d.-]/g, ''));
        // formData.append('tahun', event.target.tahun.value);
        formData.append('pic', event.target.pic.value);
        formData.append('created_by', cookies.get('username'));

        PostRab(formData).then(response => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil ditambah",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true){
                    navigate('/rab');
                }
            });
        }).catch(response => {
            console.log(response);
            swalCustom.fire({
                title: "Gagal",
                text: "Data gagal ditambah",
                type: "error",
            });
        });
    };

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">RAB Form</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                Nama
                                                <input className="form-control" type="text" name="nama" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Nominal
                                                {/* <input className="form-control" type="text" name="nominal" /> */}
                                                <CurrencyInput name="nominal" className="form-control" prefix="Rp " decimalsLimit={2} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                {/* Tahun
                                                <input className="form-control" type="text" name="tahun" /> */}

                                                {/* Tahun
                                                {periodeList.length !== 0 ?
                                                    <select className="form-select" name="tahun">
                                                        {periodeList.map((periode) =>
                                                            <option key={periode.id} value={periode.tahun}>{periode.tahun}</option>
                                                        )}
                                                    </select>:null} */}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                PIC
                                                {/* <input className="form-control" type="text" name="pic" /> */}
                                                
                                                <Select name="pic" options={picList} />
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <button className="btn btn-xs btn-success" type="submit">
                                        <i className="fas fa-save me-2"></i>Simpan
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RabForm