import { Cookies } from "react-cookie";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FormatRupiah } from "@arismun/format-rupiah";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { header, numTable, swalCustom } from '../../components/CustomStyle';

import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { GetAnggaranList, GetAnggaranListV2, GetPeriodeList, GetRekeningList } from "../../services/KeuanganService";

const AnggaranChart = () => {
    const warnas = ["#5e72e4", "#fb6340"];
    const {SearchBar} = Search;
    const {state} = useLocation();

    const cookies = new Cookies();
    const homebase = cookies.get('homebase_kode');
    const homebase_nama = cookies.get('homebase_nama');

    let [page, setPage] = useState(1);
    let [data, setData] = useState([]);
    let [saldo, setSaldo] = useState(0);
    let [periode, setPeriode] = useState('');
    let [periodeList, setPeriodeList] = useState([]);

    const getData = () => {
        if (homebase !== undefined){
            GetAnggaranListV2(homebase, periode).then((response) => {
                setData(response.data.rows);
                console.log(response.data)
            }).catch(() => console.log('error get_periode_all'));
        }
    }

    const getPeriode = () => {
        GetPeriodeList().then((response) => {
            setPeriodeList(response.data.rows);
            setPeriode(response.data.rows[0]['id']);

        }).catch(() => console.log('error get_periode_all'));
    }

    const getRekening = () => {
        GetRekeningList(homebase).then((response) => {
            setSaldo(response.data.saldo);

        }).catch(() => console.log('error get_rekening_unit'));
    }

    useEffect(() => {
        getPeriode();
        getRekening();
    }, [homebase])

    useEffect(() => {
        getData();
    }, [homebase, periode])

    const customTooltip = ({ active, payload, label }) => {
       if (active) {
          return (
          <div className="custom-tooltip" style={{backgroundColor: "#ffff", padding: "5px", border: "1px solid #cccc"}}>
                {payload[0].name} - <FormatRupiah value={payload[0].value} />
          </div>
          );
        }
    }

    function change(event){
        setPeriode(event.target.value);
        setData([]);
    }

    const namaTruncate = (cell, row, rowIndex, formatExtraData) => {
        const tc = 25;
        return (
            <>
            {row.nama_kegiatan.length > tc ? row.nama_kegiatan.substring(0, tc) + ' ...' : row.nama_kegiatan}
            </>
        );
    }

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    }, {
        text: 'Nama',
        dataField: 'nama_kegiatan',
        title: (cell, row, rowIndex) => {
            return row.nama_kegiatan
        },
        formatter: namaTruncate,
        headerStyle: header
    }, {
        text: 'Total Anggaran',
        dataField: 'total_anggaran',
        formatter: (cell, row, rowIndex) => {
            return <b><FormatRupiah value={row.total_anggaran} /></b>;
        },
        headerStyle: header
    }, {
        text: 'Total Pengeluaran',
        dataField: 'total_pengeluaran',
        formatter: (cell, row, rowIndex) => {
            return <span style={{color:'red'}}><FormatRupiah value={row.total_pengeluaran} /></span>
        },
        headerStyle: header
    }, {
        text: 'Sisa Dana',
        dataField: 'sisa_dana',
        formatter: (cell, row, rowIndex) => {
            return <span className='text-success text-bold'><FormatRupiah value={row.sisa_dana} /></span>
        },
        headerStyle: header
    }];

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Grafik Anggaran Unit</h5>
                                <span className="text-bold">{homebase} - {homebase_nama}</span><br/>
                                Saldo - <b><FormatRupiah value={saldo} /></b>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        Periode
                        {periodeList.length !== 0 ?
                            <select className="form-select" onChange={change}>
                                {periodeList.map((periode) =>
                                    <option key={periode.id} value={periode.id}>{periode.tahun}</option>
                                )}
                            </select>:null}
                        <br/>
                        <div className="row">
                            {data.length > 0 ?
                            <ToolkitProvider
                                keyField="id"
                                data={ data }
                                columns={ columns }
                                search>
                                {
                                    props => (
                                        <div>
                                            <SearchBar { ...props.searchProps } />
                                            <br />
                                            <BootstrapTable
                                                keyField="id"
                                                { ...props.baseProps }
                                                pagination={paginationFactory({
                                                    onPageChange: (page, sizePerPage) => {
                                                        setPage(page);
                                                }})}
                                                bordered={ false }
                                                hover
                                                wrapperClasses="table-responsive"
                                                classes="table table-responsive"
                                                rowClasses="text-sm font-weight-normal"
                                            />
                                        </div>
                                    )
                                }
                            </ToolkitProvider>:null}

                            {/* {data.length == 0 ? 
                                data.map((val, key) => {
                                    
                                    let pie = [
                                        {
                                            'keterangan': 'Tersisa',
                                            'nominal': val.sisa_dana
                                        },{
                                            'keterangan': 'Terpakai',
                                            'nominal': val.total_pengeluaran
                                        }
                                    ];

                                    return <>
                                        <div key={key} className="col-lg-4 col-md-6 mb-4 d-flex align-items-stretch">
                                            <div className="card">
                                                <div className="card-body p-3" style={{margin: 0}}>
                                                    <div className="d-flex">
                                                        <div className="ms-3 my-auto" title={val.nama_kegiatan}>
                                                            <h6>{val.nama_kegiatan}</h6>
                                                            <h6 style={{color: 'grey'}}><FormatRupiah value={val.total_anggaran} /></h6>
                                                        </div>
                                                    </div>

                                                    <ResponsiveContainer width='110%' height={450}>
                                                        <PieChart width={320} height={450}>
                                                            <Pie
                                                                data={pie}
                                                                color="#000000"
                                                                dataKey="nominal"
                                                                nameKey="keterangan"
                                                                cx="50%"
                                                                cy="50%"
                                                                outerRadius={120}
                                                                fill="#8884d8"
                                                                >
                                                                {pie.map((entry, index) => (
                                                                    <Cell
                                                                        key={`cell-${index}`}
                                                                        fill={warnas[index % warnas.length]}
                                                                    />
                                                                ))}
                                                            </Pie>
                                                            <Tooltip content={customTooltip} />
                                                            <Legend
                                                                payload={
                                                                    pie.map((item, index) => ({
                                                                        id: item.name,
                                                                        type: "round",
                                                                        value: `${item.keterangan}`,
                                                                        color: warnas[index % warnas.length]
                                                                    }))
                                                                }
                                                            />
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                })
                            :null} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnggaranChart