import {Cookies} from "react-cookie";
import {useState, useEffect} from "react";
import {useNavigate, Link} from "react-router-dom";

import CurrencyInput from "react-currency-input-field";
import {swalCustom} from "../../../components/CustomStyle";
import {GetRekeningListV2, UpdateRekeningListV2} from "../../../services/KeuanganService";

const RekeningForm = () => {
    const navigate = useNavigate();

    let cookies = new Cookies();
    let homebase_lbg = cookies.get('homebase_kode');
    let homebase_rek = cookies.get('homebase_rekening');

    let [total, setTotal] = useState();
    let [detail, setDetail] = useState({});

    const getDetail = async () => {
        await GetRekeningListV2(homebase_lbg, homebase_rek).then((response) => {
            setDetail(response.data);
            setTotal(response.data.saldo);

        }).catch(() => console.log('error GetRekeningListV2'));
    }

    useEffect(() => {
        getDetail();
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('kode_lembaga', event.target.kode_lembaga.value);
        formData.append('nama', event.target.nama.value);
        formData.append('nomor_rekening', event.target.nomor_rekening.value);
        formData.append('saldo', event.target.saldo.value.replace(/[^\d.-]/g, ''));
        formData.append('kepala', event.target.kepala.value);

        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin mengubah data rekening ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                UpdateRekeningListV2(homebase_lbg, homebase_rek, formData).then(response => {
                    swalCustom.fire({
                        title: "Sukses",
                        text: "Data berhasil diubah",
                        type: "success",
                        confirmButtonText: "OK",
                    }).then((result) => {
                        if (result.value === true){
                            navigate('/rekening');
                        }
                    });
                }).catch(response => {
                    swalCustom.fire({
                        title: "Gagal",
                        text: "Data gagal ditambah",
                        type: "error",
                    });
                });
            }
        })
    };

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Rekening Lembaga Edit</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                Kode Lembaga
                                                <input className="form-control" type="text" value={detail.kode_lembaga}
                                                       onChange={x => setDetail({...detail, kode_lembaga: x.target.value})} name="kode_lembaga" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Nama
                                                <input className="form-control" type="text" value={detail.nama}
                                                       onChange={x => setDetail({...detail, nama: x.target.value})} name="nama" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Nomor Rekening
                                                <input className="form-control" type="text" name="nomor_rekening" value={detail.nomor_rekening}
                                                       onChange={x => setDetail({...detail, nomor_rekening: x.target.value})} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Saldo
                                                {total != null ? 
                                                    <CurrencyInput 
                                                        name="saldo" 
                                                        className="form-control" 
                                                        prefix="Rp " 
                                                        defaultValue={total}
                                                        onChange={x => setDetail({...detail, saldo: x.target.value})}
                                                        decimalsLimit={2} />:null}
                                                <span className="text-xs">*untuk koma gunakan . (titik)</span>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Kepala
                                                <input className="form-control" type="text" name="kepala" value={detail.kepala}
                                                       onChange={x => setDetail({...detail, kepala: x.target.value})} />
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <Link to="/rekening" className="btn btn-xs btn-secondary me-1">
                                        <i className="fas fa-chevron-circle-left me-2"></i>Kembali
                                    </Link>
                                    <button className="btn btn-xs btn-success" type="submit">
                                        <i className="fas fa-save me-2"></i>Simpan
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RekeningForm