import {useEffect, useState} from "react";
import {FormatRupiah} from "@arismun/format-rupiah";
import BootstrapTable from 'react-bootstrap-table-next';
import {PieChart, Pie, Cell, Tooltip, Legend} from "recharts";
import {Link, useNavigate} from 'react-router-dom';

import paginationFactory from 'react-bootstrap-table2-paginator';
import { header, numTable, swalCustom } from '../../components/CustomStyle';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {GetAnggaranList, GetAnggaranListV2, GetListLembagaAnggaran, GetPeriodeList, GetRabListPeriode, GetRekeningList} from "../../services/KeuanganService";

const AnggaranChartKeuangan = () => {
    const warnas = ["#5e72e4", "#fb6340"];
    const {SearchBar} = Search;
    const navigate = useNavigate();

    let [rab, setRab] = useState([]);
    let [page, setPage] = useState(1);
    let [data, setData] = useState([]);
    let [pilih, setPilih] = useState();

    let [saldo, setSaldo] = useState(0);
    let [lembaga, setLembaga] = useState();
    let [namaLbg, setNamaLbg] = useState(0);
    let [periode, setPeriode] = useState('');
    
    let [periodeId, setPeriodeId] = useState(0);
    let [dataLembaga, setDataLembaga] = useState([]);
    let [periodeList, setPeriodeList] = useState([]);

    const getData = () => {
        if (lembaga !== undefined){
            GetAnggaranListV2(lembaga, periodeId).then((response) => {
                setData(response.data.rows);
                console.log('ini anggaran periode: ', response.data.rows);

            }).catch(() => console.log('error get_periode_all'));
        }
    }

    const getLembaga = () => {
        GetListLembagaAnggaran(periodeId).then((response) => {
            setDataLembaga(response.data.rows);
            console.log('ini lbg: ', response.data.rows);
            if (lembaga == undefined){
                setLembaga(response.data.rows[0].kode_lembaga);
                setNamaLbg(response.data.rows[0].nama);
            }
            
        }).catch(() => console.log('error get_lembaga_anggaran_exist'));
    }

    const getRabPeriode = () => {
        GetRabListPeriode(periode).then((response) => {
            // setRab(response.data.rows);
            // setData([]);
            setData(response.data.rows);
            console.log('ini rab periode: ', response.data.rows);

        }).catch(() => console.log('error get_rab_periode_all'));
    }

    const getRekening = () => {
        GetRekeningList(lembaga).then((response) => {
            setSaldo(response.data.saldo);

        }).catch(() => console.log('error get_rekening_unit'));
    }

    const getPeriode = () => {
        GetPeriodeList().then((response) => {
            setPeriodeList(response.data.rows);
            setPeriode(response.data.rows[0]['tahun']);
            setPeriodeId(response.data.rows[0]['id']);
            setPilih('rab');

        }).catch(() => console.log('error get_periode_all'));
    }

    useEffect(() => {
        getPeriode();
    }, [])

    useEffect(() => {
        // getData();

        if (pilih == 'rab'){
            getRabPeriode();
        }else if (pilih == 'lbg'){
            getLembaga();
            getData();
            // getRekening();
        }

    }, [periode, periodeId, lembaga, pilih])

    const customTooltip = ({ active, payload, label }) => {
        if (active) {
           return (
           <div className="custom-tooltip" style={{backgroundColor: "#ffff", padding: "5px", border: "1px solid #cccc"}}>
                 {payload[0].name} - <FormatRupiah value={payload[0].value} />
           </div>
           );
         }
     }

    function change(event){
        let vals = event.target.value.split(',')
        console.log(vals);
        setLembaga(vals[0]);
        setNamaLbg(vals[1]);
        setData([]);
    }

    function changePeriode(event){
        console.log(event.target.value);
        let periodSplit = event.target.value.split(".");
        console.log(periodSplit[1]);
        if (pilih == 'rab'){
            setPeriode(periodSplit[1]);
        }else{
            setPeriodeId(periodSplit[0])
        }
        // setPeriode(event.target.value);
        // setPeriodeId(event.target.key);
        setData([]);
    }

    function changePilih(event){
        setPilih(event.target.value);
        setData([]);
    }

    const namaTruncate = (cell, row, rowIndex, formatExtraData) => {
        const tc = 25;
        let namas = '';

        if (pilih == 'rab'){
            namas = row.nama;
        }else{
            namas = row.nama_kegiatan;
        }

        return (
            <>
            {namas.length > tc ? namas.substring(0, tc) + ' ...' : namas}
            </>
        );
    }

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    }, {
        text: 'Nama',
        dataField: (pilih == 'rab' ? 'nama' : 'nama_kegiatan'),
        title: (cell, row, rowIndex) => {
            if (pilih == 'rab'){
                return row.nama
            }else{
                return row.nama_kegiatan
            }
        },
        formatter: namaTruncate,
        headerStyle: header
    }, {
        text: 'Total Anggaran',
        dataField: (pilih == 'rab' ? 'nominal' : 'total_anggaran'),
        formatter: (cell, row, rowIndex) => {
            if (pilih == 'rab'){
                return <b><FormatRupiah value={row.nominal} /></b>;
            }else{
                return <b><FormatRupiah value={row.total_anggaran} /></b>;
            }
        },
        headerStyle: header
    }, {
        text: 'Total Pengeluaran',
        dataField: 'total_pengeluaran',
        formatter: (cell, row, rowIndex) => {
            return <span style={{color:'red'}}><FormatRupiah value={row.total_pengeluaran} /></span>
        },
        headerStyle: header
    }, {
        text: 'Sisa Dana',
        dataField: 'sisa_dana',
        formatter: (cell, row, rowIndex) => {
            return <span className='text-success text-bold'><FormatRupiah value={row.sisa_dana} /></span>
        },
        headerStyle: header
    }];

    let [tdClick, setTdClick] = useState(0);
    useEffect(() => {
        if (tdClick == 1){
            navigate('/keuangan-chart');
        }else if (tdClick == 2){
            navigate('/anggaran-all');
        }

    }, [tdClick]);

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="table-responsive m-1">
                        <table className="table" id="nav-table">
                            <tbody>
                                <tr>
                                    <td id="nav-active" onClick={() => {setTdClick(1)}}>
                                        <b className="text-xs">Grafik</b>
                                    </td>
                                    <td id="nav-no-active" onClick={() => {setTdClick(2)}}>
                                        <b className="text-xs">Agr Unit</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Grafik</h5>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        Pilih Jenis
                        <select className="form-select" onChange={changePilih}>
                            <option value="rab">RAB</option>
                            <option value="lbg">Lembaga</option>
                        </select>
                        <br/>
                        Periode
                        {periodeList.length !== 0 ?
                            <select className="form-select" onChange={changePeriode}>
                                {periodeList.map((periode) =>
                                    <option key={periode.id} value={periode.id +'.'+ periode.tahun}>{periode.tahun}</option>
                                )}
                            </select>:null}
                        <br/>
                        {pilih == 'lbg' ?
                            dataLembaga.length !== 0 ? <>
                                Lembaga
                                <select className="form-select" onChange={change}>
                                    {dataLembaga.map((lbg) =>
                                        <option key={lbg.kode_lembaga} value={lbg.kode_lembaga+','+lbg.nama}>{lbg.kode_lembaga} - {lbg.nama}</option>
                                    )}
                                </select></>:null:null}
                            <div className="row mt-6">
                                {pilih != 'rab' ?
                                    <div className="mb-3">
                                        Saldo Unit - {namaLbg}<br/>
                                        <b><FormatRupiah value={saldo} /></b>
                                    </div>:null}

                                {data.length > 0 ?
                                <ToolkitProvider
                                    keyField="id"
                                    data={ data }
                                    columns={ columns }
                                    search>
                                    {
                                        props => (
                                            <div>
                                                <SearchBar { ...props.searchProps } />
                                                <br />
                                                <BootstrapTable
                                                    keyField="id"
                                                    { ...props.baseProps }
                                                    pagination={paginationFactory({
                                                        onPageChange: (page, sizePerPage) => {
                                                            setPage(page);
                                                    }})}
                                                    bordered={ false }
                                                    hover
                                                    wrapperClasses="table-responsive"
                                                    classes="table table-responsive"
                                                    rowClasses="text-sm font-weight-normal"
                                                />
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>:null}

                                {/* {data.length > 0 ? 
                                    data.map((val, key) => {
                                        
                                        let pie = [
                                            {
                                                'keterangan': 'Tersisa',
                                                'nominal': val.sisa_dana
                                            },{
                                                'keterangan': 'Terpakai',
                                                'nominal': val.total_pengeluaran
                                            }
                                        ];

                                        return <>
                                            <div key={key} className="col-lg-4 col-md-6 mb-4 d-flex align-items-stretch">
                                                <div className="card">
                                                    <div className="card-body p-3">
                                                        {pilih == 'rab' ?
                                                        <div className="d-flex">
                                                            <div className="ms-3 my-auto" title={val.nama}>
                                                                <h6>{val.nama}</h6>
                                                                <h6 style={{color: 'grey'}}><FormatRupiah value={val.nominal} /></h6>
                                                            </div>
                                                        </div>:
                                                        <div className="d-flex">
                                                            <div className="ms-3 my-auto" title={val.nama_kegiatan}>
                                                                <h6>{val.nama_kegiatan}</h6>
                                                                <h6 style={{color: 'grey'}}><FormatRupiah value={val.total_anggaran} /></h6>
                                                            </div>
                                                        </div>}

                                                        <PieChart className="text-center" width={320} height={450}>
                                                        <Pie
                                                            data={pie}
                                                            color="#000000"
                                                            dataKey="nominal"
                                                            nameKey="keterangan"
                                                            cx="50%"
                                                            cy="50%"
                                                            outerRadius={120}
                                                            fill="#8884d8"
                                                            >
                                                            {pie.map((entry, index) => (
                                                                <Cell
                                                                    key={`cell-${index}`}
                                                                    fill={warnas[index % warnas.length]}
                                                                />
                                                            ))}
                                                        </Pie>
                                                        <Tooltip content={customTooltip} />
                                                        <Legend
                                                            payload={
                                                                pie.map((item, index) => ({
                                                                    id: item.name,
                                                                    type: "round",
                                                                    value: `${item.keterangan}`,
                                                                    color: warnas[index % warnas.length]
                                                                }))
                                                            }
                                                        />
                                                        </PieChart>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    })
                                :null} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnggaranChartKeuangan