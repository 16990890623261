import {useNavigate, useParams} from "react-router-dom";
import {GetRekeningList, GetTransaksiUnitDetail, TolakCheckerTransaksiUnit, UpdateCheckerTransaksiUnit} from "../../services/KeuanganService";
import React, {useEffect, useState} from "react";
import Moment from "moment/moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {Link} from 'react-router-dom';
import { detailStyle, header, swalCustom } from '../../components/CustomStyle';
import { Cookies } from 'react-cookie';
import { FormatRupiah } from "@arismun/format-rupiah";
import { Modal } from "react-bootstrap";
import SingleChart from "../chart/SingleChart";
import ModalDialog from "../../components/Modal";

const TrxUnitCheckerDetail = () => {
    const {id} = useParams();
    let [detail, setDetail] = useState({});
    let [rek, setRek] = useState({});
    let [kl, setKl] = useState();
    let [detailTrx, setDetailTrx] = useState([]);
    const { SearchBar } = Search;
    let navigate = useNavigate();

    const cookies = new Cookies();
    const homebase = cookies.get('homebase_kode');

    const [isShow, invokeModal] = useState(false)
    const initModal = () => {
        return invokeModal(!isShow)
    }

    const getRek = () => {
        GetRekeningList(detail.kode_lembaga).then((response) => {
            setRek(response.data);
    
        }).catch(() => console.log('error get_rekening_unit'));
    }

    const getDetail = async (id) => {
        await GetTransaksiUnitDetail(homebase, id).then((response) => {
            setDetail(response.data);
            setDetailTrx(response.data.detail_transaksi);
            setKl(response.data.kode_lembaga);

        }).catch(() => console.log('error get_trx_unit_detail'));
    }

    useEffect(() => {
        getDetail(id);
    }, [id])

    useEffect(() => {
        getRek();
    }, [kl])

    const checksweetalert = (id) => {
        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin check ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                UpdateCheckerTransaksiUnit(id).then(response => {
                    swalCustom.fire('Berhasil dicheck!', '', 'success')
                    navigate('/trx-unit-selesai')

                }).catch(err => {
                    swalCustom.fire('Gagal dichecker!'.response.data.message, 'error')
                    navigate('/trx-unit-selesai')

                });
            }
        })
    }

    const handleTolak = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('note', event.target.note.value);

        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin menolak ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                TolakCheckerTransaksiUnit(event.target.id.value, formData).then(response => {
                    invokeModal(!isShow)
                    swalCustom.fire('Transaksi ditolak!', '', 'success')
                    navigate('/trx-unit-selesai')

                }).catch(err => {
                    invokeModal(!isShow)
                    swalCustom.fire('Gagal ditolak!'.response.data.message, 'error')
                    navigate('/trx-unit-selesai')

                });
            }else{
                invokeModal(!isShow)
            }
        })
    }

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return rowIndex + 1;
        },
        headerStyle: header
    }, {
        text: 'Rekening Tujuan',
        dataField: 'rekening_tujuan',
        headerStyle: header
    },{
        text: 'Keterangan',
        dataField: 'keterangan',
        headerStyle: header
    },{
        text: 'Nominal',
        dataField: 'nominal',
        headerStyle: header,
        formatter: (cell, row, rowIndex) => {
            return <FormatRupiah value={row.nominal} />
        },
        headerStyle: header
    }];

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Transaksi Unit Detail</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <Modal show={isShow}>
                                <Modal.Header>
                                    <Modal.Title>Tolak Transaksi Unit</Modal.Title>
                                </Modal.Header>
                                <form onSubmit={handleTolak}>
                                <Modal.Body>
                                    Kode Lembaga: {detail.kode_lembaga} <br/>
                                    Keterangan: {detail.keterangan} <br />
                                    Total Transaksi: <FormatRupiah value={detail.total_transaksi} />
                                    <br/>
                                    Catatan
                                    <textarea className='form-control' type='text' name='note' required />
                                    <input name='id' value={detail.id} hidden />
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type='button' className='btn btn-xs btn-primary' onClick={initModal}>Batal</button>
                                    <button type='submit' className='btn btn-xs btn-danger'>Tolak</button>
                                </Modal.Footer>
                                </form>
                            </Modal>

                            <div className="card-body">
                                {detail.length !== 0 ?
                                    <>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="row mb-1">
                                                    <div className="col-md-3" style={detailStyle}>
                                                        Tanggal
                                                    </div>
                                                    <div className="col-md-9">
                                                        {Moment(detail.tanggal).format('DD MMMM YYYY')}
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-3" style={detailStyle}>
                                                        Kode Lembaga
                                                    </div>
                                                    <div className="col-md-9">
                                                        {detail.kode_lembaga}
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-3" style={detailStyle}>
                                                        Anggaran
                                                    </div>
                                                    <div className="col-md-9">
                                                        {detail.anggaran && detail.anggaran.nama_kegiatan}
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-3" style={detailStyle}>
                                                        Keterangan
                                                    </div>
                                                    <div className="col-md-9">
                                                        {detail.keterangan}
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-3" style={detailStyle}>
                                                        Total Anggaran
                                                    </div>
                                                    <div className="col-md-9">
                                                        <b>{detail.anggaran && <FormatRupiah value={detail.anggaran.total_anggaran} />}</b>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-3" style={detailStyle}>
                                                        Terpakai
                                                    </div>
                                                    <div className="col-md-9">
                                                        <b>{detail.anggaran && <FormatRupiah value={detail.anggaran.total_pengeluaran} />}</b>
                                                    </div>
                                                </div>
                                                <div className="row mb-1">
                                                    <div className="col-md-3" style={detailStyle}>
                                                        Sisa Anggaran
                                                    </div>
                                                    <div className="col-md-9">
                                                        <b>{detail.anggaran && <FormatRupiah value={detail.anggaran.sisa_dana} />}</b>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="row mb-1">
                                                    <div className="col-md-12">
                                                        <SingleChart anggaran={detail.anggaran && detail.anggaran} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="row">
                                                    {detail.is_checker == false ? 
                                                        <span className="badge badge-danger text-danger mb-2"> Checker </span> : 
                                                        <span className="badge badge-success text-success mb-2"> Checker </span>}

                                                    {detail.is_approve == false ? 
                                                        <span className="badge btn-xs badge-danger text-danger"> Approval </span> : 
                                                        <span className="badge btn-xs badge-success text-success"> Approval </span>}

                                                    {/* {detail.bukti != null ?
                                                        <Link target='_blank' className="btn btn-xs btn-outline-danger mt-2" to={detail.bukti}>
                                                            Lihat Bukti
                                                        </Link>:'-'} */}

                                                    <span className="mt-2"></span>
                                                    <ModalDialog 
                                                        titles={'Bukti Nota'}
                                                        datas={detail.bukti}
                                                        verifs={detail.verif_bukti} />

                                                    <ModalDialog 
                                                        titles={'Bukti Transfer'} 
                                                        datas={detail.bukti_tf}
                                                        verifs={detail.verif_bukti_tf} />
                                                </div>
                                            </div>
                                        </div>

                                        <hr/>
                                        <div className="row mb-1">
                                            <div className="col-md-4">
                                                <span style={detailStyle}>Rekening Asal</span> <br/>
                                                {detail.nama_rekening_asal} <br/>
                                                <b>{detail.rekening_asal}</b><br/>
                                                <b><FormatRupiah value={detail.saldo_rekening_asal} /></b>
                                            </div>
                                            {detail.is_payroll == 0 ? <>
                                            <div className="col-md-2">
                                                <br/>
                                                <i className="fa fa-arrow-circle-right fa-xl text-success"></i>
                                            </div>
                                            <div className="col-md-4">
                                                <span style={detailStyle}>Rekening Tujuan</span> <br/>
                                                {detail.nama_rekening_tujuan} <br/>
                                                <b>{detail.rekening_tujuan}</b><br/>
                                                <b><FormatRupiah value={detail.saldo_rekening_tujuan} /></b>
                                            </div> </>:null}
                                            <div className="col-md-2">
                                                <span style={detailStyle}>Total Transaksi</span> <br/>
                                                <b><FormatRupiah value={detail.total_transaksi} /></b>
                                            </div>
                                        </div>
                                        <br/>
                                        {detail.is_payroll == 1 ? <>
                                            <div className="col-md-2 mb-3" style={detailStyle}>
                                                Detail Rekening Tujuan 
                                            </div>
                                            <ToolkitProvider
                                                keyField="id"
                                                data={ detailTrx }
                                                columns={ columns }
                                                search>
                                                {
                                                    props => (
                                                        <div>
                                                            <SearchBar { ...props.searchProps } />
                                                            <br />
                                                            <BootstrapTable
                                                                keyField="id"
                                                                { ...props.baseProps }
                                                                pagination={ paginationFactory() }
                                                                bordered={ false }
                                                                wrapperClasses="table-responsive"
                                                                classes="table table-responsive"
                                                                rowClasses="text-sm font-weight-normal"
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </ToolkitProvider></>:null}
                                        <br/>
                                        <Link to="/trx-unit-selesai" className="btn btn-sm btn-secondary me-2">
                                            <i className="fas fa-chevron-circle-left me-2"></i>Kembali
                                        </Link>
                                        {detail.is_checker == 0 ? <>
                                            <button onClick={(e) => {e.stopPropagation(); checksweetalert(id)}} className='btn btn-sm btn-info me-1'>
                                                <i className="fas fa-check fa-lg text-white me-2"></i> Checker
                                            </button>
                                            <button onClick={(e) => {e.stopPropagation(); initModal()}} className='btn btn-sm btn-danger'>
                                                <i className="fas fa-times fa-lg text-white me-1"></i> Tolak
                                            </button></>:null}
                                    </>:null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrxUnitCheckerDetail