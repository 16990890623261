import {FormatRupiah} from "@arismun/format-rupiah";
import {PieChart, Pie, Cell, Tooltip, Legend} from "recharts";

function SingleChart({anggaran}) {
    if (anggaran !== undefined){
        const warnas = ["#5e72e4", "#fb6340"];

        let pie = [
            {
                'keterangan': 'Tersisa',
                'nominal': anggaran.sisa_dana
            },{
                'keterangan': 'Terpakai',
                'nominal': anggaran.total_pengeluaran
            }
        ];

        const customTooltip = ({ active, payload, label }) => {
            if (active) {
            return (
            <div className="custom-tooltip" style={{backgroundColor: "#ffff", padding: "5px", border: "1px solid #cccc"}}>
                    {payload[0].name} - <FormatRupiah value={payload[0].value} />
            </div>
            );
            }
        }

        return (<>
            <p>Perbandingan Anggaran Chart</p>
            <PieChart width={200} height={330}>
                <Pie
                    data={pie}
                    color="#000000"
                    dataKey="nominal"
                    nameKey="keterangan"
                    cx="50%"
                    cy="50%"
                    outerRadius={99}
                    fill="#8884d8" >
                    {pie.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={warnas[index % warnas.length]} /> ))}
                </Pie>
                <Tooltip content={customTooltip} />
                <Legend
                    payload={
                        pie.map((item, index) => ({
                            id: item.name,
                            type: "round",
                            value: `${item.keterangan}`,
                            color: warnas[index % warnas.length] }))
                        } />
            </PieChart></>
        )
    }else{
        return null
    }
}

export default SingleChart