import {useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { FormatRupiah } from '@arismun/format-rupiah';
import { GetAnggaranByLembaga, GetAnggaranByLembagaV2, GetListLembagaAnggaran, GetPeriodeList } from '../../../services/KeuanganService';
import { header, numTable } from '../../../components/CustomStyle';

const AnggaranKeuanganList = () => {
    const url = '/anggaran-detail/'
    const { SearchBar } = Search;
    let navigate = useNavigate();
    let [data, setData] = useState([]);
    let [periodeList, setPeriodeList] = useState([]);
    let [periode, setPeriode] = useState(0);
    let [dataLembaga, setDataLembaga] = useState([]);
    let [lembaga, setLembaga] = useState();
    let [page, setPage] = useState(1);

    const getData = async () => {
        await GetAnggaranByLembagaV2(lembaga, periode).then((response) => {
            setData(response.data.rows);

        }).catch(() => console.log('error GetAnggaranByLembagaV2'));
    }

    const getLembaga = async () => {
        await GetListLembagaAnggaran(periode).then((response) => {
            setDataLembaga(response.data.rows);
            if (lembaga == undefined){
                setLembaga(response.data.rows[0].kode_lembaga);
            }
            
        }).catch(() => console.log('error GetListLembagaAnggaran'));
    }

    const getPeriode = async () => {
        await GetPeriodeList().then((response) => {
            setPeriodeList(response.data.rows);
            setPeriode(response.data.rows[0]['id']);

        }).catch(() => console.log('error get_periode_all'));
    }

    useEffect(() => {
        getPeriode();
    }, [])

    useEffect(() => {
        getLembaga();
    }, [periode])

    useEffect(() => {
        getData();
    }, [lembaga, periode])

    const namaTruncate = (cell, row, rowIndex, formatExtraData) => {
        const tc = 25;
        return (
            <>
            {row.nama_kegiatan.length > tc ? row.nama_kegiatan.substring(0, tc) + ' ...' : row.nama_kegiatan}
            </>
        );
    }

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    }, {
        text: 'Kegiatan',
        dataField: 'nama_kegiatan',
        title: (cell, row, rowIndex) => {
            return row.nama_kegiatan
        },
        formatter: namaTruncate,
        headerStyle: header
    },{
        text: 'Nominal',
        dataField: 'total_anggaran',
        formatter: (cell, row, rowIndex) => {
            return <b><FormatRupiah value={row.total_anggaran} /></b>
        },
        headerStyle: header
    },{
        text: 'Terpakai',
        dataField: 'total_pengeluaran',
        formatter: (cell, row, rowIndex) => {
            return <span style={{color:'red'}}><FormatRupiah value={row.total_pengeluaran} /></span>
        },
        headerStyle: header
    },{
        text: 'Tersisa',
        dataField: 'sisa_dana',
        formatter: (cell, row, rowIndex) => {
            return <span className='text-success text-bold'><FormatRupiah value={row.sisa_dana} /></span>
        },
        headerStyle: header
    },{
        text: 'Jenis',
        dataField: 'jenis_anggaran',
        headerStyle: header
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            let path = url + row.kode_lembaga + '/' + row.id; 
            navigate(path);
        }
    }

    function change(event){
        setLembaga(event.target.value);
        setData([]);
    }

    function changePeriode(event){
        setPeriode(event.target.value);
        setData([]);
    }

    let [tdClick, setTdClick] = useState(0);
    useEffect(() => {
        if (tdClick == 1){
            navigate('/keuangan-chart');
        }else if (tdClick == 2){
            navigate('/anggaran-all');
        }

    }, [tdClick]);

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="table-responsive m-1">
                        <table className="table" id="nav-table">
                            <tbody>
                                <tr>
                                    <td id="nav-no-active" onClick={() => {setTdClick(1)}}>
                                        <b className="text-xs">Grafik</b>
                                    </td>
                                    <td id="nav-active" onClick={() => {setTdClick(2)}}>
                                        <b className="text-xs">Agr Unit</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Anggaran Lembaga</h5>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        Periode
                        {periodeList.length !== 0 ?
                            <select className="form-select" onChange={changePeriode}>
                                {periodeList.map((periode) =>
                                    <option key={periode.id} value={periode.id}>{periode.tahun}</option>
                                )}
                            </select>:null}
                        <br />
                        Lembaga
                        {dataLembaga.length !== 0 ?
                            <select className="form-select" onChange={change}>
                                {dataLembaga.map((lbg) =>
                                    <option key={lbg.kode_lembaga} value={lbg.kode_lembaga}>{lbg.kode_lembaga} - {lbg.nama}</option>
                                )}
                            </select>:<select className="form-select"><option>-</option></select>}
                        <br/>
                        <ToolkitProvider
                            keyField="id"
                            data={ data }
                            columns={ columns }
                            search>
                            {
                                props => (
                                    <div>
                                        <SearchBar { ...props.searchProps } />
                                        <br />
                                        <BootstrapTable
                                            keyField="id"
                                            { ...props.baseProps }
                                            pagination={paginationFactory({
                                                onPageChange: (page, sizePerPage) => {
                                                    setPage(page);
                                            }})}
                                            bordered={ false }
                                            rowEvents={ rowEvents } 
                                            hover
                                            wrapperClasses="table-responsive"
                                            classes="table table-responsive"
                                            rowClasses="text-sm font-weight-normal"
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnggaranKeuanganList