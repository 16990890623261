import {Cookies} from 'react-cookie';
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom';
import {FormatRupiah} from '@arismun/format-rupiah';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {header, numTable, swalCustom} from '../../../components/CustomStyle';
import {DeleteAnggaran, GetAnggaranList, GetSinkronApple} from "../../../services/KeuanganService";
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

const AnggaranPimpinanList = () => {
    const url = '/anggaran-pimpinan/detail/'
    const url_edit = '/anggaran-pimpinan/edit/'
    const navigate = useNavigate();
    
    let {SearchBar} = Search;
    let cookies = new Cookies();
    let homebase = cookies.get('homebase_kode');

    let [page, setPage] = useState(1);
    let [spinIcon, setSpinIcon] = useState(0);

    let [data, setData] = useState([]);

    const getData = () => {
        GetAnggaranList(homebase).then((response) => {
            setData(response.data.rows);

        }).catch(() => console.log('error get_periode_all'));
    }

    const getSinkronApple = () => {
        GetSinkronApple(homebase).then((response) => {
            if (response.data.status == 200){
                getData();
                setSpinIcon(0);
            }
        }).catch(() => console.log('error sinkron_apple'));
    }

    function sinkronApple() {
        setSpinIcon(1);
        getSinkronApple();
    }

    useEffect(() => {
        getData();
    }, [homebase])

    const opensweetalert = (id) => {
        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin menghapus ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                DeleteAnggaran(homebase, id).then(response => {
                    if (response.status == 204){
                        swalCustom.fire('Berhasil dihapus!', '', 'success')
                        getData();
                    }
                }).catch(err => swalCustom.fire(err.response.data.message, '', 'fail'));
            }
        })
    }

    const namaTruncate = (cell, row, rowIndex, formatExtraData) => {
        const tc = 25;
        return (
            <>
            {row.nama_kegiatan.length > tc ? row.nama_kegiatan.substring(0, tc) + ' ...' : row.nama_kegiatan}
            </>
        );
    }

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    }, {
        text: 'Kegiatan',
        dataField: 'nama_kegiatan',
        title: (cell, row, rowIndex) => {
            return row.nama_kegiatan
        },
        formatter: namaTruncate,
        headerStyle: header
    },{
        text: 'Nominal',
        dataField: 'total_anggaran',
        formatter: (cell, row, rowIndex) => {
            return <FormatRupiah value={row.total_anggaran} />
        },
        headerStyle: header
    },{
        text: 'Terpakai',
        dataField: 'total_pengeluaran',
        formatter: (cell, row, rowIndex) => {
            return <FormatRupiah value={row.total_pengeluaran} />
        },
        headerStyle: header
    },{
        text: 'Jenis',
        dataField: 'jenis_anggaran',
        headerStyle: header
    },{
        text: 'Action',
        formatter: (cell, row, rowIndex) => {
            return <>
                <Link to={url_edit+row.id} onClick={(e) => e.stopPropagation()} className='btn btn-xs btn-warning me-1'>
                    <i className="fas fa-edit text-white"></i>
                </Link>
                <button onClick={(e) => {e.stopPropagation(); opensweetalert(row.id)}} className='btn btn-xs btn-danger'>
                    <i className="fas fa-trash text-white"></i>
                </button>
            </>
        },
        headerStyle: header
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            let path = url + row.id; 
            navigate(path);
        }
    }

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Anggaran Pimpinan</h5>
                            </div>
                            <div className="ms-auto my-auto mt-lg-0 mt-4">
                                <div className="ms-auto my-auto">
                                    <button onClick={sinkronApple} className="me-2 btn btn-xs btn-primary">
                                        <i className={spinIcon == 0 ? "fas fa-sync fa-lg me-1" : "fas fa-sync fa-lg me-1 fa-spin"}></i> Sinkron Apple
                                    </button>
                                    <Link to="/anggaran-pimpinan/form" className="btn btn-xs btn-success">
                                        <i className="fas fa-plus-square fa-lg me-1"></i> Tambah
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <ToolkitProvider
                            keyField="id"
                            data={ data }
                            columns={ columns }
                            search>
                            {
                                props => (
                                    <div>
                                        <SearchBar { ...props.searchProps } />
                                        <br />
                                        <BootstrapTable
                                            keyField="id"
                                            { ...props.baseProps }
                                            pagination={paginationFactory({
                                                onPageChange: (page, sizePerPage) => {
                                                    setPage(page);
                                            }})}
                                            bordered={ false }
                                            rowEvents={ rowEvents }
                                            hover
                                            wrapperClasses="table-responsive"
                                            classes="table table-responsive"
                                            rowClasses="text-sm font-weight-normal"
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnggaranPimpinanList