import Moment from 'moment';
import React, {useEffect, useState} from "react";
import {useNavigate, Link} from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory from 'react-bootstrap-table2-paginator';
import {header, numTable, swalCustom} from '../../../components/CustomStyle';
import {DeletePeriode, GetPeriodeList} from "../../../services/KeuanganService";
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

const PeriodeList = () => {
    const url = '/periode/detail/'
    const url_edit = '/periode/edit/'

    const {SearchBar} = Search;
    const navigate = useNavigate();

    let [page, setPage] = useState(1);
    let [data, setData] = useState([]);

    const getData = async () => {
        await GetPeriodeList().then((response) => {
            setData(response.data.rows);

        }).catch(() => console.log('error GetPeriodeList'));
    }

    useEffect(() => {
        getData();
    }, [])

    const delsweetalert = (tahun) => {
        swalCustom.fire({
            type: 'warning',
            title: 'Anda yakin ingin menghapus ?',
            showCancelButton: true,
            confirmButtonText: '<i className="fa fa-check-circle text-white me-1"></i> Ya',
            cancelButtonText: '<i className="fa fa-times-circle text-white"></i> Tidak',
        }).then((result) => {
            if (result.value === true) {
                DeletePeriode(tahun).then(response => {
                    swalCustom.fire('Berhasil dihapus!', '', 'success')
                    getData();
                })
            }
        })
    }

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    }, {
        text: 'Tahun',
        dataField: 'tahun',
        headerStyle: header
    }, {
        text: 'Tanggal Dibuat',
        dataField: 'created_at',
        formatter: (cell, row, rowIndex) => {
            return <>{Moment(row.created_at).format('DD MMMM YYYY')}</>
        },
        headerStyle: header
    }, {
        text: 'Dibuat Oleh',
        dataField: 'created_by',
        headerStyle: header
    }, {
        text: 'Action',
        dataField: 'button',
        formatter: (cell, row, rowIndex) => {
            return <>
                <Link to={url_edit+row.tahun} onClick={(e) => e.stopPropagation()} className='btn btn-xs btn-warning me-1'>
                    <i className="fas fa-edit text-white"></i>
                </Link>
                <button onClick={(e) => {e.stopPropagation(); delsweetalert(row.tahun)}} className='btn btn-xs btn-danger'>
                    <i className="fas fa-trash text-white"></i>
                </button>
            </>
        },
        headerStyle: header
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            let path = url + row.tahun; 
            navigate(path);
        }
    }

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Periode</h5>
                            </div>

                            <div className="ms-auto my-auto mt-lg-0 mt-4">
                                <div className="ms-auto my-auto">
                                    <Link to="/periode/form" className="btn btn-xs btn-success">
                                        <i className="fas fa-plus-square fa-lg me-1"></i> Tambah
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <ToolkitProvider
                            keyField="id"
                            data={ data }
                            columns={ columns }
                            search>
                            {
                                props => (
                                    <div>
                                        <SearchBar { ...props.searchProps } />
                                        <br />
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            { ...props.baseProps }
                                            pagination={paginationFactory({
                                                onPageChange: (page, sizePerPage) => {
                                                    setPage(page);
                                            }})}
                                            bordered={ false }
                                            rowEvents={ rowEvents }
                                            hover
                                            wrapperClasses="table-responsive"
                                            classes="table table-responsive"
                                            headerWrapperClasses="thead-light"
                                            rowClasses="text-sm font-weight-normal"
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PeriodeList