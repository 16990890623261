import { useNavigate } from "react-router-dom";
import { GetPic, PostRekeningAdmin } from "../../../services/KeuanganService";
import { swalCustom } from "../../../components/CustomStyle";
import CurrencyInput from "react-currency-input-field";
import { useEffect, useState } from "react";
import Select from 'react-select'

const RekeningKeuanganForm = () => {
    const navigate = useNavigate();
    let optPic = [];
    let [picList, setPicList] = useState([]);

    const getPic = () => {
        GetPic().then((response) => {
            let resp = response.data.rows;
            for (let i = 0; i < resp.length; i++){
                let opt = {
                    value: resp[i].user[0].username,
                    label: resp[i].nama_bergelar,
                }
                optPic.push(opt);
            }
            setPicList(optPic);

        }).catch(() => console.log('error get_pic'));
    }

    useEffect(() => {
        getPic();
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('kode_lembaga', event.target.kode_lembaga.value);
        formData.append('nama', event.target.nama.value);
        formData.append('nomor_rekening', event.target.nomor_rekening.value);
        // formData.append('saldo', event.target.saldo.value);
        formData.append('saldo', event.target.saldo.value.replace(/[^0-9.]/g, ''));
        formData.append('kepala', event.target.kepala.value);

        PostRekeningAdmin(formData).then(response => {
            if (response.status == 201){
                swalCustom.fire({
                    title: "Sukses",
                    text: "Data berhasil ditambah",
                    type: "success",
                    confirmButtonText: "OK",
                }).then((result) => {
                    if (result.value === true){
                        navigate('/rekening-all');
                    }
                });
            }
        }).catch(err => swalCustom.fire(err.response.data.message, '', 'fail'));
    };

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Rekening Form</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                Kode Lembaga
                                                <input className="form-control" type="text" name="kode_lembaga" required />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Nama Rekening
                                                <input className="form-control" type="text" name="nama" required />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Nomor Rekening
                                                <input className="form-control" type="text" name="nomor_rekening" required />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Saldo
                                                {/* <input className="form-control" type="text" name="saldo" required /> */}
                                                <CurrencyInput name="saldo" className="form-control" prefix="Rp " decimalsLimit={2} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                Kepala
                                                {/* <input className="form-control" type="text" name="kepala" required /> */}
                                                <Select name="kepala" options={picList} />
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <button className="btn btn-xs btn-success" type="submit">
                                        <i className="fas fa-save me-2"></i>Simpan
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RekeningKeuanganForm