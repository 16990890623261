import {Link, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Moment from 'moment';

import {DeleteKodeAnggaran, GetKodeAnggaranList} from "../../../services/KeuanganService";
import { header, numTable, swalCustom } from '../../../components/CustomStyle';

const KodeAnggaranList = () => {
    const url = '/mata-anggaran/detail/'
    const url_edit = '/mata-anggaran/edit/'
    const { SearchBar } = Search;
    let [data, setData] = useState([]);
    const navigate = useNavigate();
    let [page, setPage] = useState(1);

    const getData = async () => {
        await GetKodeAnggaranList().then((response) => {
            setData(response.data.rows);

        }).catch(() => console.log('error GetKodeAnggaranList'));
    }

    useEffect(() => {
        getData();
    }, [])

    const delsweetalert = (kode) => {
        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin menghapus ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                DeleteKodeAnggaran(kode).then(response => {
                    swalCustom.fire('Berhasil dihapus!', '', 'success')
                    getData();
                }).catch(err =>swalCustom.fire(err.response.data.message, '', 'error'));
            }
        });
    }

    const ketTruncate = (cell, row, rowIndex, formatExtraData) => {
        const tc = 25;
        return (
            <>
            {row.keterangan.length > tc ? row.keterangan.substring(0, tc) + ' ...' : row.keterangan}
            </>
        );
    }

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    }, {
        text: 'Kode',
        dataField: 'kode',
        headerStyle: header
    }, {
        text: 'Keterangan',
        dataField: 'keterangan',
        title: (cell, row, rowIndex) => {
            return row.keterangan
        },
        formatter : ketTruncate,
        headerStyle: header
    }, {
        text: 'Tanggal Dibuat',
        dataField: 'created_at',
        formatter: (cell, row, rowIndex) => {
            return <>{Moment(row.created_at).format('DD MMMM YYYY')}</>
        },
        headerStyle: header
    }, {
        text: 'Dibuat Oleh',
        dataField: 'created_by',
        headerStyle: header
    }, {
        text: 'Action',
        dataField: 'button',
        formatter: (cell, row, rowIndex) => {
            return <>
                <Link to={url_edit+row.kode} onClick={(e) => e.stopPropagation()} className='btn btn-xs btn-warning me-1'>
                    <i className="fas fa-edit text-white"></i>
                </Link>
                <button onClick={(e) => {e.stopPropagation(); delsweetalert(row.kode)}} className='btn btn-xs btn-danger'>
                    <i className="fas fa-trash text-white"></i>
                </button>
            </>
        },
        headerStyle: header
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            let path = url + row.kode; 
            navigate(path);
        }
    }

    let [tdClick, setTdClick] = useState(0);
    useEffect(() => {
        if (tdClick == 1){
            navigate('/mata-anggaran');
        }else if (tdClick == 2){
            navigate('/rab');
        }

    }, [tdClick]);

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="table-responsive m-1">
                        <table className="table" id="nav-table">
                            <tbody>
                                <tr>
                                    <td id="nav-active" onClick={() => {setTdClick(1)}}>
                                        <b className="text-xs">Mata Agr</b>
                                    </td>
                                    <td id="nav-no-active" onClick={() => {setTdClick(2)}}>
                                        <b className="text-xs">RAB</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Mata Anggaran</h5>
                            </div>

                            <div className="ms-auto my-auto mt-lg-0 mt-4">
                                <div className="ms-auto my-auto">
                                    <Link to="/mata-anggaran/form" className="btn btn-xs btn-success">
                                        <i className="fas fa-plus-square fa-lg me-1"></i> Tambah
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <ToolkitProvider
                            keyField="kode"
                            data={ data }
                            columns={ columns }
                            search>
                            {
                                props => (
                                    <div>
                                        <SearchBar { ...props.searchProps } />
                                        <br />
                                        <BootstrapTable
                                            keyField="id"
                                            { ...props.baseProps }
                                            pagination={paginationFactory({
                                                onPageChange: (page, sizePerPage) => {
                                                    setPage(page);
                                            }})}
                                            bordered={ false }
                                            rowEvents={ rowEvents }
                                            hover
                                            wrapperClasses="table-responsive"
                                            classes="table table-responsive"
                                            rowClasses="text-sm font-weight-normal"
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KodeAnggaranList