import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {swalCustom} from "../../../components/CustomStyle";
import {GetPeriodeDetail, UpdatePeriode} from "../../../services/KeuanganService";

const PeriodeEdit = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    
    let [data, setData] = useState({});

    useEffect(() => {
        GetPeriodeDetail(id).then((response) => {
            setData(response.data);

        }).catch(() => console.log('error get periode detail'));
    }, [id])

    const handleSubmit = (event) => {
        event.preventDefault();
        let { tahun } = document.forms[0];

        let formData = new FormData();
        formData.append('tahun', tahun.value);

        UpdatePeriode(id, formData).then(response => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil diubah",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true){
                    navigate('/periode');
                }
            });
        }).catch(response => {
            swalCustom.fire({
                title: "Gagal",
                text: "Data gagal diubah",
                type: "error",
            });
        });
    };

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Periode Edit</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                Tahun
                                                <input type="text" name="tahun" value={data.tahun} className="form-control"
                                                    onChange={x => setData({...data, tahun: x.target.value})} required />
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <Link to="/periode" className="btn btn-xs btn-secondary me-1">
                                        <i className="fas fa-chevron-circle-left me-2"></i>Kembali
                                    </Link>
                                    <button className="btn btn-xs btn-success" type="submit">
                                        <i className="fas fa-save me-2"></i>Simpan
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PeriodeEdit