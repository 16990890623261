import {Cookies} from "react-cookie";
import {useNavigate, useLocation} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import {GetHomebase} from "../services/KeuanganService";

function Navbar({setBege, gom}) {
    const location = useLocation();
    let cookies = new Cookies();
    let navigate = useNavigate();
    let pathname = window.location.pathname;
    let [spinIcon, setSpinIcon] = useState(0);
    let [homebase, setHomebase] = useState([]);
    let homebase_now = cookies.get('homebase_rekening');

    const handleChangeHomebase = (e) => {
        homebase.map((val) => {
            if (val.nomor_rekening == e.target.value){
                cookies.set('homebase_kode', val.kode_lembaga);
                cookies.set('homebase_nama', val.nama);
                cookies.set('homebase_rekening', val.nomor_rekening);

                setSpinIcon(1);
                setTimeout(() => {
                    setSpinIcon(0);
                }, 500);
            }
        })

        navigate(pathname);
    }

    const getData = () => {
        GetHomebase().then((response) => {
            setHomebase(response.data);

            const homebase_now = cookies.get('homebase_kode');
            if (homebase_now == null || homebase_now == ''){
                cookies.set('homebase_kode', response.data[0].kode_lembaga);
                cookies.set('homebase_nama', response.data[0].nama);
                cookies.set('homebase_rekening', response.data[0].nomor_rekening);
                cookies.set('is_unit', response.data[0].unit);
            }

        }).catch(() => console.log('error get_homebase_navbar'));
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        getData();
    }, [location])

    function logout_sso() {
        cookies.remove('token');
        cookies.remove('refresh');
        cookies.remove('username');
        cookies.remove('homebase_kode');
        cookies.remove('homebase_nama');
        cookies.remove('homebase_rekening');
        cookies.remove('is_unit');
        cookies.remove('groups');

        navigate('/login');
    }

    const handleClick = () => {
        setBege((count) => !count);
    };

    return (
        <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl z-index-sticky "
             id="navbarBlur" data-scroll="false">
            <div className="container-fluid py-1 px-3">
                <div className="sidenav-toggler sidenav-toggler-inner d-xl-block d-none pe-5">
                    <a href="#" onClick={handleClick} className="nav-link p-0">
                        <div className="sidenav-toggler-inner">
                            <i className="sidenav-toggler-line bg-white"></i>
                            <i className="sidenav-toggler-line bg-white"></i>
                            <i className="sidenav-toggler-line bg-white"></i>
                        </div>
                    </a>
                </div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                        <li className="breadcrumb-item text-sm">
                        </li>
                        <li className="text-sm text-white active">
                            Homebase Aktif {gom} <i className="fa fa-info-circle" title="Pilih untuk mengaktifkan homebase"></i>
                        </li>
                        <li className="text-sm text-white active ms-2" aria-current="page">
                            {spinIcon == 1 ? <i className="fas fa-sync fa-lg me-1 fa-spin text-white"></i> : null}
                        </li>
                    </ol>

                    <select name="sel-homebase" onChange={handleChangeHomebase}
                        className="form-select form-select-md pe-5 font-weight-bolder text-white bg-primary">
                        {homebase && homebase.map((val) => 
                            homebase_now == val.nomor_rekening ? 
                                <option key={val.nomor_rekening} value={val.nomor_rekening} selected>{val.nama} - {val.nomor_rekening}</option> :
                                <option key={val.nomor_rekening} value={val.nomor_rekening}>{val.nama} - {val.nomor_rekening}</option>
                        )}
                    </select>
                </nav>

                <div className="navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4">
                    <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                    </div>
                    <ul className="navbar-nav justify-content-end ms-2">
                        <li className="nav-item d-flex align-items-center">
                            {cookies.get('token') !== '' ? (
                                <>
                                <a href="/" className="nav-link text-white font-weight-bold px-0">
                                    <i className="fa fa-user me-sm-1"></i>
                                    <span className="d-sm-inline d-none">{cookies.get('username') !== '' ? cookies.get('username') : <p>Anonim</p>}</span>
                                </a>

                                <button onClick={logout_sso} className="btn btn-xs btn-danger ms-2 mt-3">
                                    <i className="fas fa-sign-out text-white me-2"></i> Logout
                                </button>
                                </>
                            ) : (
                                <a href="/"
                                    className="nav-link text-white font-weight-bold px-0">
                                    <i className="fa fa-user me-sm-1"></i>
                                    <span className="d-sm-inline d-none">login</span>
                                </a>
                            )}
                        </li>
                        <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                            <a href="#" onClick={handleClick} className="nav-link text-white p-0" id="iconNavbarSidenav">
                                <div className="sidenav-toggler-inner">
                                    <i className="sidenav-toggler-line bg-white"></i>
                                    <i className="sidenav-toggler-line bg-white"></i>
                                    <i className="sidenav-toggler-line bg-white"></i>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item px-3 d-flex align-items-center">
                            <a href="#" className="nav-link text-white p-0">
                                <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
                            </a>
                        </li>
                        <li className="nav-item dropdown pe-2 d-flex align-items-center">
                            <a href="#" className="nav-link text-white p-0" id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-bell cursor-pointer"></i>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4"
                                aria-labelledby="dropdownMenuButton">
                                <li className="mb-2">
                                    <a className="dropdown-item border-radius-md" href="#">
                                        <div className="d-flex py-1">
                                            <div className="my-auto">
                                                <img src="" className="avatar avatar-sm me-3 " alt="user image"/>
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="text-sm font-weight-normal mb-1">
                                                    <span className="font-weight-bold">New message</span> from Laur
                                                </h6>
                                                <p className="text-xs text-secondary mb-0">
                                                    <i className="fa fa-clock me-1"></i>
                                                    13 minutes ago
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;