import {Cookies} from 'react-cookie';
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom';

import {FormatRupiah} from '@arismun/format-rupiah';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {header, numTable, swalCustom, headerStickLeft, tdStickLeft} from '../../../components/CustomStyle';
import {DeleteAnggaran, GetAnggaranListV2, GetPeralihanAnggaranListAdmin} from "../../../services/KeuanganService";

const AnggaranPeralihanList = () => {
    const url = '/anggaran-peralihan/'

    const navigate = useNavigate();
    let {SearchBar} = Search;
    
    let cookies = new Cookies();
    let homebase = cookies.get('homebase_kode');

    let [page, setPage] = useState(1);
    let [data, setData] = useState([]);

    const getData = async () => {
        await GetPeralihanAnggaranListAdmin().then((response) => {
            setData(response.data.rows);
            console.log(response.data.rows);
            
        }).catch(() => console.log('error GetPeralihanAnggaranListAdmin'))
    }

    useEffect(() => {
        getData();
    }, [])

    const opensweetalert = (id) => {
        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin menghapus ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {

            }
        })
    }

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    }, {
        text: 'Asal Kegiatan',
        dataField: 'asal_nama_kegiatan',
        formatter: (cell, row, rowIndex) => {
            return <>
                <b>{row.asal_nama_kegiatan}</b><br/>
                <i>{row.periode}</i>
            </>
        },
        style: {
            width: 500,
            whiteSpace: 'normal'
        },
        headerStyle: header
    },{
        text: 'Tujuan Kegiatan',
        dataField: 'tujuan_nama_kegiatan',
        formatter: (cell, row, rowIndex) => {
            return <>
                <b>{row.tujuan_nama_kegiatan}</b><br/>
                <i>{row.periode}</i>
            </>
        },
        headerStyle: header
    },{
        text: 'Nominal',
        dataField: 'total',
        formatter: (cell, row, rowIndex) => {
            return <>
                {row.status_peralihan == 'diajukan' ? <><span className="badge badge-sm badge-info">{row.status_peralihan}</span><br/></> : ''}
                {row.status_peralihan == 'disetujui' ? <><span className="badge badge-sm badge-success">{row.status_peralihan}</span><br/></> : ''}
                {row.status_peralihan == 'dibatalkan' ? <><span className="badge badge-sm badge-danger">{row.status_peralihan}</span><br/></> : ''}
                <b><FormatRupiah value={row.total} /></b>
            </>
        },
        headerStyle: header
    },{
        text: 'Action',
        dataField: 'button',
        formatter: (cell, row, rowIndex) => {
            return <>
                <Link to={url+row.id} onClick={(e) => e.stopPropagation()} className='btn btn-xs btn-primary me-1'>
                    <i className="fas fa-eye text-white"></i>
                </Link>
            </>
        },
        headerStyle: header
    }
    ];

    let [tdClick, setTdClick] = useState(0);
    useEffect(() => {
        if (tdClick == 1){
            navigate('/trx-unit-verifikasi/list');
        }else if (tdClick == 2){
            navigate('/anggaran-peralihan');
        }
    }, [tdClick]);

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="table-responsive m-1">
                        <table className="table" id="nav-table">
                            <tbody>
                                <tr>
                                    <td id="nav-no-active" onClick={() => {setTdClick(1)}}>
                                        <b className="text-xs">Bukti Upload</b>
                                    </td>
                                    <td id="nav-active" onClick={() => {setTdClick(2)}}>
                                        <b className="text-xs">Peralihan Anggaran</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Anggaran Peralihan</h5>
                            </div>
                            <div className="ms-auto my-auto mt-lg-0 mt-4">
                                <div className="ms-auto my-auto">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <ToolkitProvider
                            keyField="id"
                            data={ data }
                            columns={ columns }
                            search>
                            {
                                props => (
                                    <div>
                                        <SearchBar { ...props.searchProps } />
                                        <br />
                                        <BootstrapTable
                                            keyField="id"
                                            { ...props.baseProps }
                                            pagination={paginationFactory({
                                                onPageChange: (page, sizePerPage) => {
                                                    setPage(page);
                                            }})}
                                            bordered={ false }
                                            hover
                                            wrapperClasses="table-responsive"
                                            classes="table table-responsive"
                                            rowClasses="text-sm font-weight-normal"
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnggaranPeralihanList