import Moment from "moment/moment";

import {Cookies} from 'react-cookie';
import {useEffect, useState} from "react";
import {useParams, Link} from "react-router-dom";
import { Modal } from 'react-bootstrap';
import CurrencyInput from "react-currency-input-field";
import Select from 'react-select'

import {FormatRupiah} from "@arismun/format-rupiah";
import {detailStyle, header, numTable, swalCustom} from "../../../components/CustomStyle";
import {GetAnggaranDetailV2, GetAnggaranListV2, GetPeralihanAnggaran, PostPeralihanAnggaran, GetAnggaranPeriodeNow} from "../../../services/KeuanganService";

const AnggaranDetail = () => {
    let {id} = useParams();
    let {periode} = useParams();

    let cookies = new Cookies();
    let homebase = cookies.get('homebase_kode');

    let [detail, setDetail] = useState({});
    let [pengeluaran, setPengeluaran] = useState([]);
    let [peralihan, setPeralihan] = useState([]);

    let [anggaran, setAnggaran] = useState([]);
    let [nominalPending, setNominalPending] = useState(0);
    let [nominalInput, setNominalInput] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);

    const getDetail = async () => {
        await GetAnggaranDetailV2(homebase, periode, id).then((response) => {
            setDetail(response.data);
            setPengeluaran(response.data.detail_pengeluaran);

        }).catch(() => console.log('error GetAnggaranDetailV2'));
    }

    const getPeralihanAnggaran = async () => {
        await GetPeralihanAnggaran(id).then((response) => {
            setPeralihan(response.data.rows);
            
            let total_pending = 0;
            let resp = response.data.rows;
            for (let i = 0; i < resp.length; i++){
                if (resp[i].status_peralihan == 'diajukan'){
                    total_pending += resp[i].total
                }
            }
            setNominalPending(total_pending);

        }).catch(() => console.log('error GetPeralihanAnggaran'))
    }

    const getAnggaranLembaga = async () => {
        await GetAnggaranListV2(homebase, periode).then((response) => {
            setAnggaran(response.data.rows);

        }).catch(() => console.log('get GetAnggaranListV2'));
    }

    useEffect(() => {
        getDetail();
        getPeralihanAnggaran();
        getAnggaranLembaga();
    }, [id])


    const [isShowCreate, invokeModalCreate] = useState(false)
    const initModalCreate = () => {
        return invokeModalCreate(!isShowCreate);
    }

    const handleCreatePeralihan = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('asal_anggaran_id', detail.id);
        formData.append('asal_nama_kegiatan', detail.nama_kegiatan);
        formData.append('asal_jenis_anggaran', detail.jenis_anggaran);

        let tujuan_select_val = event.target.tujuan_anggaran.value;
        let tujuan_opt = anggaran.find(anggarans => anggarans.id == tujuan_select_val);
        formData.append('tujuan_anggaran_id', tujuan_opt.id);
        formData.append('tujuan_nama_kegiatan', tujuan_opt.nama_kegiatan);
        formData.append('tujuan_jenis_anggaran', tujuan_opt.jenis_anggaran);

        formData.append('periode', detail.periode.tahun);
        formData.append('kode_lembaga', homebase);
        formData.append('total', event.target.nominal_peralihan.value.replace(/[^\d.-]/g, ''));
        formData.append('created_by', cookies.get('username'));
        formData.append('status_peralihan', 'diajukan');

        PostPeralihanAnggaran(id, formData).then(response => {
            if (response.status == 201){
                swalCustom.fire({
                    title: "Sukses",
                    text: "Data berhasil ditambah",
                    type: "success",
                    confirmButtonText: "OK",
                }).then((result) => {
                    if (result.value === true){
                        invokeModalCreate(!isShowCreate)
                        getPeralihanAnggaran();
                    }
                });
            }
        }).catch(err => {
            invokeModalCreate(!isShowCreate)
            swalCustom.fire('Gagal menambah!'.response.data.message, 'error')
            getPeralihanAnggaran();
        });
    }

    const handleChangeNominalInput = (event) => {
        const input_val = event.target.value.replace(/[^0-9.]/g, '');

        if (input_val > (detail.sisa_dana - nominalPending)){
            setShowTooltip(true);
            setNominalInput(detail.sisa_dana - nominalPending);
        }else{
            setShowTooltip(false);
            setNominalInput(input_val);
        }
    }

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Anggaran Detail</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Kode Lembaga
                                            </div>
                                            <div className="col-md-10">
                                                {detail.kode_lembaga}
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Jenis Anggaran
                                            </div>
                                            <div className="col-md-2">
                                                {detail.jenis_anggaran}<br/>
                                                {detail.bukti_pengajuan != null ?
                                                    <Link target='_blank' className="btn btn-xs btn-danger" to={detail.bukti_pengajuan}>
                                                        Bukti Pengajuan
                                                    </Link>:'-'}
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Nama Kegiatan
                                            </div>
                                            <div className="col-md-10">
                                                {detail.nama_kegiatan}
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Total Anggaran
                                            </div>
                                            <div className="col-md-10">
                                                <b><FormatRupiah value={detail.total_anggaran} /></b>
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Terpakai
                                            </div>
                                            <div className="col-md-10">
                                                <span className="text-danger"><FormatRupiah value={detail.total_pengeluaran} /></span>
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Tersisa
                                            </div>
                                            <div className="col-md-10">
                                                <span className="text-success"><b><FormatRupiah value={detail.sisa_dana} /></b></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="row mt-6 mb-3">
                                    <div className="col-lg-12 mb-3">
                                        <h5>Penggunaan Anggaran</h5>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th style={header}>No.</th>
                                                        <th style={header}>Rekening Tujuan</th>
                                                        <th style={header}>Keterangan</th>
                                                        <th style={header}>Nominal</th>
                                                        <th style={header}>Tanggal</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pengeluaran.map((peng, index)=> {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-sm font-weight-normal">{index+1}</td>
                                                                <td className="text-sm font-weight-normal" style={{whiteSpace: "normal"}}>
                                                                    {peng.is_payroll == 1 ? <>
                                                                        <span className="badge badge-sm badge-info ms-1">Payroll</span><br/>
                                                                    </> : ''}
                                                        
                                                                    <b>{peng.rekening_tujuan ? peng.rekening_tujuan : '-'}</b><br/>
                                                                    <i>{peng.nama_rekening_tujuan ? peng.nama_rekening_tujuan : '-'}</i>
                                                                </td>
                                                                <td className="text-sm font-weight-normal" style={{whiteSpace: "normal"}}>
                                                                    {peng.keterangan ? peng.keterangan : '-'}
                                                                </td>
                                                                <td className="text-sm font-weight-normal">
                                                                    {peng.status_trx == 0 ? <>
                                                                        <span className="badge badge-sm badge-success">Keluar</span><br/>
                                                                    </> : <>
                                                                        <span className="badge badge-sm badge-primary">Masuk</span><br/>
                                                                    </>}
                                                                    <b><FormatRupiah value={peng.nominal} /></b>
                                                                </td>
                                                                <td className="text-sm font-weight-normal">
                                                                    {Moment(peng.tanggal).format('DD MMMM YYYY hh:mm')}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <Link to="/anggaran" className="btn btn-sm btn-secondary">
                                    <i className="fas fa-chevron-circle-left me-2"></i>Kembali
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Status Peralihan Anggaran</h5>
                                    <button onClick={(e) => {e.stopPropagation(); initModalCreate()}} className="ms-2 btn btn-xs btn-warning">Alihkan Anggaran</button>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <div className="col-lg-12">
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th style={header}>No.</th>
                                                    <th style={header}>Asal Anggaran</th>
                                                    <th style={header}>Tujuan Anggaran</th>
                                                    <th style={header}>Total</th>
                                                    <th style={header}>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {peralihan.map((pera, index)=> {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="text-sm font-weight-normal">{index+1}</td>
                                                            <td className="text-sm font-weight-normal" style={{whiteSpace: "normal"}}>                    
                                                                <b>{pera.asal_nama_kegiatan ? pera.asal_nama_kegiatan : '-'}</b> <br/>{pera.periode}
                                                            </td>
                                                            <td className="text-sm font-weight-normal" style={{whiteSpace: "normal"}}>
                                                                <b>{pera.tujuan_nama_kegiatan ? pera.tujuan_nama_kegiatan : '-'}</b> <br/>{pera.periode}
                                                            </td>
                                                            <td className="text-sm font-weight-normal">
                                                                {pera.status_peralihan == 'diajukan' ? <><span className="badge badge-sm badge-info">{pera.status_peralihan}</span><br/></> : ''}
                                                                {pera.status_peralihan == 'disetujui' ? <><span className="badge badge-sm badge-success">{pera.status_peralihan}</span><br/></> : ''}
                                                                {pera.status_peralihan == 'dibatalkan' ? <><span className="badge badge-sm badge-danger">{pera.status_peralihan}</span><br/></> : ''}
                                                                <b><FormatRupiah value={pera.total} /></b>
                                                            </td>
                                                            <td className="text-sm font-weight-normal" style={{whiteSpace: "normal"}}>
                                                                {Moment(pera.created_at).format('DD MMMM YYYY hh:mm')}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Modal show={isShowCreate}>
                <Modal.Header>
                    <Modal.Title>Peralihan Anggaran</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleCreatePeralihan}>
                <Modal.Body>
                    <div className="form-group">
                        <div className="row text-center">
                            <div className="col-lg-12"><b>Anggaran Sekarang</b></div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-lg-4">Nama Kegiatan</div>
                            <div className="col-lg-8">{detail && detail.nama_kegiatan}</div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-lg-4">Periode</div>
                            <div className="col-lg-8">{detail.periode && detail.periode.tahun}</div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-lg-4">Sisa Dana</div>
                            <div className="col-lg-8"><FormatRupiah value={detail && detail.sisa_dana} /></div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-lg-4">Peralihan Pending</div>
                            <div className="col-lg-8"><FormatRupiah value={nominalPending} /></div>
                        </div>
                    </div>
                    <hr/>
                    <div className="form-group mt-4">
                        <div className="row">
                            <div className="col-lg-12">
                                Nominal Peralihan
                            </div>
                            <div className="col-lg-12">
                                {showTooltip && <div className="text-danger">Nominal tidak boleh melebihi <FormatRupiah value={detail.sisa_dana-nominalPending}/></div>}
                                <CurrencyInput 
                                    name="nominal_peralihan" 
                                    className="form-control" 
                                    prefix="Rp " 
                                    decimalsLimit={2} 
                                    onChange={handleChangeNominalInput}
                                    value={nominalInput}
                                    required />
                                <span className="text-xs">*untuk koma gunakan . (titik)</span>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mt-4">
                        <div className="row">
                            <div className="col-lg-12">
                                Tujuan Anggaran
                            </div>
                            <div className="col-lg-12">                   
                                <select className="form-control" name="tujuan_anggaran">
                                    {anggaran && anggaran.map((val, index) => {
                                        return <option value={val.id}>{val.nama_kegiatan} (<FormatRupiah value={val.sisa_dana} />)</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type='button' className='btn btn-xs btn-primary' onClick={initModalCreate}>Batal</button>
                    <button type='submit' className='btn btn-xs btn-success'>Simpan</button>
                </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default AnggaranDetail