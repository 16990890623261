import React, {useEffect, useState} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { FormatRupiah } from '@arismun/format-rupiah';
import cellEditFactory from 'react-bootstrap-table2-editor';

import { GetAllRekening, GetPic, GetSinkronAllRekening, PostRekeningAdmin, UpdateRekeningAdmin } from '../../../services/KeuanganService';
import { header, numTable, swalCustom } from '../../../components/CustomStyle';
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import CurrencyInput from "react-currency-input-field";
import Select from 'react-select'

const RekeningKeuanganList = () => {
    const { SearchBar } = Search;
    let [data, setData] = useState([]);
    let [spinIcon, setSpinIcon] = useState(0);
    let [page, setPage] = useState(1);

    let [detail, setDetail] = useState({});
    let [detailSaldo, setDetailSaldo] = useState();
    let [detailNoRek, setDetailNoRek] = useState();

    let optPic = [];
    let [editOptPic, setEditOptPic] = useState();
    let [picList, setPicList] = useState([]);

    const [isShowEdit, invokeModalEdit] = useState(false)
    const initModalEdit = (row) => {
        setDetail(row);
        setDetailSaldo(row.saldo);
        setDetailNoRek(row.nomor_rekening);
        setEditOptPic(row.kepala);

        return invokeModalEdit(!isShowEdit);
    }

    const [isShowCreate, invokeModalCreate] = useState(false)
    const initModalCreate = () => {
        return invokeModalCreate(!isShowCreate);
    }

    const getPic = () => {
        GetPic().then((response) => {
            let resp = response.data.rows;
            for (let i = 0; i < resp.length; i++){
                let opt = {
                    value: resp[i].user[0].username,
                    label: resp[i].nama_bergelar,
                }
                optPic.push(opt);
            }
            setPicList(optPic);

        }).catch(() => console.log('error get_pic'));
    }

    const getData = () => {
        GetAllRekening().then((response) => {
            setData(response.data.rows);

        }).catch(() => console.log('error get_rekening_unit'));
    }

    const getSinkronRekening = () => {
        GetSinkronAllRekening().then((response) => {
            if (response.status == 200){
                getData();
                setSpinIcon(0);
            }
        }).catch(() => console.log('error sinkron_apple'));
        setSpinIcon(0);
    }

    function sinkronRekening() {
        setSpinIcon(1);
        getSinkronRekening();
    }

    useEffect(() => {
        getData();
        getPic();
    }, [])

    const handleEdit = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('kode_lembaga', detail.kode_lembaga);
        formData.append('nama', detail.nama);
        formData.append('kepala', event.target.kepala.value);
        formData.append('nomor_rekening', event.target.nomor_rekening.value);

        // formData.append('saldo', event.target.saldo.value.replace(/[^0-9]/g, ''));
        formData.append('saldo', event.target.saldo.value.replace(/[^\d.-]/g, ''));

        swalCustom.fire({
            type: 'question',
            title: 'Anda yakin ingin mengubah data rekening ?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak',
        }).then((result) => {
            if (result.value === true) {
                UpdateRekeningAdmin(detail.kode_lembaga, detailNoRek, detail.id, formData).then((response) => {
                    invokeModalEdit(!isShowEdit)
                    swalCustom.fire('Rekening diubah!', '', 'success')
                    getData();
        
                }).catch(err => {
                    invokeModalEdit(!isShowEdit)
                    swalCustom.fire('Gagal diubah!'.response.data.message, 'error')
                    getData();
                });
            }else{
                invokeModalEdit(!isShowEdit)
            }
        })
    }

    const handleCreate = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('kode_lembaga', event.target.kode_lembaga.value);
        formData.append('nama', event.target.nama.value);
        formData.append('kepala', event.target.kepala.value);
        formData.append('nomor_rekening', event.target.nomor_rekening.value);

        // formData.append('saldo', event.target.saldo.value.replace(/[^0-9]/g, ''));
        formData.append('saldo', event.target.saldo.value.replace(/[^\d.-]/g, ''));

        PostRekeningAdmin(formData).then(response => {
            if (response.status == 201){
                swalCustom.fire({
                    title: "Sukses",
                    text: "Data berhasil ditambah",
                    type: "success",
                    confirmButtonText: "OK",
                }).then((result) => {
                    if (result.value === true){
                        invokeModalCreate(!isShowCreate)
                        getData();
                    }
                });
            }
        }).catch(err => {
            invokeModalCreate(!isShowCreate)
            swalCustom.fire('Gagal menambah!'.response.data.message, 'error')
            getData();
        });
    }

    const onChangeEditKepala = (e) => {
        setEditOptPic(e.value);
    }

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header
    }, {
        text: 'Kode Lembaga',
        dataField: 'kode_lembaga',
        headerStyle: header
    }, {
        text: 'Nama Lembaga',
        dataField: 'nama',
        headerStyle: header
    }, {
        text: 'Kepala',
        dataField: 'kepala',
        headerStyle: header
    }, {
        text: 'Nomor Rekening',
        dataField: 'nomor_rekening',
        headerStyle: header
    }, {
        text: 'Saldo',
        dataField: 'saldo',
        headerStyle: header,
        formatter: (cell, row, rowIndex) => {
            return <FormatRupiah value={row.saldo} />
        }
    },{
        text: 'Action',
        dataField: 'button',
        formatter: (cell, row, rowIndex) => {
            return <>
                <button onClick={(e) => {e.stopPropagation(); initModalEdit(row)}} className="btn btn-xs btn-warning">
                    <i className="fas fa-edit text-white"></i>
                </button>
            </>
        },
        headerStyle: header
    }];

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Rekening</h5>
                            </div>

                            <div className="ms-auto my-auto mt-lg-0 mt-4">
                                <div className="ms-auto my-auto">
                                    <button onClick={sinkronRekening} className="me-2 btn btn-xs btn-primary">
                                        <i className={spinIcon == 0 ? "fas fa-sync fa-lg me-1" : "fas fa-sync fa-lg me-1 fa-spin"}></i> Sinkron Rekening
                                    </button>
                                    <button onClick={(e) => {e.stopPropagation(); initModalCreate()}} className="btn btn-xs btn-success">
                                        <i className="fas fa-plus-square fa-lg me-1"></i> Tambah
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <Modal show={isShowCreate}>
                            <Modal.Header>
                                <Modal.Title>Tambah Rekening</Modal.Title>
                            </Modal.Header>
                            <form onSubmit={handleCreate}>
                            <Modal.Body>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            Kode Lembaga
                                        </div>
                                        <div className="col-md-6">
                                            <input className="form-control" name="kode_lembaga" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            Nama Lembaga
                                        </div>
                                        <div className="col-md-6">
                                            <input className="form-control" name="nama" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            Kepala
                                        </div>
                                        <div className="col-md-6">
                                            <Select name="kepala" options={picList} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            Nomor Rekening
                                        </div>
                                        <div className="col-md-6">
                                            <input className="form-control" name="nomor_rekening" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            Saldo
                                        </div>
                                        <div className="col-md-6">
                                            <CurrencyInput name="saldo" className="form-control" prefix="Rp " decimalsLimit={2} required />
                                            <span className="text-xs">*untuk koma gunakan . (titik)</span>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type='button' className='btn btn-xs btn-primary' onClick={initModalCreate}>Batal</button>
                                <button type='submit' className='btn btn-xs btn-success'>Simpan</button>
                            </Modal.Footer>
                            </form>
                        </Modal>

                        <Modal show={isShowEdit}>
                            <Modal.Header>
                                <Modal.Title>Edit Rekening</Modal.Title>
                            </Modal.Header>
                            <form onSubmit={handleEdit}>
                            <Modal.Body>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            Kode Lembaga
                                        </div>
                                        <div className="col-md-6">
                                            {detail.kode_lembaga}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            Nama Lembaga
                                        </div>
                                        <div className="col-md-6">
                                            {detail.nama}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            Kepala
                                        </div>
                                        <div className="col-md-6">
                                            {/* <input 
                                            className="form-control" 
                                            name="kepala" 
                                            value={detail.kepala}
                                            onChange={x => setDetail({...detail, kepala: x.target.value})} /> */}

                                            <Select 
                                                name="kepala" 
                                                options={picList}
                                                onChange={onChangeEditKepala}
                                                value = {
                                                    picList.filter(option => 
                                                       option.value == editOptPic)
                                                }
                                                 />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            Nomor Rekening
                                        </div>
                                        <div className="col-md-6">
                                            <input 
                                                className="form-control" 
                                                name="nomor_rekening" 
                                                value={detail.nomor_rekening} 
                                                onChange={x => setDetail({...detail, nomor_rekening: x.target.value})} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            Saldo
                                        </div>
                                        <div className="col-md-6">
                                            {detailSaldo != null ?
                                            <CurrencyInput 
                                                name="saldo" 
                                                className="form-control" 
                                                prefix="Rp " 
                                                defaultValue={detail.saldo}
                                                decimalsLimit={2} />:null}
                                            <span className="text-xs">*untuk koma gunakan . (titik)</span>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type='button' className='btn btn-xs btn-primary' onClick={initModalEdit}>Batal</button>
                                <button type='submit' className='btn btn-xs btn-success'>Simpan</button>
                            </Modal.Footer>
                            </form>
                        </Modal>

                        <p className="text-center">
                            Jika ingin mengubah data <br/>
                            Klik data yang ingin di ubah, kemudian tekan enter untuk menyimpan perubahan
                        </p>
                        <p className="text-danger text-center">
                            (Hanya data kepala, nomor rekening, dan saldo yang dapat di ubah)
                        </p>
                        
                        <ToolkitProvider
                            keyField="id"
                            data={ data }
                            columns={ columns }
                            search>
                            {
                                props => (
                                    <div>
                                        <SearchBar { ...props.searchProps } />
                                        <br />
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            { ...props.baseProps }
                                            pagination={paginationFactory({
                                                onPageChange: (page, sizePerPage) => {
                                                    setPage(page);
                                            }})}
                                            bordered={ false }
                                            hover
                                            wrapperClasses="table-responsive"
                                            classes="table table-responsive"
                                            headerWrapperClasses="thead-light"
                                            rowClasses="text-sm font-weight-normal"
                                            // cellEdit={ cellEditFactory({
                                            //     mode: 'click',
                                            //     beforeSaveCell
                                            // }) }
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RekeningKeuanganList