import {useParams, Link, useNavigate} from "react-router-dom";
import {GetAnggaranDetail, GetAnggaranDetailV2} from "../../../services/KeuanganService";
import { GetDetailInventaris } from "../../../services/SarprasService";
import {useEffect, useState} from "react";
import { FormatRupiah } from "@arismun/format-rupiah";
import { Cookies } from 'react-cookie';
import { detailStyle } from "../../../components/CustomStyle";

const InventarisDetail = () => {
    const history = useNavigate();
    const {id} = useParams();
    const {periode} = useParams();
    let [detail, setDetail] = useState({});
    const cookies = new Cookies();
    const homebase = cookies.get('homebase_kode');

    const getDetail = () => {
        GetDetailInventaris(id).then((response) => {
            setDetail(response.data.data);
            console.log(response.data.data);

        }).catch(() => console.log('error get_periode_all'));
    }

    useEffect(() => {
        getDetail();
    }, [id])

    return (
        <>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header inline-group">
                            <div className="d-lg-flex">
                                <div>
                                    <h5 className="mb-0">Inventaris Detail</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Barcode
                                            </div>
                                            <div className="col-md-10">
                                                {detail.rid}
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Nama Inventaris
                                            </div>
                                            <div className="col-md-10">
                                                {detail.inventaris && detail.inventaris.inventaris_nama}
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Spesifikasi
                                            </div>
                                            <div className="col-md-2">
                                                {detail.spek}
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Ruangan
                                            </div>
                                            <div className="col-md-10">
                                                {detail.ruangan && detail.ruangan.ruang_nama}
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Harga
                                            </div>
                                            <div className="col-md-10">
                                                <b><FormatRupiah value={detail.harga} /></b>
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Bulan/Tahun
                                            </div>
                                            <div className="col-md-10">
                                                {detail.bln}/{detail.thn}
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-md-2" style={detailStyle}>
                                                Depresiasi
                                            </div>
                                            <div className="col-md-10">
                                                <b><FormatRupiah value={detail.depresiasi} /></b>
                                            </div>
                                        </div>
                                        {detail.depresiasi_detail !== null ? <>
                                            <div className="row mb-1">
                                                <div className="col-md-2" style={detailStyle}>
                                                    Depresiasi Detail
                                                </div>
                                                <div className="col-md-10">
                                                    {detail.depresiasi_detail && detail.depresiasi_detail.map((item) => 
                                                        <>
                                                            {item.tahun} &nbsp;
                                                            <b><FormatRupiah value={item.harga_depresiasi}/></b>
                                                            <br/>
                                                        </>
                                                    )}
                                                    {/* {detail.depresiasi_detail} */}
                                                </div>
                                            </div>
                                        </>:<></>}
                                    </div>
                                </div>
                                <br />
                                <button className="btn btn-sm btn-secondary" onClick={() => history(-1)}>
                                    <i className="fas fa-chevron-circle-left me-2"></i>Kembali
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InventarisDetail