import axios from "axios";
import {api_refresh} from "./ApiService";
import TokenService from "./TokenService";

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_API_KEUANGAN,
    // baseURL: process.env.REACT_APP_BASE_URL_API_LOCAL,
    headers: {
      "Content-Type": "application/json",
    },
});

client.interceptors.request.use(
    async (config) => {
        const token = TokenService.getAccessToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
            config.headers["Content-Type"] = 'multipart/form-data'
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

client.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
        const originalRequest = err.config;
        
        if (originalRequest.url !== "/login" && err.response) {
            if (err.response.status === 401 && !originalRequest._retry) {

                originalRequest._retry = true;
                try {
                    const formData = new FormData();
                    formData.append('refresh', TokenService.getRefreshToken());
                    
                    const rs = await axios.post(api_refresh, formData);
                    const accessToken = rs.data.access;
                    TokenService.updateAccessToken(accessToken);
    
                    originalRequest.headers['Authorization'] = TokenService.getBearerAccessToken();
                    return client(originalRequest);
                
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }
        return Promise.reject(err);
    }
);

export default client;