import axios from "axios";
import moment from "moment";
import {Cookies} from "react-cookie";
import {useNavigate} from "react-router-dom";

import React, {useEffect, useState} from "react";
import {FormatRupiah} from '@arismun/format-rupiah';
import BootstrapTable from 'react-bootstrap-table-next';
import TokenService from "../../../services/TokenService";

import paginationFactory from 'react-bootstrap-table2-paginator';
import {header, numTable, wsNormal} from '../../../components/CustomStyle';
import {api_export_excel_v3, api_export_excel_v4} from "../../../services/ApiService";
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {GetAnggaranListV2, GetLogRekeningUnit, GetLogRekeningUnitV2, GetPeriodeList} from '../../../services/KeuanganService';

const LogRekeningUnit = () => {
    const {SearchBar} = Search;
    const navigate = useNavigate();

    let cookies = new Cookies();
    let rek = cookies.get('homebase_rekening');
    let homebase = cookies.get('homebase_kode');
    let homebase_nama = cookies.get('homebase_nama');

    let [page, setPage] = useState(1);
    let [data, setData] = useState([]);
    let [agr, setAgr] = useState('all');
    let [periode, setPeriode] = useState(0);
    let [anggaran, setAnggaran] = useState([]);
    let [periodeList, setPeriodeList] = useState([]);
    let [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    let [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));

    const getPeriode = () => {
        GetPeriodeList().then((response) => {
            setPeriodeList(response.data.rows);
            setPeriode(response.data.rows[0]['id']);

        }).catch(() => console.log('error get_periode_all'));
    }

    const getAnggaran = () => {
        GetAnggaranListV2(homebase, periode).then((response) => {
            setAnggaran(response.data.rows);

        }).catch(() => console.log('error get_anggaran_all'));
    }

    useEffect(() => {
        setData([]);
        getPeriode();
    }, [homebase])

    useEffect(() => {
        setData([]);
        getAnggaran();
    }, [homebase, periode])

    const onChangeStartDate = (e) => {
        const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setStartDate(newDate);
    };

    const onChangeEndDate = (e) => {
        const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setEndDate(newDate);
    };

    const handleFilter = () => {
        let startMillis = new Date(startDate+'T00:00:00').getTime();
        let endMillis = new Date(endDate+'T23:59:59').getTime();

        if (agr == 'all'){
            GetLogRekeningUnit(rek, startMillis, endMillis).then((response) => {
                setData(response.data.rows);

            }).catch(() => console.log('error get_log_rekening_unit'));
        }else{
            GetLogRekeningUnitV2(rek, startMillis, endMillis, agr).then((response) => {
                setData(response.data.rows);
    
            }).catch(() => console.log('error get_log_rekening_unit_v2'));
        }
    }

    const getExcel = () => {
        let url_xlsx;
        let startMillis = new Date(startDate+'T00:00:00').getTime();
        let endMillis = new Date(endDate+'T23:59:59').getTime();

        if (agr == 'all'){
            url_xlsx = api_export_excel_v3+homebase+'/'+startMillis+'/'+endMillis+'/'+rek
        }else{
            url_xlsx = api_export_excel_v4+homebase+'/'+startMillis+'/'+endMillis+'/'+agr+'/'+rek
        }
        
        axios({
            url: url_xlsx,
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Authorization': TokenService.getBearerAccessToken()
            }

        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            
            link.href = url;
            link.setAttribute('download', homebase+'-rekening.xlsx');
            document.body.appendChild(link);
            link.click();
        });
    }

    const namaTruncate = (cell, row, rowIndex, formatExtraData) => {
        const ket = row.keterangan;
        const tanggals = moment(row.tanggal_trx).format('DD MMMM YYYY hh:mm')
        const tc = 25;
        console.log(row);
        return (
            <>
            <span className="text-xs"><i>{tanggals}</i></span><br/>
            <b style={wsNormal}>{ket}</b>
            </>
        );
    }

    const columns = [{
        text: 'No',
        dataField: '',
        formatter: (cell, row, rowIndex) => {
            return numTable(page, rowIndex);
        },
        headerStyle: header,
        editable: false
    }, {
        text: 'Keterangan',
        dataField: 'keterangan',
        formatter: namaTruncate,
        headerStyle: header
    }, {
        text: 'Rekening Asal',
        dataField: 'nomor_rekening_awal',
        headerStyle: header
    },{
        text: 'Transaksi',
        dataField: 'transaksi',
        formatter: (cell, row, rowIndex) => {
            if (row.jenis == 'transaksi unit'){
                if (row.transaksi == 'keluar'){
                    return <>
                        <span title="Keluar" className="badge badge-success text-success">
                            {row.jenis}
                            <i className="fas fa-arrow-circle-right text-success ms-2"></i>
                        </span>
                    </>
                }else if (row.transaksi == ''){
                    return <>
                        <span title="Masuk" className="badge badge-success text-success">
                            {row.jenis}
                            <i className="fas fa-arrow-circle-right text-primary ms-2"></i>
                        </span>
                    </>
                }else if (row.transaksi == 'kedalam'){
                    return <>
                        <span title="Pengembalian" className="badge badge-success text-success">
                            {row.jenis}
                            <i className="fas fa-undo text-primary ms-2"></i>
                        </span>
                    </>
                }
            }else if (row.jenis == 'mata anggaran'){
                if (row.transaksi == 'keluar'){
                    return <>
                        <span title="Keluar" className="badge badge-info text-success">
                            {row.jenis}
                            <i className="fas fa-undo text-primary ms-2"></i>
                        </span>
                    </>
                }else if (row.transaksi == 'kedalam'){
                    return <>
                        <span title="Masuk" className="badge badge-info text-info">
                            {row.jenis}
                            <i className="fas fa-arrow-circle-right text-primary ms-2"></i>
                        </span>
                    </>
                }
            }
        },
        headerStyle: header
    }, {
        text: 'Rekening Tujuan',
        dataField: 'nomor_rekening_tujuan',
        formatter: (cell, row, rowIndex) => {
            return <b>{row.nomor_rekening_tujuan}</b>
        },
        headerStyle: header
    },{
        text: 'Saldo Awal',
        dataField: 'saldo_awal',
        formatter: (cell, row, rowIndex) => {
            return <FormatRupiah value={row.saldo_awal} />
        },
        headerStyle: header,
    }, {
        text: 'Nominal',
        dataField: 'nominal',
        headerStyle: header,
        formatter: (cell, row, rowIndex) => {
            return <b><i><FormatRupiah value={row.nominal} /></i></b>
        },
    }, {
        text: 'Saldo Akhir',
        dataField: 'saldo_akhir',
        headerStyle: header,
        formatter: (cell, row, rowIndex) => {
            return <b><FormatRupiah value={row.saldo_akhir} /></b>
        },
        editable: false
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (row.jenis == 'transaksi unit'){
                const url = '/trx-unit/detail/'
                let path = url + row.id_transaksi_unit;
                navigate(path);

            }else if(row.jenis == 'mata anggaran'){
                const url = '/trx-mata-anggaran/detail/'
                let path = url + row.id_transaksi_mata_anggaran;
                navigate(path);
            }
        }
    }

    function change(event){
        setPeriode(event.target.value);
        setAnggaran([]);
    }

    function changeAgr(event){
        setAgr(event.target.value);
    }

    return (
        <div className="row mt-4">
            <div className="col-12">
                <div className="card">
                    <div className="card-header inline-group">
                        <div className="d-lg-flex">
                            <div>
                                <h5 className="mb-0">Log Rekening</h5>
                                <span className="text-bold">{homebase} - {homebase_nama}</span>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-6">
                                Start Date
                                <input className="form-control" type="date" value={startDate} name="start_date" onChange={onChangeStartDate} />
                            </div>
                            <div className="col-lg-6">
                                End Date
                                <input className="form-control" type="date" value={endDate} name="end_date" onChange={onChangeEndDate} />
                            </div>
                        </div>
                        <div className="row mt-4" style={{backgroundColor: '#f5f5f5', borderRadius: 10, padding: 10}}>
                            <div className="col-lg-12">
                                Periode Anggaran
                                {periodeList.length !== 0 ?
                                    <select className="form-select" onChange={change}>
                                        {periodeList.map((periode) =>
                                            <option key={periode.id} value={periode.id}>{periode.tahun}</option>
                                        )}
                                    </select>:<select className="form-select"></select>}
                            </div>
                            <div className="col-lg-12 mt-3">
                                Anggaran
                                {anggaran.length !== 0 ?
                                    <select className="form-select" onChange={changeAgr}>
                                        <option value="all">Semua</option>
                                        {anggaran.map((agr) =>
                                            <option key={agr.id} value={agr.id}>{agr.nama_kegiatan}</option>
                                        )}
                                    </select>:<select className="form-select"></select>}
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-12">
                                <button className="btn btn-xs btn-primary me-2" onClick={handleFilter}>Filter</button>
                                <button className="btn btn-xs btn-success" onClick={getExcel}>Export</button>
                            </div>
                        </div>
                        <br/>
                        <ToolkitProvider
                            keyField="id"
                            data={ data }
                            columns={ columns }
                            search>
                            {
                                props => (
                                    <div>
                                        <SearchBar { ...props.searchProps } />
                                        <br />
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            { ...props.baseProps }
                                            pagination={paginationFactory({
                                                onPageChange: (page, sizePerPage) => {
                                                    setPage(page);
                                            }})}
                                            bordered={ false }
                                            rowEvents={ rowEvents }
                                            hover
                                            wrapperClasses="table-responsive"
                                            classes="table table-responsive"
                                            headerWrapperClasses="thead-light"
                                            rowClasses="text-sm font-weight-normal"
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LogRekeningUnit