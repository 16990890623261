import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import CurrencyInput from "react-currency-input-field";
import { UpdateDepresiasiMultiInventaris } from '../services/SarprasService';
import { swalCustom } from './CustomStyle';
import {useNavigate} from "react-router-dom";

function ModalDepresiasi({titles, datas, getDatas}) {
    const [isModal, setIsModal] = useState(false)
    const navigate = useNavigate();

    let trueValue=[];
    for (let i in datas){
        if (datas[i] === true){
            trueValue.push(i);
        }
    }

    const initModal = (e) => {
        e.preventDefault();
        return setIsModal(!isModal)
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        let formData = new FormData();
        formData.append('depresiasi', event.target.depresiasi.value.replace(/[^0-9.]/g, ''));
        formData.append('barcode', trueValue);

        UpdateDepresiasiMultiInventaris(formData).then(response => {
            swalCustom.fire({
                title: "Sukses",
                text: "Data berhasil ditambah",
                type: "success",
                confirmButtonText: "OK",
            }).then((result) => {
                if (result.value === true){
                    // navigate(0);
                    getDatas();
                }
            });
        }).catch(response => {
            swalCustom.fire({
                title: "Gagal",
                text: "Data gagal ditambah",
                type: "error",
            });
        });
    }

    return (
    <>
        <button className="btn btn-xs btn-success" onClick={(e) => initModal(e)}>
            {titles}
        </button>

        <Modal show={isModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {titles}
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
            <Modal.Body>
                Barcode<br/>
                {trueValue.map((datz) => 
                    <span className='badge badge-info ms-2'>{datz}</span>
                )}
                <br/>
                <br/>
                <br/>
                Nilai Depresiasi
                <CurrencyInput name="depresiasi" className="form-control" prefix="Rp " decimalsLimit={4} />
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-xs btn-danger mt-2" type="button" onClick={() => setIsModal(!isModal)}>Tutup</button>
                <button className="btn btn-xs btn-success mt-2" type="submit" onClick={() => setIsModal(!isModal)}>
                    <i className="fas fa-save me-2"></i>Selesai
                </button>
            </Modal.Footer>
            </form>
      </Modal>
    </>
  )
}

export default ModalDepresiasi